import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  actions: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    ["@media print"]: {
      display: "none !important",
    },
    display: "flex",
    marginTop: theme.spacing(2),
    justifyContent: "space-between",
  },
}));

export default function FormActions({
  children,
}: React.PropsWithChildren<any>) {
  const classes = useStyles();

  return <div className={classes.actions}>{children}</div>;
}
