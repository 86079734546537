import { Box, Step, StepLabel, Stepper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useBreakpoints from "../../breakpoints";
import paths, { AppPath, RouteDef } from "../../paths";
import { useIsInquiryDataAvailable } from "../../store/form/selectors";
import RootState from "../../store/types";
import useTokenUserInfo from "./useTokenUserInfo";

const useStyles = makeStyles((theme) => ({
  nav: {
    color: "black",
  },
  title: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  navLink: {
    color: "inherit",
    textDecoration: "none",
    cursor: "pointer",
  },
  disabled: {
    pointerEvents: "none",
  },
  divider: {
    marginTop: "24px",
    marginRight: "-1px",
    marginBottom: "24px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
}));

function isStepAllowed(stepHref: AppPath, isInquiryDataAvailable: boolean) {
  return (
    isInquiryDataAvailable ||
    [paths.vastauskoodit, paths.yhteenveto].includes(stepHref)
  );
}

type SidenavProps = {
  routes: {
    href: AppPath;
    text: string;
    nested?: boolean;
  }[];
  formId: string;
};

export default function Sidenav({ routes, formId }: SidenavProps) {
  const user = useTokenUserInfo();
  const { t } = useTranslation();
  const breakpoints = useBreakpoints();
  const classes = useStyles();
  const isInquiryDataAvailable = useIsInquiryDataAvailable();
  let activeStep = useSelector((state: RootState) => state.form.step) || 0;

  if (user?.upn) {
    activeStep += 1;
  }

  return (
    <Box pt={4} pb={4} className={breakpoints.stepper}>
      <Stepper activeStep={activeStep} alternativeLabel={true}>
        {user?.upn && (
          <Step key={"vastauskoodit"}>
            {" "}
            <Link
              className={classes.navLink}
              key={"vastauskoodit"}
              to={`vastauskoodit?id=${formId}`}
            >
              <StepLabel>{t("common.vastauskoodit")}</StepLabel>{" "}
            </Link>
          </Step>
        )}

        {routes.map((route, index) => {
          return (
            <Step
              data-testid="stepper-step"
              key={route.text}
              completed={
                isStepAllowed(route.href, isInquiryDataAvailable) &&
                index + (user?.upn ? 1 : 0) < activeStep
              }
              disabled={!isStepAllowed(route.href, isInquiryDataAvailable)}
            >
              <RouteLabel
                route={route}
                formId={formId}
                isCompletedOrActive={index <= activeStep}
                disabled={!isStepAllowed(route.href, isInquiryDataAvailable)}
              />
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}

interface RouteLabelProps {
  formId: string;
  route: RouteDef;
  isCompletedOrActive: boolean;
  disabled: boolean;
}

function RouteLabel({
  route,
  disabled,
  formId,
  isCompletedOrActive,
}: RouteLabelProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const Label = () => <StepLabel>{t(route.text)}</StepLabel>;

  if (!disabled && isCompletedOrActive) {
    return (
      <Link
        className={classes.navLink}
        key={route.text}
        to={`${route.href}?id=${formId}`}
      >
        <Label />{" "}
      </Link>
    );
  }

  return <Label />;
}
