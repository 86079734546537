import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

const useBreakpoints = makeStyles((theme: Theme) => ({
  appTitle: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  desktopHeader: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      display: "none !important",
    },
    flex: 0,
  },
  formActions: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  layout: {
    height: "100%",
    [theme.breakpoints.up("md")]: {
      flex: 1,
      height: "auto",
    },
  },
  routes: {
    height: "100%",
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(12),
      paddingRight: theme.spacing(12),
    },
  },
  stepper: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  footer: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  mobilePrintButton: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default useBreakpoints;
