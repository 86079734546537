import { useSelector } from "react-redux";
import RootState from "../../store/types";
import { Fields } from "./lisatieto/lisatiedot.schema";

export const useLisatiedotValue = () => {
  const { lisatiedotWithID, lisatiedotWithNoID } = useSelector(
    ({ form }: RootState) => ({
      lisatiedotWithID: form.Lisatiedot,
      lisatiedotWithNoID: form["Lisätiedot"],
    })
  );

  // if the lisatiedot field with kenttaID has values
  if (lisatiedotWithID && lisatiedotWithID[Fields.Lisatiedot]) {
    return lisatiedotWithID;
  }
  // fixme: Backwards compatilibity workaround, to be removed after SATO tiedonkeruu is over and data is cleaned
  if (lisatiedotWithNoID) {
    return {
      [Fields.Lisatiedot]: lisatiedotWithNoID,
    };
  }

  return {
    [Fields.Lisatiedot]: "",
  };
};
