import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect } from "react";
import { Controller, FieldArrayWithId, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { HehtaariField } from "../../../common/Fields";
import FormInstructions from "../../../common/FormInstructions";
import FormSection from "../../../common/FormSection";
import FormSectionTitle from "../../../common/FormSectionTitle";
import { Fields, PerunanSatotiedotForm } from "./perunanSatotiedot.schema";

const useStyles = makeStyles(() => ({
  field: {},
}));

const useSummaryValues = (
  fields: FieldArrayWithId<PerunanSatotiedotForm, "PerunanSatotiedot", "id">[]
) => {
  const methods = useFormContext();

  const istutusalaFieldNames = fields.map(
    (_, index) => `PerunanSatotiedot.${index}.${[Fields.Istutusala]}`
  );
  const korjuualaFieldNames = fields.map(
    (_, index) => `PerunanSatotiedot.${index}.${[Fields.Korjuuala]}`
  );

  const istutusalaFieldValues = methods.watch(istutusalaFieldNames);
  const korjuualaFieldValues = methods.watch(korjuualaFieldNames);

  useEffect(() => {
    methods.setValue(
      `PerunanSatotiedotYhteensa.${Fields.IstutusalaYhteensä}`,
      istutusalaFieldValues.reduce((acc, v) => acc + v)
    );
  }, [istutusalaFieldValues]);

  useEffect(() => {
    methods.setValue(
      `PerunanSatotiedotYhteensa.${Fields.KorjattuAlaYhteensä}`,
      korjuualaFieldValues.reduce((acc, v) => acc + v)
    );
  }, [korjuualaFieldValues]);

  useEffect(() => {
    const korjaamatonAla =
      istutusalaFieldValues.reduce((acc, v) => acc + v) -
      korjuualaFieldValues.reduce((acc, v) => acc + v);

    methods.setValue(
      `PerunanSatotiedotYhteensa.${Fields.KorjaamatonAlaYhteensä}`,
      korjaamatonAla > 0 ? korjaamatonAla : 0
    );
  }, [istutusalaFieldValues, korjuualaFieldValues]);
};

interface IProps {
  fields: FieldArrayWithId<PerunanSatotiedotForm, "PerunanSatotiedot", "id">[];
}

export default function Yhteenveto({ fields }: IProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  const methods = useFormContext();

  useSummaryValues(fields);

  return (
    <>
      <FormInstructions>
        {t("PerunanSatotiedotYhteensa.ohjeteksti.yhteenveto")}
      </FormInstructions>
      <FormSection>
        <FormSectionTitle>
          {t("PerunanSatotiedotYhteensa.otsikko")}
        </FormSectionTitle>

        <Grid spacing={2} container item xs={12}>
          <Grid md={6} item xs={12}>
            <Controller
              name={`PerunanSatotiedotYhteensa.${Fields.IstutusalaYhteensä}`}
              control={methods.control}
              render={({ field: { ref, ...field } }) => (
                <HehtaariField
                  inputRef={ref}
                  {...field}
                  disabled
                  fullWidth
                  className={classes.field}
                  label={"IstutusalaYhteensä"}
                />
              )}
            />

            <Controller
              name={`PerunanSatotiedotYhteensa.${Fields.KorjattuAlaYhteensä}`}
              control={methods.control}
              render={({ field: { ref, ...field } }) => (
                <HehtaariField
                  inputRef={ref}
                  {...field}
                  disabled
                  fullWidth
                  className={classes.field}
                  label={"KorjattuAlaYhteensä"}
                />
              )}
            />

            <Controller
              name={`PerunanSatotiedotYhteensa.${Fields.KorjaamatonAlaYhteensä}`}
              control={methods.control}
              render={({ field: { ref, ...field } }) => (
                <HehtaariField
                  inputRef={ref}
                  {...field}
                  disabled
                  fullWidth
                  className={classes.field}
                  label={"KorjaamatonAlaYhteensä"}
                />
              )}
            />
          </Grid>
        </Grid>
      </FormSection>
    </>
  );
}

export { Yhteenveto };
