import { useLayoutEffect } from "react";

export default function useFocusFirstField() {
  useLayoutEffect(() => {
    setTimeout(() => {
      try {
        const inputs = document.getElementsByTagName("input");

        if (inputs.length) inputs[0].focus();
      } catch (e) {
        console.warn(e);
      }
    }, 500);
  }, []);
}
