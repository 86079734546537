import { Grid, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { HehtaariField, KgField, SatotasoField } from "../../../common/Fields";
import { useSatotasoCalculator } from "../../../common/useSatotasoCalculator";
import { CropFieldContext } from "./CropContext";
import { Fields } from "./viljanSatotiedot.schema";

export default function KuivattavaVilja() {
  const { t } = useTranslation();
  const { field, index } = useContext(CropFieldContext);

  const methods = useFormContext();
  const selected = methods.watch(
    `ViljanSatotiedot.${index}.${Fields.KorjattuKuivana}`
  );

  const korjuuala = methods.watch(
    `ViljanSatotiedot.${index}.${[Fields.KorjuualaKuivattavaVilja]}`
  );
  useEffect(() => {
    if (korjuuala === 0) {
      methods.setValue(
        `ViljanSatotiedot.${index}.${[Fields.SatomääräKuivattavaVilja]}`,
        null
      );
    }
  }, [korjuuala]);

  const { handleChangeAcres, handleChangeKilograms } = useSatotasoCalculator(
    `ViljanSatotiedot.${index}.${Fields.SatomääräKuivattavaVilja}`,
    `ViljanSatotiedot.${index}.${Fields.KorjuualaKuivattavaVilja}`,
    `ViljanSatotiedot.${index}.${Fields.SatotasoKuivattavaVilja}`
  );

  useEffect(() => {
    if (!selected) {
      methods.setValue(
        `ViljanSatotiedot.${index}.${Fields.SatomääräKuivattavaVilja}`,
        null
      );

      methods.setValue(
        `ViljanSatotiedot.${index}.${Fields.SatomääräKuivattavaVilja}`,
        null
      );
      methods.setValue(
        `ViljanSatotiedot.${index}.${Fields.KorjuualaKuivattavaVilja}`,
        null
      );
      methods.setValue(
        `ViljanSatotiedot.${index}.${Fields.SatotasoKuivattavaVilja}`,
        null
      );
    }
  }, [selected]);

  return (
    <>
      {!!selected && (
        <Grid item md={6} xs={12}>
          <Typography sx={{ fontWeight: "bold" }}>
            {t("ViljanSatotiedot.KuivattavaVilja")}
          </Typography>
          <Controller
            name={`ViljanSatotiedot.${index}.${Fields.KorjuualaKuivattavaVilja}`}
            control={methods.control}
            render={({ field: { ref, ...field } }) => (
              <HehtaariField
                tooltip={t(
                  "ViljanSatotiedot.ohjeteksti.KuivattavaViljaKorjuualaOhje"
                )}
                inputRef={ref}
                {...field}
                label={"Kuivakorjuuala"}
                fullWidth
                onChange={(e) => {
                  field.onChange(e);
                  handleChangeAcres(e);
                }}
              />
            )}
          />
          <Controller
            name={`ViljanSatotiedot.${index}.${Fields.SatomääräKuivattavaVilja}`}
            control={methods.control}
            render={({ field: { ref, ...field } }) => (
              <KgField
                inputRef={ref}
                {...field}
                label={"KuivattuKokonaismäärä"}
                fullWidth
                onChange={(e) => {
                  field.onChange(e);
                  handleChangeKilograms(e);
                }}
                disabled={korjuuala === 0}
              />
            )}
          />
          <Controller
            name={`ViljanSatotiedot.${index}.${Fields.SatotasoKuivattavaVilja}`}
            control={methods.control}
            render={({ field: { ref, ...field } }) => (
              <SatotasoField
                inputRef={ref}
                {...field}
                label={"satotaso"}
                fullWidth
                disabled={korjuuala === 0}
              />
            )}
          />
        </Grid>
      )}
    </>
  );
}
