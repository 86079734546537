import axios from "axios";
import { useAsync } from "react-async-hook";
import { useDispatch } from "react-redux";
import { initFormValues } from "../../store/form/actions";
import { FormState } from "../../store/form/types";
import { setMeta } from "../../store/meta/actions";
import { MetaState } from "../../store/meta/types";
import useQuery from "./useQuery";

export default function useFormData(periodId: string) {
  const query = useQuery();
  const dispatch = useDispatch();

  const endpoint = `${
    process.env.REACT_APP_DATA_FUNCTION_URL
  }/GetData?id=${query.get("id")}`;
  const metaendpoint = `${process.env.REACT_APP_DATA_FUNCTION_URL}/GetMetadata`;

  const fetch = useAsync(async () => {
    try {
      const [form, meta] = await axios.all([
        axios.get(endpoint),
        axios.get(metaendpoint),
      ]);

      dispatch(initFormValues({ ...(form.data as FormState), periodId }));
      dispatch(setMeta(meta.data as MetaState));
    } catch (e) {
      console.warn(e);
    }
  }, []);

  return { loading: fetch.loading, error: fetch.error };
}
