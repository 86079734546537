import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { arrayHasValue, kaikkiKorjuukertakentatNollia, jokainenKorjuukertaKenttaTyhja } from "./common";
import {
    KgField, SatotasoField, HehtaariField,
} from "../../../common/Fields";
import { useSatotasoCalculator } from "../../../common/useSatotasoCalculator";
import makeStyles from "@mui/styles/makeStyles";
import {
    Fields,
    RehunurmenSatotieto
} from "./rehunurmenSatotiedot.schema";

interface IKuivaheinaOsio {
    methods: UseFormReturn<any, any>
}

interface IKuivaheinaHaYhteensa {
    methods: UseFormReturn<any, any>,
    fields: RehunurmenSatotieto[]
}

const useStyles = makeStyles(() => ({
    field: {},
}));

const kuivaheinaHaYhteensa = ({ methods, fields }: IKuivaheinaHaYhteensa) => {
    const kuivaheinaFieldNames = fields.map(
        (_, index) => `RehunurmenSatotiedot.Korjuu.${index}.${Fields.Kuivaheinä}`
    );
    const kuivaheinaFieldValues: number[] = methods.watch(kuivaheinaFieldNames as any);
    const summa = kuivaheinaFieldValues.reduce((acc, val) => {
        return acc + (val || 0);
    }, 0)

    useEffect(() => {
        methods.setValue(
            `RehunurmenSatotiedot.${Fields.KuivaheinaKorjuuAlaKaikkiKorjuukerratYhteensa}`,
            summa
        );
    }, [summa])

    return summa;
}

const useSummaryValues = (
    fields: RehunurmenSatotieto[],
    methods: UseFormReturn<any, any>
): {
    kuivaheinaaKorjattu: boolean;
    kuivaheinaKaikkiKorjuukerratNollia: boolean;
    kuivaheinaKaikkiKorjuukerratTyhjia: boolean;
    kuivaheinaKorjuukerratHaYhteensa: number
} => {
    const kuivaheinaFieldNames = fields.map(
        (_, index) => `RehunurmenSatotiedot.Korjuu.${index}.${Fields.Kuivaheinä}`
    );
    const kuivaheinaFieldValues = methods.watch(kuivaheinaFieldNames as any);

    const kuivaheinaaKorjattu = arrayHasValue(kuivaheinaFieldValues);

    const kuivaheinaKaikkiKorjuukerratNollia = kaikkiKorjuukertakentatNollia(kuivaheinaFieldValues);

    const kuivaheinaKaikkiKorjuukerratTyhjia = jokainenKorjuukertaKenttaTyhja(kuivaheinaFieldValues);

    const kuivaheinaKorjuukerratHaYhteensa = kuivaheinaHaYhteensa({ methods, fields });


    return {
        kuivaheinaaKorjattu,
        kuivaheinaKaikkiKorjuukerratNollia,
        kuivaheinaKaikkiKorjuukerratTyhjia,
        kuivaheinaKorjuukerratHaYhteensa
    };
};

const KuivaheinaOsio = ({ methods }: IKuivaheinaOsio) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const rehunurmensatotiedot = methods.getValues("RehunurmenSatotiedot.Korjuu");


    const {
        kuivaheinaaKorjattu,
        kuivaheinaKaikkiKorjuukerratNollia,
        kuivaheinaKaikkiKorjuukerratTyhjia,
        kuivaheinaKorjuukerratHaYhteensa
    } = useSummaryValues(
        rehunurmensatotiedot,
        methods
    );

    useEffect(() => {
        if (kuivaheinaKaikkiKorjuukerratNollia || kuivaheinaKaikkiKorjuukerratTyhjia) {
            methods.clearErrors(`RehunurmenSatotiedot.${Fields.KuivaheinäYhteensäKg}`);
        }
        if (kuivaheinaaKorjattu === false) {
            methods.setValue(
                `RehunurmenSatotiedot.${Fields.KuivaheinäYhteensäKg}`,
                null
            );
        }
    }, [kuivaheinaaKorjattu]);

    const { handleChangeAcres, handleChangeKilograms } =
        useSatotasoCalculator(
            `RehunurmenSatotiedot.${Fields.KuivaheinäYhteensäKg}`,
            `RehunurmenSatotiedot.${Fields.KuivaheinaKorjuuAlaKaikkiKorjuukerratYhteensa}`,
            `RehunurmenSatotiedot.${Fields.SatotasoKuivaheinaYhteensa}`
        );

    return (
        <>
            <Typography sx={{ fontWeight: "bold" }} pt={4}>
                {t("RehunurmenSatotiedot.Kuivaheinän")}
            </Typography>
            <Controller
                name={`RehunurmenSatotiedot.${Fields.KuivaheinaKorjuuAlaKaikkiKorjuukerratYhteensa}`}
                control={methods.control}
                render={({ field: { ref, ...field } }) => (
                    <HehtaariField
                        inputRef={ref}
                        {...field}
                        disabled
                        fullWidth
                        onChange={(e) => {
                            field.onChange(e);
                            handleChangeAcres(e);
                        }}
                        className={classes.field}
                        label={"KorjattuAlaYhteensä"}
                    />
                )}
            />

            <Controller
                name={`RehunurmenSatotiedot.${Fields.KuivaheinäYhteensäKg}`}
                control={methods.control}
                render={({ field: { ref, ...field } }) => (
                    <KgField
                        inputRef={ref}
                        {...field}
                        label={"Kuivaheinä"}
                        fullWidth
                        onChange={(e) => {
                            field.onChange(e);
                            handleChangeKilograms(e);
                        }}
                        disabled={!kuivaheinaaKorjattu}
                    />
                )}
            />

            <Controller
                name={`RehunurmenSatotiedot.${Fields.SatotasoKuivaheinaYhteensa}`}
                control={methods.control}
                render={({ field: { ref, ...field } }) => (
                    <SatotasoField
                        inputRef={ref}
                        {...field}
                        label={"satotaso"}
                        fullWidth
                        disabled={true}
                    />
                )}
            />
        </>
    )
}
export default KuivaheinaOsio;
