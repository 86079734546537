import InfoIcon from "@mui/icons-material/Info";
import { Grid, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { HehtaariField, KgField, SatotasoField } from "../../../common/Fields";
import FormSectionTitle from "../../../common/FormSectionTitle";
import Kasvilaji from "../../../common/Kasvilaji";
import SectionSubtitle from "../../../common/SectionSubtitle";
import { useSatotasoCalculator } from "../../../common/useSatotasoCalculator";
import { Fields } from "./muutViljelykasvit.schema";

const useStyles = makeStyles(() => ({
  sectionHeader: {
    display: "flex",
    alignItems: "flex-end",
  },
  title: {
    "&:first-letter": {
      textTransform: "capitalize",
    },
  },
  transText: { fontSize: "1.2em" },
  acreField: {
    minWidth: "275px",
  },
}));

export default function MuuKasvi({ field, index }: any) {
  const { t } = useTranslation();
  const methods = useFormContext();
  const classes = useStyles();

  const viljelyAla = methods.watch(
    `MuutViljelykasvitSatotiedot.${index}.${Fields.Viljelyala}`
  );

  const korjuuala = methods.watch(
    `MuutViljelykasvitSatotiedot.${index}.${Fields.Korjuuala}`
  );
  useEffect(() => {
    if (korjuuala === 0) {
      methods.setValue(
        `MuutViljelykasvitSatotiedot.${index}.${[Fields.Satomäärä]}`,
        null
      );
      methods.setValue(
        `MuutViljelykasvitSatotiedot.${index}.${[Fields.Satotaso]}`,
        null
      );
    }
  }, [korjuuala]);

  useEffect(() => {
    if (viljelyAla === 0) {
      methods.setValue(
        `MuutViljelykasvitSatotiedot.${index}.${Fields.Korjuuala}`,
        null
      );
      methods.setValue(
        `MuutViljelykasvitSatotiedot.${index}.${Fields.Satomäärä}`,
        null
      );
      methods.setValue(
        `MuutViljelykasvitSatotiedot.${index}.${Fields.Satotaso}`,
        null
      );
    }
  }, [viljelyAla]);

  const { handleChangeAcres, handleChangeKilograms } = useSatotasoCalculator(
    `MuutViljelykasvitSatotiedot.${index}.${Fields.Satomäärä}`,
    `MuutViljelykasvitSatotiedot.${index}.${Fields.Korjuuala}`,
    `MuutViljelykasvitSatotiedot.${index}.${Fields.Satotaso}`
  );

  return (
    <Grid key={index} container item md={6} xs={12} mb={2} columnSpacing={2}>
      <Grid item xs={12} className={classes.sectionHeader}>
        <FormSectionTitle>
          <Kasvilaji id={field.Kasvilaji} />
        </FormSectionTitle>
        {field[Fields.Kasvilaji] === "_387247" && (
          <Tooltip
            tabIndex={0}
            title={
              <Typography className={classes.transText}>
                <Trans
                  i18nKey={"muutViljelyKasvit.kumina.ohjeteksti.Viljelyala"}
                  components={{
                    b: <b />,
                  }}
                  values={{
                    ed_vuosi: new Date().getFullYear() - 1,
                    vuosi: new Date().getFullYear(),
                  }}
                >
                  {t("muutViljelyKasvit.kumina.ohjeteksti.Viljelyala")}
                </Trans>
              </Typography>
            }
          >
            <InfoIcon
              sx={{
                color: "#0079C9",
                top: "-10px",
                left: "10px",
                position: "relative",
              }}
            />
          </Tooltip>
        )}
      </Grid>
      <Grid item md={12}>
        <Controller
          name={`MuutViljelykasvitSatotiedot.${index}.${Fields.Viljelyala}`}
          control={methods.control}
          render={({ field: { ref, ...field } }) => (
            <HehtaariField
              inputRef={ref}
              {...field}
              className={classes.acreField}
              label={"Viljelyala"}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} mt={3}>
        <SectionSubtitle>
          {t("ValkuaiskasvienSatotiedot.Korjuutiedot")}
        </SectionSubtitle>
      </Grid>
      <Grid item xs={12}>
        <Controller
          name={`MuutViljelykasvitSatotiedot.${index}.${Fields.Korjuuala}`}
          control={methods.control}
          render={({ field: { ref, ...field } }) => (
            <HehtaariField
              inputRef={ref}
              {...field}
              fullWidth
              label={"Korjuuala"}
              onChange={(e) => {
                field.onChange(e);
                handleChangeAcres(e);
              }}
              disabled={viljelyAla === 0}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name={`MuutViljelykasvitSatotiedot.${index}.${Fields.Satomäärä}`}
          control={methods.control}
          render={({ field: { ref, ...field } }) => (
            <KgField
              inputRef={ref}
              {...field}
              fullWidth
              label={"SadonKokonaismäärä"}
              onChange={(e) => {
                field.onChange(e);
                handleChangeKilograms(e);
              }}
              disabled={viljelyAla === 0 || korjuuala === 0}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name={`MuutViljelykasvitSatotiedot.${index}.${Fields.Satotaso}`}
          control={methods.control}
          render={({ field: { ref, ...field } }) => (
            <SatotasoField
              inputRef={ref}
              {...field}
              fullWidth
              label={"satotaso"}
              disabled={viljelyAla === 0 || korjuuala === 0}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
