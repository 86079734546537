import React from "react";
import { SubmitHandler, useFormContext } from "react-hook-form";
import { FormState } from "../../store/form/types";
import { NextButton, PrevButton } from "./Buttons";

export default function DefaultActions({
  onNext,
  onPrevious,
}: {
  onNext?: SubmitHandler<Partial<FormState>>;
  onPrevious?: any;
}) {
  const methods = useFormContext();

  return (
    <>
      {onPrevious !== undefined && <PrevButton onClick={onPrevious} />}
      {onNext !== undefined && (
        <NextButton onClick={methods.handleSubmit(onNext)}></NextButton>
      )}
    </>
  );
}
