import * as decimals from "@lukefi-private/sato-util-numbers/decimals";
import {
  defaultNumber,
  extendedYup as yup,
  nullableNumber,
  validators,
  testSatotasoMax,
} from "../../../common/FormUtils";

/* ****************** tyyppimäärittelyt ******************* */
type TTranslate = (message: string, values?: object) => string;

export enum Fields {
  Korjuu = "Korjuu",
  Korjuuala = "_610920941387287",
  Pystyynmyynti = "_6109387239387287",
  Pystyynosto = "_6109390195387287",
  Katoala = "_610914549387287",
  Korjattuala = "_6109386967387287",

  Kuivaheinä = "_6109_korjuu_387291",
  Säilörehu = "_6109_korjuu_387275",
  SäilörehuEsikuivattu = "_6109_korjuu_387279",
  Niittorehu = "_6109_korjuu_387295",

  KuivaheinäYhteensäKg = "_566014545387291",
  SäilörehuYhteensäKg = "_566014545387275",
  SäilörehuEsikuivattuYhteensäKg = "_566014545387279",
  NiittorehuYhteensäKg = "_566014545387295",
  SäilörehuKuivaaineProsentti = "_6115386931387275",
  SäilörehuEsikuivattuKuivaaineProsentti = "_6115386931387279",
  NiittorehuKuivaaineProsentti = "_6115386931387295",

  SatotasoKuivaheinaYhteensa = "Satotaso_KuivaheinaYhteensa",
  SatotasoSailorehuYhteensa = "Satotaso_SailorehuYhteensa",
  SatotasoSailorehuEsikuivattuYhteensa = "Satotaso_SailorehuEsikuivattuYhteensa",
  SatotasoNiittorehuYhteensa = "Satotaso_NiittorehuYhteensa",

  KuivaheinaKorjuuAlaKaikkiKorjuukerratYhteensa = "KuivaheinaHaKaikkiKorjuuKerratYhteensa",
  SailorehuKorjuuAlaKaikkiKorjuukerratYhteensa = "SailorehuaHaKaikkiKorjuuKerratYhteensa",
  SailorehuEsikuivattuKorjuuAlaKaikkiKorjuukerratYhteensa = "SailorehuEsikuivattuHaKaikkiKorjuuKerratYhteensa",
  NiittorehuKorjuuAlaKaikkiKorjuukerratYhteensa = "NiittorehuHaKaikkiKorjuuKerratYhteensa"
}

export type RehunurmenSatotiedotForm = {
  RehunurmenSatotiedot: RehunurmenSatotiedot;
};

type RehunurmenSatotiedot = {
  [Fields.Korjuuala]: number | null;
  [Fields.Pystyynmyynti]: number | null;
  [Fields.Pystyynosto]: number | null;
  [Fields.Katoala]: number | null;
  [Fields.Korjattuala]: number | null;

  Korjuu: RehunurmenSatotieto[];

  [Fields.KuivaheinäYhteensäKg]: number | null;
  [Fields.SäilörehuYhteensäKg]: number | null;
  [Fields.SäilörehuKuivaaineProsentti]: number | null;
  [Fields.SäilörehuEsikuivattuYhteensäKg]: number | null;
  [Fields.SäilörehuEsikuivattuKuivaaineProsentti]: number | null;
  [Fields.NiittorehuYhteensäKg]: number | null;
  [Fields.NiittorehuKuivaaineProsentti]: number | null;

  [Fields.SatotasoKuivaheinaYhteensa]: number | null;
  [Fields.SatotasoSailorehuYhteensa]: number | null;
  [Fields.SatotasoSailorehuEsikuivattuYhteensa]: number | null;
  [Fields.SatotasoNiittorehuYhteensa]: number | null;

  [Fields.KuivaheinaKorjuuAlaKaikkiKorjuukerratYhteensa]: number | null;
  [Fields.SailorehuKorjuuAlaKaikkiKorjuukerratYhteensa]: number | null;
  [Fields.SailorehuEsikuivattuKorjuuAlaKaikkiKorjuukerratYhteensa]: number | null;
  [Fields.NiittorehuKorjuuAlaKaikkiKorjuukerratYhteensa]: number | null;
};

export class RehunurmenSatotieto {
  constructor(Korjuu: string) {
    this[Fields.Korjuu] = Korjuu;
  }

  [Fields.Korjuu]: string | null = null;
  [Fields.Kuivaheinä]: number | null = null;
  [Fields.Säilörehu]: number | null = null;
  [Fields.SäilörehuEsikuivattu]: number | null = null;
  [Fields.Niittorehu]: number | null = null;

}

/* ****************** yup ******************* */

/**
 * Jos jossain korjuussa on korjattu tiettyä heinän tyyppiä,
 * kokonaissadon vastaava kenttä ei voi olla tyhjä tai nolla.
 *
 * HOF (higher-order function), joka saa vertailtavien kenttien nimet
 * ja palauttaa varsinaisen testin.
 */
const testKokonaissatoVastaaKorjuuta =
  (kokonaissadonKenttä: Fields, korjuunKenttä: Fields, t: TTranslate) =>
    (rehunurmenSatotiedot: any): boolean | yup.ValidationError => {
      const {
        Korjuu = [],
        [kokonaissadonKenttä]: kokonaissadonKentänArvo,
        [Fields.Korjattuala]: korjattuala,
      } = rehunurmenSatotiedot;

      /**
       * Tehdään tarkistukset, vain jos korjattua alaa on määritetty,
       * ja nämä kentät ovat näkyvissä.
       */
      if (!korjattuala) return true;

      const onkoKorjattuKorjuussa = Korjuu.find((k: any) => !!k[korjuunKenttä]);

      if (onkoKorjattuKorjuussa && !kokonaissadonKentänArvo) {
        /**
         * Palauttamalla `ValidationError`, virhe voidaan kohdistaa tiettyyn kenttään.
         * Tällöin virheilmoitus näkyy kohdistetun kentän yhteydessä.
         */
        return new yup.ValidationError(
          t("fields.errors.rehunurmensatotiedot.kokonaissatovastaakorjuuta"),
          kokonaissadonKentänArvo,
          `RehunurmenSatotiedot.${kokonaissadonKenttä}`
        );
      }

      return true;
    };

const testKokonaissatoKuivaheinäDefinition = (t: TTranslate) => ({
  name: "Kokonaissadon kuivaheinä on määritetty tarvittaessa",
  test: testKokonaissatoVastaaKorjuuta(
    Fields.KuivaheinäYhteensäKg,
    Fields.Kuivaheinä,
    t
  ),
});

const testKokonaissatoSäilörehuEsikuivattuDefinition = (t: TTranslate) => ({
  name: "Kokonaissadon säilörehu (esikuivattu) on määritetty tarvittaessa",
  test: testKokonaissatoVastaaKorjuuta(
    Fields.SäilörehuEsikuivattuYhteensäKg,
    Fields.SäilörehuEsikuivattu,
    t
  ),
});

const testKokonaissatoSäilörehuTuoreDefinition = (t: TTranslate) => ({
  name: "Kokonaissadon säilörehu (tuore) on määritetty tarvittaessa",
  test: testKokonaissatoVastaaKorjuuta(
    Fields.SäilörehuYhteensäKg,
    Fields.Säilörehu,
    t
  ),
});

const testKokonaissatoNiittorehuDefinition = (t: TTranslate) => ({
  name: "Kokonaissadon niittorehu on määritetty tarvittaessa",
  test: testKokonaissatoVastaaKorjuuta(
    Fields.NiittorehuYhteensäKg,
    Fields.Niittorehu,
    t
  ),
});

/**
 * Jos kokonaissadon kuiva-ainetta käyttävä satokenttä on annettu,
 * myös käytetty kuiva-aineprosentti tulee ilmoittaa.
 *
 * HOF (higher-order function), joka saa vertailtavien kenttien nimet
 * ja palauttaa varsinaisen testin.
 */
const testKokonaissadonKuivaaineProsenttiAnnettu =
  (satoKenttä: Fields, kuivaaineKenttä: Fields, t: TTranslate) =>
    (rehunurmenSatotiedot: any): boolean | yup.ValidationError => {
      const {
        [satoKenttä]: satoKentänArvo,
        [kuivaaineKenttä]: kuivaaineKentänArvo,
        [Fields.Korjattuala]: korjattualaKentänArvo,
      } = rehunurmenSatotiedot;

      /**
       * Tehdään tarkistukset, vain jos korjattua alaa on määritetty,
       * ja nämä kentät ovat näkyvissä.
       */
      if (!korjattualaKentänArvo) return true;

      if (!!satoKentänArvo && !kuivaaineKentänArvo) {
        return new yup.ValidationError(
          t(
            "fields.errors.rehunurmensatotiedot.kokonaissadonkuivaaineprosenttiannettu"
          ),
          kuivaaineKentänArvo,
          `RehunurmenSatotiedot.${kuivaaineKenttä}`
        );
      }

      return true;
    };

const testKokonaissatoSäilörehuEsikuivattuProsenttiDefinition = (
  t: TTranslate
) => ({
  name: "Kokonaissadon säilörehu (esikuivattu) on määritetty tarvittaessa",
  test: testKokonaissadonKuivaaineProsenttiAnnettu(
    Fields.SäilörehuEsikuivattuYhteensäKg,
    Fields.SäilörehuEsikuivattuKuivaaineProsentti,
    t
  ),
});

const testKokonaissatoSäilörehuTuoreProsenttiDefinition = (t: TTranslate) => ({
  name: "Kokonaissadon säilörehu (tuore) on määritetty tarvittaessa",
  test: testKokonaissadonKuivaaineProsenttiAnnettu(
    Fields.SäilörehuYhteensäKg,
    Fields.SäilörehuKuivaaineProsentti,
    t
  ),
});

const testKokonaissatoNiittorehuProsenttiDefinition = (t: TTranslate) => ({
  name: "Kokonaissadon niittorehu on määritetty tarvittaessa",
  test: testKokonaissadonKuivaaineProsenttiAnnettu(
    Fields.NiittorehuYhteensäKg,
    Fields.NiittorehuKuivaaineProsentti,
    t
  ),
});

//TODO: REMOVE 'ANY' TYPING
const testKorjuualaAgainstOtherFields =
  (t: TTranslate) => (rehunurmenSatotiedot: any, context: any) => {
    if (!rehunurmenSatotiedot || rehunurmenSatotiedot.length < 3) return true;
    const korjattuala = rehunurmenSatotiedot[Fields.Korjattuala] ?? 0;

    const laskeKorjuukerranSumma = (rivi: any = {}) =>
      (rivi[Fields.Säilörehu] || 0) +
      (rivi[Fields.Kuivaheinä] || 0) +
      (rivi[Fields.SäilörehuEsikuivattu] || 0) +
      (rivi[Fields.Niittorehu] || 0);

    const kenttasumma =
      korjattuala - laskeKorjuukerranSumma(rehunurmenSatotiedot.Korjuu[0]);

    if (korjattuala === null || korjattuala === undefined || korjattuala === 0)
      return true;

    if (korjattuala < 0)
      return context.createError({
        message: t("fields.errors.korjattualaeivoiollanegatiivinen"),
      });

    if (
      !decimals.eq(
        laskeKorjuukerranSumma(rehunurmenSatotiedot.Korjuu[0]),
        korjattuala
      )
    )
      return context.createError({
        message: t("fields.errors.korjuu1eroaakokonaiskorjuusta", {
          erotus:
            decimals.difference(
              laskeKorjuukerranSumma(rehunurmenSatotiedot.Korjuu[0]),
              korjattuala
            ) || "-",
        }),
      });

    if (laskeKorjuukerranSumma(rehunurmenSatotiedot.Korjuu[1]) > korjattuala)
      return context.createError({
        message: t("fields.errors.korjuu2eroaakokonaiskorjuusta", {
          erotus:
            decimals.difference(
              laskeKorjuukerranSumma(rehunurmenSatotiedot.Korjuu[1]),
              korjattuala
            ) || "-",
        }),
      });

    if (laskeKorjuukerranSumma(rehunurmenSatotiedot.Korjuu[2]) > korjattuala)
      return context.createError({
        message: t("fields.errors.korjuu3eroaakokonaiskorjuusta", {
          erotus:
            decimals.difference(
              laskeKorjuukerranSumma(rehunurmenSatotiedot.Korjuu[2]),
              korjattuala
            ) || "-",
        }),
      });

    return true;
  };

const testKorjuualaAgainstOtherFieldsDefinition = (t: TTranslate) => ({
  /*
    This test is used to determine whether or not the first member of the fields
      array has a value in the field 'korjuuala'
    Than checking whether or not the other fields in the same array have a value.
      Lastly, checking whether or not the sum of these other fields, is the same or less than korjuuala field's value
  */
  name: "sumOfFields",
  exclusive: false,
  test: testKorjuualaAgainstOtherFields(t),
});

const testlimitfields =
  (
    satoKenttä: Fields,
    korjattualaKenttä: Fields,
    numberLimit: number,
    plantname: string,
    t: TTranslate
  ) =>
    (rehunurmenSatotiedot: any): boolean | yup.ValidationError => {
      const { [satoKenttä]: satoKentanArvo, Korjuu } = rehunurmenSatotiedot;

      // Sum values for target field for each Korjuu
      const korjattualaKentänArvo = Korjuu.map(
        (row: any) => row[korjattualaKenttä]
      )
        .filter((n: any) => n !== undefined)
        .reduce((a: any, b: any) => Number(a) + Number(b), 0);

      /**
       * Tehdään tarkistukset, vain jos korjattua alaa on määritetty,
       * ja nämä kentät ovat näkyvissä.
       */
      if (!korjattualaKentänArvo) return true;

      if (satoKentanArvo / korjattualaKentänArvo > numberLimit) {
        return new yup.ValidationError(
          t("fields.errors.satotaso.hehtaarisatoylittääsallitunarvon", {
            plantname,
            numberLimit,
          }),
          satoKentanArvo,
          `RehunurmenSatotiedot.${satoKenttä}`
        );
      } else {
        return true;
      }
    };

const testKuivaheinaLimitDefinition = (t: TTranslate) => ({
  name: "Kokonaissato ei saa ylittää 9 000kg/ha kuivaheinässä.",
  test: testlimitfields(
    Fields.KuivaheinäYhteensäKg,
    Fields.Kuivaheinä,
    9000,
    t("RehunurmenSatotiedot.Kuivaheinän"),
    t
  ),
});

const testTuoresailorehuLimitDefinition = (t: TTranslate) => ({
  name: "Kokonaissato ei saa ylittää 40 000kg/ha tuoresäilörehussa.",
  test: testlimitfields(
    Fields.SäilörehuYhteensäKg,
    Fields.Säilörehu,
    40000,
    t("RehunurmenSatotiedot.TuoresäilöttySäilörehun"),
    t
  ),
});

const testEsikuivattuSailorehuLimitDefinition = (t: TTranslate) => ({
  name: "Kokonaissato ei saa ylittää 40 000kg/ha esikuivatussa säilörehussa.",
  test: testlimitfields(
    Fields.SäilörehuEsikuivattuYhteensäKg,
    Fields.SäilörehuEsikuivattu,
    40000,
    t("RehunurmenSatotiedot.EsikuivattuSäilörehun"),
    t
  ),
});

const testNiittorehuLimitDefinition = (t: TTranslate) => ({
  name: "Kokonaissato ei saa ylittää 40 000kg/ha niittorehussa",
  test: testlimitfields(
    Fields.NiittorehuYhteensäKg,
    Fields.Niittorehu,
    40000,
    t("RehunurmenSatotiedot.Niittorehun"),
    t
  ),
});


export default (t: TTranslate) =>
  yup.object().shape({
    RehunurmenSatotiedot: yup
      .object()
      .test(testKuivaheinaLimitDefinition(t))
      .test(testTuoresailorehuLimitDefinition(t))
      .test(testEsikuivattuSailorehuLimitDefinition(t))
      .test(testNiittorehuLimitDefinition(t))
      .test(testKorjuualaAgainstOtherFieldsDefinition(t))
      .test(testKokonaissatoKuivaheinäDefinition(t))
      .test(testKokonaissatoSäilörehuEsikuivattuDefinition(t))
      .test(testKokonaissatoSäilörehuTuoreDefinition(t))
      .test(testKokonaissatoNiittorehuDefinition(t))
      .test(testKokonaissatoSäilörehuEsikuivattuProsenttiDefinition(t))
      .test(testKokonaissatoSäilörehuTuoreProsenttiDefinition(t))
      .test(testKokonaissatoNiittorehuProsenttiDefinition(t))
      .shape({
        [Fields.Korjuuala]: validators.defaultNumber,
        [Fields.Pystyynmyynti]: validators.thousands,
        [Fields.Pystyynosto]: validators.thousands,
        [Fields.Katoala]: validators.thousands,
        [Fields.Korjattuala]: validators.thousands,

        [Fields.KuivaheinäYhteensäKg]: validators.millions,
        [Fields.SäilörehuYhteensäKg]: nullableNumber.when(
          `RehunurmenSatotiedot.${Fields.Korjattuala}`,
          {
            is: (value: number) => value > 0,
            then: defaultNumber
              .notNull(1)
              .max(99999999, "fields.errors.säilörehuSatoYhteensäMax"),
          }
        ),
        [Fields.SäilörehuKuivaaineProsentti]: nullableNumber
          .when(`RehunurmenSatotiedot.${Fields.Korjattuala}`, {
            is: (value: number) => value > 0,
            then: validators.nurmirehuKuivaaineProsentti,
          })
          .min(10, "fields.errors.säilörehuKuivaaineProsenttiMin")
          .max(60, "fields.errors.säilörehuKuivaaineProsenttiMax"),
        [Fields.SäilörehuEsikuivattuYhteensäKg]: nullableNumber.when(
          `RehunurmenSatotiedot.${Fields.Korjattuala}`,
          {
            is: (value: number) => value > 0,
            then: defaultNumber.notNull(1),
          }
        ),
        [Fields.SäilörehuEsikuivattuKuivaaineProsentti]: nullableNumber
          .when(`RehunurmenSatotiedot.${Fields.Korjattuala}`, {
            is: (value: number) => value > 0,
            then: validators.säilörehuEsikuivattuKuivaaineProsentti,
          })
          .min(15, "fields.errors.säilörehuEsikuivattuKuivaaineProsenttiMin")
          .max(70, "fields.errors.säilörehuEsikuivattuKuivaaineProsenttiMax"),
        [Fields.NiittorehuYhteensäKg]: validators.millions,
        [Fields.NiittorehuKuivaaineProsentti]: nullableNumber
          .when(`RehunurmenSatotiedot.${Fields.Korjattuala}`, {
            is: (value: number) => value > 0,
            then: validators.nurmirehuKuivaaineProsentti,
          })
          .min(10, "fields.errors.niittorehuKuivaaineProsenttiMin")
          .max(60, "fields.errors.niittorehuKuivaaineProsenttiMax"),
        Korjuu: yup.array().of(
          yup.object().shape({
            [Fields.Korjuu]: yup
              .string()
              .when(`RehunurmenSatotiedot.${Fields.Korjattuala}`, {
                is: (value: number) => value > 0,
                then: yup.string().required(),
              }),
            [Fields.Kuivaheinä]: validators.thousands,
            [Fields.Säilörehu]: validators.säilörehu,
            [Fields.SäilörehuEsikuivattu]: validators.säilörehu,
            [Fields.Niittorehu]: validators.thousands,
          })
        ),
      }),
  });
