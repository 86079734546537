import { pick } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import RootState from "../../../../store/types";
import { Summary, SummaryData } from "../../../common/Summary";
import { Fields } from "./viljanSatotiedot.schema";
export default function YhteenvetoViljanSatotiedot() {
  const { t } = useTranslation();
  const values = useSelector(({ form }: RootState) =>
    form.ViljanSatotiedot.map((row: any) => {
      let summary = pick(row, [Fields.Kasvilaji, Fields.Viljelyala]) as any;

      if (row[Fields.KorjattuKuivana]) {
        summary["ViljanSatotiedot.KuivattavaVilja"] = pick(row, [
          Fields.KorjuualaKuivattavaVilja,
          Fields.SatomääräKuivattavaVilja,
          Fields.SatotasoKuivattavaVilja,
        ]);
      }

      if (row[Fields.KorjattuTuoresäilörehu]) {
        summary["ViljanSatotiedot.TuoreenaKorjattavaVilja"] = pick(row, [
          Fields.KorjuualaTuoresäilövilja,
          Fields.SatomääräTuoresäilövilja,
          Fields.SatotasoTuoresäilövilja,
          Fields.KosteusTuoresäilövilja,
        ]);
      }

      if (row[Fields.KorjattuKokoviljasäilörehu]) {
        summary["ViljanSatotiedot.Kokoviljasäilörehu"] = pick(row, [
          Fields.KorjuualaKokoviljasäilörehu,
          Fields.SatomääräKokoviljasäilörehu,
          Fields.SatotasoKokoviljasäilörehu,
          Fields.KuivaaineKokoviljasäilörehu,
        ]);
      }

      if (row.Kasvilaji === "_386583") {
        summary = {
          ...summary,
          ...pick(row, [
            Fields.KorjuualaVihantavilja,
            Fields.SatomääräVihantavilja,
            Fields.SatotasoVihantavilja,
            Fields.KuivaaineVihantavilja,
          ]),
        };
      }

      if (row.Kasvilaji === "_61201") {
        summary = {
          ...summary,
          ...pick(row, [
            Fields.KorjuualaMallasohra,
            Fields.SatomääräMallasohra,
            Fields.SatotasoKuivattavaVilja,
          ]),
        };
      }

      return summary;
    })
  );

  if (!values || !values.length) return null;

  return (
    <Summary title={t("ViljanSatotiedot.title")} data={values as SummaryData} />
  );
}
