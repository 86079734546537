import React from "react";
import { FieldArrayWithId } from "react-hook-form";
import { ViljanSatotiedotForm } from "./viljanSatotiedot.schema";

export const CropFieldContext = React.createContext({
  field: {} as FieldArrayWithId<ViljanSatotiedotForm>,
  index: 0,
});

export default function CropFieldProvider({
  field,
  index,
  children,
}: FieldArrayWithId<ViljanSatotiedotForm> & React.PropsWithChildren<any>) {
  return (
    <CropFieldContext.Provider value={{ field, index }}>
      {children}
    </CropFieldContext.Provider>
  );
}
