import React from "react";
import Fade from "./Fade";
import FormContent from "./FormContent";

const FormPage = ({ children }: React.PropsWithChildren<any>) => (
  <FormContent>
    <Fade>{children}</Fade>
  </FormContent>
);

export default FormPage;
