import { Box, Typography } from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";

export default function NoPresetsForPage() {
  return (
    <Box
      p={8}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "4px solid rgba(179, 92, 0, 0.3)",
      }}
    >
      <Typography>
        <Trans
          i18nKey={"common.eiEsitäyttötietoja"}
          components={{
            b: <b />,
          }}
        />
      </Typography>
    </Box>
  );
}

export function NoPreviouslyHarvestedPotato() {
  return (
    <Box
      p={8}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "4px solid rgba(179, 92, 0, 0.3)",
      }}
    >
      <Typography>
        <Trans
          i18nKey={"common.eiEdellisvuodenPerunasatoa"}
          components={{
            b: <b />,
          }}
        />
      </Typography>
    </Box>
  );
}
