import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import RootState from "../../../../store/types";
import { Summary, SummaryData } from "../../../common/Summary";
import { VarastoViljat } from "./varastotiedot.schema";
import { viljat } from "./ViljakohtaisetKentat";

interface ICustomSummaryRowHeaderProps {
  id: string;
}

const CustomSummaryRowHeader = ({ id }: ICustomSummaryRowHeaderProps) => {
  const { t } = useTranslation();

  if (!Object.keys(viljat).includes(id)) return null;

  const title = viljat[id as VarastoViljat];

  if (!title) return null;

  return <>{t(title)}</>;
};

const YhteenvetoVarastotiedot = () => {
  const { t } = useTranslation();
  const values = useSelector(
    ({ form }: RootState) => form.Varastotiedot as any
  );

  if (!values || !values.length) return null;

  return (
    <Summary
      CustomSummaryRowHeader={CustomSummaryRowHeader}
      idAttribute="kasvilaji"
      title={t("Varastotiedot.title")}
      data={values as SummaryData}
    />
  );
};

export default YhteenvetoVarastotiedot;
