import { yupResolver } from "@hookform/resolvers/yup";
import {
FormControl,
FormControlLabel,
FormHelperText,
FormLabel,
Radio,
RadioGroup,
TextField
} from "@mui/material";
import React from "react";
import { Controller,FormProvider,useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch,useSelector } from "react-redux";
import paths,{ AppPathWithInquiry } from "../../paths";
import { setFormValues } from "../../store/form/actions";
import { FormState } from "../../store/form/types";
import RootState from "../../store/types";
import { formInquiryPath } from "../../utils";
import DefaultActions from "./DefaultActions";
import FormActions from "./FormActions";
import FormPage from "./FormPage";
import useNavigateWithSearch from "./useNavigateWithSearch";
import {
FieldsVastauskoodi,
FieldsVastauskoodiKommentti,
vastauskoodiKommenttiSchemaValidator,
VASTAUSKOODIT,
VASTAUSKOODIT_NUMS
} from "./vastauskoodit.schema";

interface VastausKooditProps {
  nextPath: AppPathWithInquiry;
}

const useVastauskoodiValues = () => {
  const { vastauskoodi, vastauskoodiKommentti } = useSelector(
    ({ form }: RootState) => ({
      vastauskoodi: form.vastauskoodi || "",
      vastauskoodiKommentti: form.vastauskoodiKommentti || "",
    })
  );

  return {
    vastauskoodiKommentti: {
      [FieldsVastauskoodiKommentti.VastauskoodiKommentti]:
        vastauskoodiKommentti[
          FieldsVastauskoodiKommentti.VastauskoodiKommentti
        ],
    },
    vastauskoodi,
  };
};

export default function Vastauskoodit({ nextPath }: VastausKooditProps) {
  const dispatch = useDispatch();
  const navigate = useNavigateWithSearch();
  const { t } = useTranslation();
  const { vastauskoodi, vastauskoodiKommentti } = useVastauskoodiValues();

  const methods = useForm<any>({
    shouldFocusError: true,
    reValidateMode: "onBlur",
    defaultValues: {
      vastauskoodi: Object.keys(vastauskoodi)[0],
      vastauskoodiKommentti,
    },
    resolver: yupResolver(vastauskoodiKommenttiSchemaValidator),
  });

  const mapVastauskoodi = (vk?: Partial<FieldsVastauskoodi> | undefined) => {
    if (!vk) return;

    return {
      vastauskoodi: {
        [vk]: VASTAUSKOODIT_NUMS[vk],
      },
    };
  };

  const handleNextClick = (values: Partial<FormState>) => {
    const isInformationAvailable = values.vastauskoodi === FieldsVastauskoodi.INFORMATION_AVAILABLE
    const step = isInformationAvailable ? 0 : 8;
    const vastauskooditOut = mapVastauskoodi(
      values.vastauskoodi as unknown as Partial<FieldsVastauskoodi>
    );
    dispatch(setFormValues({ ...values, ...vastauskooditOut, step }));
    navigate(
      isInformationAvailable ? nextPath : formInquiryPath(paths.yhteenveto)
    );
  };

  const lisatietoError =
    methods.formState.errors.Lisatiedot &&
    methods.formState.errors.Lisatiedot[
      FieldsVastauskoodiKommentti.VastauskoodiKommentti
    ]?.message;

  return (
    <FormProvider {...methods}>
      <FormPage>
        <FormControl component="fieldset">
          <FormLabel component="legend">{t("common.vastauskoodit")}</FormLabel>
          <Controller
            control={methods.control}
            name="vastauskoodi"
            defaultValue={4}
            render={({ field }) => (
              <RadioGroup {...field}>
                {Object.values(VASTAUSKOODIT).map((koodi, i) => (
                  <FormControlLabel
                    key={koodi}
                    value={koodi}
                    control={<Radio />}
                    label={t(`vastauskoodit.${i + 1}`)}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </FormControl>
        <Controller
          name={`vastauskoodiKommentti.${FieldsVastauskoodiKommentti.VastauskoodiKommentti}`}
          control={methods.control}
          render={({ field: { ref, ...field } }) => (
            <>
              <TextField
                variant="standard"
                inputRef={ref}
                {...field}
                error={!!lisatietoError}
                multiline
                fullWidth
                label={t("fields.Lisätiedot")}
              />
              {!!lisatietoError && (
                <FormHelperText error={true}>
                  {t(lisatietoError)}
                </FormHelperText>
              )}
            </>
          )}
        />
        <FormActions>
          <span />
          <DefaultActions onNext={handleNextClick} />
        </FormActions>
      </FormPage>
    </FormProvider>
  );
}
