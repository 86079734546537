import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import ShrinkingTitle from "./ShrinkingTitle";

const useStyles = makeStyles((theme) => ({
  title: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

export default function KyselyOtsake({ title }: { title: string }) {
  const classes = useStyles();

  return (
    <div className={classes.title}>
      <ShrinkingTitle variant="h5" title={title}></ShrinkingTitle>
    </div>
  );
}
