import * as yup from "yup";

export enum Fields {
  Lisatiedot = "_6037162990",
}

export type Lisatiedot = {
  [Fields.Lisatiedot]: string | null;
};

export type LisatiedotForm = {
  Lisatiedot: Lisatiedot;
};

// regex joka löytää ei sallitut merkit
const invalidCharactersRegex = /[`#$^&*_[\]{};\\|<>~]/;

export const lisatiedotSchemaValidator = () => {
  return yup.object().shape({
    Lisatiedot: yup.object().shape({
      [Fields.Lisatiedot]: yup
        .string()
        .test(
          "invalidCharacters",
          "fields.errors.invalidCharacters",
          (item: string | undefined | null) =>
            item ? !item.match(invalidCharactersRegex) : true
        )
        .trim(),
    }),
  });
};
