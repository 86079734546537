import * as yup from "yup";
import { NumberSchema } from "yup";
import {
  nullableNumber,
  testSatotasoMax,
  validators,
} from "../../../common/FormUtils";

export type PerunanSatotiedotForm = {
  PerunanSatotiedot: PerunanSatotieto[];
};

//Peruna
export enum Fields {
  Kasvilaji = "Kasvilaji",
  Istutusala = "_610920917",
  Korjuuala = "_610920919",
  Satomäärä = "_566014545",
  Sadostamyyty1510 = "_5660386979",
  Varastossa1510 = "_5660386963",
  Satotaso = "_Satotaso",
  IstutusalaYhteensä = "_610920917386899",
  KorjattuAlaYhteensä = "_610920919386899",
  KorjaamatonAlaYhteensä = "_610914549386899",
}

type TKasvilaji = "_14555" | "_20921" | "_21575" | "_386987" | "_386983";

export class PerunanSatotieto {
  constructor(Kasvilaji: TKasvilaji, IstutusAla?: number) {
    this[Fields.Kasvilaji] = Kasvilaji;

    if (IstutusAla) this[Fields.Istutusala] = IstutusAla;
  }
  [Fields.Kasvilaji]: TKasvilaji;
  [Fields.Istutusala]: number | null = null;
  [Fields.Korjuuala]: number | null = null;
  [Fields.Satomäärä]: number | null = null;
  [Fields.Sadostamyyty1510]: number | null = null;
  [Fields.Varastossa1510]: number | null = null;
  [Fields.Satotaso]: number | null = null;
}

export default yup.object().shape({
  PerunanSatotiedotYhteensa: yup.object().shape({
    [Fields.IstutusalaYhteensä]: nullableNumber,
    [Fields.KorjattuAlaYhteensä]: nullableNumber.test(
      "korjattualaMax",
      "fields.errors.korjattualaYlittääIstutusalan",
      function (item) {
        if (!item) return true;

        if (item > this.parent[Fields.IstutusalaYhteensä]) return false;

        return true;
      }
    ),
  }),
  PerunanSatotiedot: yup.array().of(
    yup.object().shape({
      [Fields.Kasvilaji]: yup.string().required(),
      [Fields.Istutusala]: validators.istutusalaNotZero.notNull(0),
      [Fields.Korjuuala]: validators.thousands.requiredWith(
        Fields.Istutusala,
        "fields.validation.numberRequired"
      ),
      [Fields.Satomäärä]: nullableNumber.requiredWith(
        Fields.Korjuuala,
        "fields.validation.numberRequired"
      ),
      [Fields.Sadostamyyty1510]: nullableNumber.when(Fields.Kasvilaji, {
        is: (value: string) => value === "_14555" || value === "_20921",
        then: nullableNumber.when(Fields.Korjuuala, {
          is: (value: number | null) => value !== null && value !== 0,
          then: (schema: yup.AnySchema) =>
            schema.test(
              "varastoarvotDifferFromHarvested",
              "errors.varastoarvotEivätTäsmää",
              testVarastoarvotEqualsFarmedArea
            ),
        }),
      }),
      [Fields.Varastossa1510]: nullableNumber.when(Fields.Kasvilaji, {
        is: (value: string) => value === "_14555" || value === "_20921",
        then: nullableNumber.when(Fields.Korjuuala, {
          is: (value: number | null) => value !== null && value !== 0,
          then: (schema: yup.AnySchema) =>
            schema.test(
              "varastoarvotDifferFromHarvested",
              "errors.varastoarvotEivätTäsmää",
              testVarastoarvotEqualsFarmedArea
            ),
        }),
      }),
      [Fields.Satotaso]: nullableNumber.test(
        ...testSatotasoMax<TKasvilaji>(
          {
            _14555: 80000,
            _20921: 80000,
            _21575: 50000,
            _386987: 80000,
            _386983: 80000,
          },
          {
            kasvilaji: Fields.Kasvilaji,
          }
        )
      ),
    })
  ),
});

function testVarastoarvotEqualsFarmedArea(
  this: yup.TestContext<NumberSchema>,
  item: number | null
) {
  if (
    this.parent[Fields.Sadostamyyty1510] +
      this.parent[Fields.Varastossa1510] ===
      this.parent[Fields.Satomäärä] ||
    (this.parent[Fields.Sadostamyyty1510] === 0 &&
      this.parent[Fields.Varastossa1510] === 0 &&
      this.parent[Fields.Satomäärä] === null)
  )
    return true;
  else return false;
}
