import {
  FormControl,
  TextField as MuiTextField,
  TextFieldProps,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { get, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FieldTooltip from "./FieldTooltip";
import NumberFormatter from "./NumberFormatter";

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      ["@media print"]: {
        borderBottom: "solid !important",
      },
    },
  })
);

/**
 * Pick a correct value based on props, to be given to `react-number-format`.
 *
 * @see resetting value in react-number-format happens by passing empty string (`""`) - https://github.com/s-yadav/react-number-format/issues/528#issuecomment-825787850
 * @param props.value - value
 * @param props.allowZeroForInputValue - is 0 allowed value, or should it clear field
 * @returns `values.floatValue` or `""` for missing values
 */
const pickValueForInputComponent = ({
  value,
  allowZeroForInputValue = true,
}: {
  value?: number | null | unknown;
  allowZeroForInputValue?: boolean;
}) => {
  if (value === 0 && allowZeroForInputValue) return 0;

  // Resetting a value happens by giving `NumberFormatter` an empty string (`""`).
  // See link to issue #528 in jsdoc.
  if (!value) return "";

  return value;
};

export default function NumberField({
  allowZeroForInputValue,
  ...props
}: TextFieldProps & {
  helpText?: string;
  tooltip?: string;
  allowZeroForInputValue?: boolean;
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { formState } = useFormContext();

  return (
    <FieldTooltip title={props.tooltip}>
      <FormControl
        fullWidth={props.fullWidth}
        error={!!get(formState.errors, props.name as string)}
      >
        <MuiTextField
          {...props}
          color={props.color || "primary"}
          variant={props.variant || "standard"}
          className={classes.input}
          InputProps={{
            ...props.InputProps,
            value: pickValueForInputComponent({
              ...props,
              allowZeroForInputValue,
            }),
            inputComponent: NumberFormatter,
          }}
          error={!!get(formState.errors, props.name as string)}
          helperText={t(get(formState.errors, props.name as string)?.message)}
        />
      </FormControl>
    </FieldTooltip>
  );
}
