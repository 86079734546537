import { Alert, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { FieldError } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  error: {
    color: "#d32f2f",
    margin: "10px 0",
  },
}));

export interface IYUPVirheilmoitus {
  id: string;
  errors: any;
  type: string | string[];
}

const YUPVirheilmoitus = ({ errors, id, type }: IYUPVirheilmoitus) => {
  const classes = useStyles();

  const virhe = errors[id] as FieldError;

  if (!virhe) return null;
  if (type && virhe.type !== type) return null;

  const { message: viesti } = virhe;

  return (
    <div className="sato-yup-error-message">
      <Alert severity="error">
        <Typography>{viesti}</Typography>
      </Alert>
    </div>
  );
};

export default YUPVirheilmoitus;
