import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    textShadow:
      "0.01em 0 rgba(0, 0, 0, 0.2),0 0.01em rgba(0, 0, 0, 0.2),-0.01em 0 rgba(0, 0, 0, 0.2),0 -0.01em rgba(0, 0, 0, 0.2)",
  },
}));

export default function Title({
  children,
  props,
}: React.PropsWithChildren<any>) {
  const classes = useStyles();

  return (
    <Box {...props} className={classes.box} pr={8} pl={8} pt={8} pb={8}>
      {children}
    </Box>
  );
}
