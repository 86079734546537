import { yupResolver } from "@hookform/resolvers/yup";
import * as mui from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AppPathWithInquiry } from "../../../../paths";
import { setFormValues } from "../../../../store/form/actions";
import { useVarastoarvot } from "../../../../store/form/selectors";
import { FormState } from "../../../../store/form/types";
import DefaultActions from "../../../common/DefaultActions";
import Fade from "../../../common/Fade";
import FormActions from "../../../common/FormActions";
import FormContent from "../../../common/FormContent";
import { hasDirtyFields } from "../../../common/FormUtils";
import MobileActions from "../../../common/MobileActions";
import MobileFooter from "../../../common/MobileFooter";
import ConfirmationPrompt from "../../../common/Prompt/ConfirmationPrompt";
import useNavigateWithSearch from "../../../common/useNavigateWithSearch";
import OnkoVarastoaKentat from "./OnkoVarastoaKentat";
import schema from "./varastotiedot.schema";
import ViljakohtaisetKentat from "./ViljakohtaisetKentat";

type VarastotiedotFields = Pick<FormState, "Varastotiedot">;

const useFormMovement = ({
  nextUrl,
  previousUrl,
}: {
  nextUrl: AppPathWithInquiry;
  previousUrl: AppPathWithInquiry;
}) => {
  const navigate = useNavigateWithSearch();
  const dispatch = useDispatch();

  const handlePreviousClick = () => {
    navigate(previousUrl);
  };

  const handleNextClick = (values: Partial<VarastotiedotFields>) => {
    dispatch(setFormValues({ ...values, step: 2 }));
    navigate(nextUrl);
  };

  return { handleNextClick, handlePreviousClick };
};

const FormWrapper = ({ children }: React.PropsWithChildren<{}>) => {
  const varastoarvot = useVarastoarvot();
  const methods = useForm<VarastotiedotFields>({
    shouldFocusError: true,
    mode: "onSubmit",
    defaultValues: {
      Varastotiedot: varastoarvot,
    },
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });

  const nextUrl = "/sato/valkuaiskasvien-satotiedot";
  const previousUrl = "/sato/viljan-satotiedot";
  const { handleNextClick, handlePreviousClick } = useFormMovement({
    nextUrl,
    previousUrl,
  });

  return (
    <FormProvider {...methods}>
      <FormContent>
        <Fade>
          <mui.Box>
            {children}
            <FormActions>
              <DefaultActions
                onNext={handleNextClick}
                onPrevious={handlePreviousClick}
              />
            </FormActions>
          </mui.Box>
        </Fade>
      </FormContent>
      <ConfirmationPrompt
        open={
          hasDirtyFields(methods.formState) && !methods.formState.isSubmitting
        }
      />
      <MobileFooter>
        <MobileActions
          step={"2/9"}
          onNext={handleNextClick}
          onPrevious={handlePreviousClick}
        />
      </MobileFooter>
    </FormProvider>
  );
};

const Otsikko = () => {
  const { t } = useTranslation();

  const teksti = t("Varastotiedot.title");

  return (
    <mui.Box>
      <mui.Typography variant="h3">2. {teksti}</mui.Typography>
    </mui.Box>
  );
};

const Varastotiedot = () => (
  <FormWrapper>
    <Otsikko />
    <OnkoVarastoaKentat />
    <ViljakohtaisetKentat />
  </FormWrapper>
);

export default Varastotiedot;
