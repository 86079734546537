{
  "kirjaudu.title": "Vällkommen till Naturresursinstitutets applikation!",
  "kirjaudu.ohjeteksti1.1": "kirjaudu.ohjeteksti1.1",
  "kirjaudu.ohjeteksti1.2": "kirjaudu.ohjeteksti1.2",
  "kirjaudu.ohjeteksti1": "Välkommen att svara till Naturresursinstitutets elektroniska datainsamling. Uppgifterna du lämnar behandlas konfidentiellt och de används endast för statistiska ändamål.",
  "kirjaudu.suomifi": "Identifiera dig",
  "kirjaudu.tunnistautuminenEpaonnistui.title": "",
  "kirjaudu.tunnistautuminenEpaonnistui.return": "Gå till startsidan",
  "common.app": "",
  "common.logout": "Logga ut",
  "common.edellinen": "Föregående sida",
  "common.seuraava": "Fortsätt till nästa",
  "common.kirjautumisvirhe": "",
  "common.syota_vastaajatunnus": "Svararkoden",
  "common.lähetä": "Skicka",
  "common.hello": "Hej",
  "common.fi": "Suomeksi",
  "common.se": "På svenska",
  "common.satotilastokysely": "Statistikförfrågan om skörden",
  "common.peruna": "Skörd av potatis samt lagermängd",
  "common.loggedOut": "",
  "common.timedOut": "",
  "common.toFrontpage": "Gå till startsidan",
  "virhe.404.sivuaEiLoytynyt.virhe": "Sidan kunde inte hittas",
  "virhe.404.sivuaEiLoytynyt.ohje.alku": "",
  "virhe.404.sivuaEiLoytynyt.ohje.linkki": "",
  "virhe.404.kysymyssarjaaEiLoytynyt.virhe": "",
  "virhe.404.kysymyssarjaaEiLoytynyt.ohje": "",
  "virhe.errorboundary.kysymyssarja.virhe": "",
  "virhe.errorboundary.kysymyssarja.ohje": "",
  "virhe.errorboundary.router.virhe": "",
  "virhe.errorboundary.router.ohje": "",
  "muutViljelyKasvit.title": "Skördar av övriga odlingsväxter",
  "muutViljelyKasvit.oljykasvit.title": "",
  "muutViljelyKasvit.oljykasvit.kevätrypsi": "Vårrybs",
  "muutViljelyKasvit.oljykasvit.kevätrapsi": "Vårraps",
  "muutViljelyKasvit.oljykasvit.syysrypsi": "Höstrybs",
  "muutViljelyKasvit.oljykasvit.syysrapsi": "Höstraps",
  "muutViljelyKasvit.kumina": "Kummin",
  "muutViljelyKasvit.tattari": "Bovete",
  "muutViljelyKasvit.sokerijuurikas": "Sockerbeta",
  "muutViljelyKasvit.timoteinsiemen": "Timotejfrö",
  "muutViljelyKasvit.oljykasvit.öljypellava": "Oljelin",
  "muutViljelyKasvit.oljykasvit.öljyhamppu": "Oljehampa",
  "muutViljelyKasvit.viljelyala": "Odlingsareal",
  "muutViljelyKasvit.korjuuala": "Skördeareal",
  "common.ha": "ha",
  "common.kg": "kg",
  "common.kg-ha": "kg/ha",
  "common.ei": "",
  "common.kyllä": "",
  "sato.title": "Statistikförfrågan om skörden",
  "sato.description1.1": "Välkommen att svara på en statistikförfrågan om produktionen av åkergrödor i Finland 2022.",
  "sato.description1.1.2": "I förfrågan deltar cirka 6 200 gårdar av olika storlekar och med olika produktionsformer runt om i Finland. Av dessa är cirka 700 gårdar i ekologisk produktion.",
  "sato.description1.2": "Förfrågan till er gård inkluderar sådda och skördade arealer samt totala skördar av åkergrödor, höstsådda arealer samt lagervolymer av spannmål.",
  "sato.description1.2.1": "Gårdar med ekologisk produktion ombes enbart lämna uppgifter om den åkerareal som används för ekologisk produktion. Av uppgifterna sammanställs Finlands officiella statistik, varav den viktigaste är skördestatistiken.",
  "sato.luovutus": "Uppgifter på objektsnivå lämnas underinga omständigheter ut för administrativa beslut, undersökningar, tillsyn ellerannan motsvarande",

  "peruna.title": "Skörd av potatis samt lagermängd",
  "peruna.description1.1": "Välkommen att svara på en statistikförfrågan om gårdarnas lagervolymer av mat- och livsmedelsindustripotatis samt om årets produktion av åkergrödor i Finland.",
  "peruna.description1.2": "I förfrågan deltar cirka 200 gårdar runt om i Finland. Av uppgifterna sammanställs statistik över lagren av matpotatis och skördestatistik.",
  "peruna.ohjeteksti.SatotiedotOhje": "Korjuuala ei sisällä katoalaa",

  "footer.saavutettavuus": "Tillgänglighetsutlåtande",
  "footer.tietosuoja": "Dataskyddsbeskrivning",
  "ViljanSatotiedot.title": "Spannmålsskörd",
  "kasvilajit.label": "",
  "ViljanSatotiedot.KuivattavaVilja": "Torr säd",
  "ViljanSatotiedot.TuoreenaKorjattavaVilja": "Ensilerat spannmål",
  "ViljanSatotiedot.Kokoviljasäilörehu": "Helsädesensilage",
  "ViljanSatotiedot.Vihantavilja": "Grönfodersäd",
  "ViljanSatotiedot.lajiohjeet": "",
  "Varastotiedot.title": "",
  "Varastotiedot.ohje": "",
  "Varastotiedot.vehnä": "Vete",
  "Varastotiedot.ruis": "Råg",
  "Varastotiedot.kaura": "Havre",
  "Varastotiedot.ohra": "Korn",
  "fields.Kuivakorjuuala": "",
  "fields.Viljelyala": "Odlingsareal",
  "fields.KuivattuKokonaismäärä": "Totalmängden av torkad skörd",
  "fields.VarastoarvoHeinäkuu": "",
  "fields.VarastoarvoTammikuuArvio": "",
  "fields.onkoVarastossaViljaa": "",
  "fields.TuoreviljanKorjuuala": "Skördeareal för färsk spannmål",
  "fields.TuoresadonKokonaismäärä": "Total mängd färsk skörd",
  "fields.KokoviljasäilörehuKorjuuala": "Skördeareal för helsädesensilage",
  "fields.KokoviljasäilörehuKokonaismäärä": "Total mängd för skörd av helsädesensilage",
  "fields.KuivaaineProsentti": "Torrsubstanshalt i procent",
  "fields.Kosteusprosentti": "Fukthalt i procent",
  "fields.Vihantavilja": "Skördades grönfoderspannmål på gården?",
  "fields.VihantaviljaKorjuuala": "Skördeareal",
  "fields.VihantaviljaKokonaismäärä": "Skörden totalt",
  "fields.VihantaviljaKuivaaineProsentti": "Torrsubstanshalt i procent",
  "fields.SadonKokonaismäärä": "Skörden totalt",
  "fields.Kylvoala": "Sådd areal",
  "fields.Suorakylvoala": "Direktsådd areal",
  "fields.KuivasadonSatotaso": "Skördenivån för torr skörd kg/ha",
  "fields.TuoresadonSatotaso": "Skördenivån för färsk skörd kg/ha",
  "fields.Korjuuala": "Skördeareal",
  "fields.SatoYhteensä": "Skörden totalt",
  "fields.Niittorehu": "Saftfoder",
  "fields.Säilörehu": "Ensilage, färskensilering",
  "fields.SäilörehuEsikuivattu": "Ensilage, förtorkning",
  "fields.Pystyynmyynti": "Areal som sålts på rot",
  "fields.RehunurmialaYhteensä": "Fodervallareal totalt",
  "fields.Kuivaheinä": "Torrhö",
  "fields.SäilörehunKorjuuala": "",
  "fields.SäilörehuEsikuivattuKuivaaineProsentti": "Torrsubstanshalt för förtorkat ensilage",
  "fields.SäilörehuKuivaaineProsentti": "Torrsubstanshalt för färsk ensilage",
  "fields.NiittorehuKuivaaineProsentti": "Torrsubstanshalt för slåtterfoder",
  "fields.Istutusala": "Såningsareal",
  "fields.TuotettuSato": "Skörd som producerats på gården år {{vuosi}}",
  "fields.Elintarvikekäyttöön": "Skörd som blivit till livsmedel och vidareförädlats",
  "fields.Rehuksi": "Skörd som blivit till djurfoder",
  "fields.Kompostointiin": "Skörd som komposterats/blivit bioavfall",
  "fields.Energiaksi": "Skörd som blivit till energi",
  "fields.Muu": "Skörd som använts för annat ändamål",
  "fields.MuuSelite": "Förklaring av annan användning",
  "fields.KayttoYhteensa": "Användning totalt",
  "fields.Lisätiedot": "Tilläggs information",
  "fields.HävikkiKokolaatu": "Storlek, vikt, form eller annan faktor som gäller utseende och/eller köparens kriterier uppfylls inte",
  "fields.HävikkiSuurisato": "Större skörd än förutsett/skörden klar tidigare än förutsett",
  "fields.HävikkiMenekkivaikeudet": "Avsättningsproblem",
  "fields.HävikkiVarastointitappio": "Lagerförlust",
  "fields.Ylituotanto": "Överproduktion, olönsamhet och/eller ingen lämplig köpare",
  "fields.Kokopaino": "Storlek, vikt, form eller annan faktor som gäller utseende och/eller köparens kriterier uppfylls inte",
  "fields.TyövoimanSaatavuus": "Tillgång till arbetskraft",
  "fields.TekninenOngelma": "Tekniskt problem (t.ex. går lyftanordningen sönder)",
  "fields.Korjuutappio": "Skördeförlust",
  "fields.Kasvitaudit": "Växtsjukdomar, skadedjur",
  "fields.errors.Min": "Det angivna värdet ska vara större än 0.",
  "fields.satotaso": "Skördenivå",
  "fields.errors.MaxHundreds": "Värdet får högst vara 999,99 ha.",
  "fields.errors.MaxThousands": "Värdet får högst vara 9999,99 ha.",
  "fields.errors.MaxMillion": "Värdet får högst vara 999 999 kg.",
  "fields.errors.MaxMillions": "Värdet får högst vara 9 999 999 kg.",
  "fields.errors.TuoresäilöviljanKosteusprosenttiMin": "Fukthalten för färsk ensilerad spannmål ska uppgå till minst 11 %.",
  "fields.errors.TuoresäilöviljanKosteusprosenttiMax": "Fukthalten för färsk ensilerad spannmål får vara högst 80 %.",
  "fields.errors.ViljanKuivaaineProsenttiMin": "Torrsubstanshalten ska uppgå till minst 12 %.",
  "fields.errors.ViljanKuivaaineProsenttiMax": "Torrsubstanshalten får vara högst 80%.",
  "fields.errors.ValkuaiskasvienKosteusprosenttiMin": "Fukthalten för proteinväxtskörden ska uppgå till minst 5 %.",
  "fields.errors.ValkuaiskasvienKosteusprosenttiMax": "Fukthalten för proteinväxtskörden får vara högst 50 %.",
  "fields.errors.ValkuaiskasvienKuivaaineprosenttiMin": "Torrsubstanshalten för proteinväxtskörden ska uppgå till minst 12 %.",
  "fields.errors.ValkuaiskasvienKuivaaineProsenttiMax": "Torrsubstanshalten för proteinväxtskörden får vara högst 80 %.",
  "fields.errors.ÖljykasvienKosteusprosenttiMin": "Fukthalten för oljeväxtskörden ska uppgå till minst 5 %.",
  "fields.errors.ÖljykasvienKosteusprosenttiMax": "Fukthalten för oljeväxtskörden får vara högst 50 %.",
  "fields.errors.SäilörehuMax": "Ensilageskörden kan högst vara 99 999 999 kg.",
  "fields.errors.NurmirehuKuivaaineProsenttiMin": "Torrsubstanshalten för vall ska uppgå till minst 10 %.",
  "fields.errors.NurmirehuKuivaaineProsenttiMax": "Torrsubstanshalten för vall får vara högst 60%",
  "fields.errors.SäilörehuEsikuivattuKuivaaineprosenttiMin": "Torrsubstanshalten för förtorkat ensilage ska uppgå till minst 15 %.",
  "fields.errors.SäilörehuEsikuivattuKuivaaineprosenttiMax": "Torrsubstanshalten för förtorkat ensilage får vara högst 70 %.",
  "fields.errors.SäilörehuSatoYhteensä": "Ensilageskörden ska uppgå till mer än 0 kg.",
  "fields.errors.SäilörehuSatoYhteensäMax": "Ensilageskörden kan högst vara 99 999 999 kg.",
  "fields.errors.PercentageMaxSadonkäyttö": "Den sammanlagda användningen i procent kan uppgå till högst 100 %.",
  "fields.errors.yliKylvöalan": "Den direktsådda arealen får inte vara större än såddarealen.",
  "fields.errors.yliViljelyalan": "Skördearealen får inte överskrida odlingsarealen.",
  "fields.errors.yliIstutusalan": "Skördearealen får inte överskrida planteringsarealen.",
  "fields.errors.kuivattuSatoRequired": "Ange den totala mängden torkad skörd.",
  "fields.errors.kuivakorjuualaRequired": "Ange skördearealen för torr skörd.",
  "fields.errors.tuoresatoRequired": "Ange den totala mängden färsk skörd.",
  "fields.errors.kosteusprosenttiRequired": "Ange fukthalt i procent.",
  "fields.errors.kuivaaineprosenttiRequired": "Ange torrsubstanshalt i procent.",
  "fields.errors.sadonKokonaismääräRequired": "Ange den totala mängden skörd.",
  "fields.errors.kokoviljasäilörehukorjuualaRequired": "Ange den totala mängden skörd.",
  "fields.errors.kokoviljasäilörehusatoRequired": "Ange den totala mängden skörd.",
  "syyskylvot.title": "Höstsådda arealer och direktsådda arealer hösten",
  "syyskylvot.ohjeteksti": "Ange de hektar som såddes av gårdens åkerareal på hösten.",
  "syyskylvot.ohjeteksti1": "Med direktsådd areal avses att utsädet sås i skörderesterna för föregående växt.",
  "ValkuaiskasvienSatotiedot.title": "Skördar av proteingrödor",
  "ValkuaiskasvienSatotiedot.KuivattavaKasvi": "Torr",
  "ValkuaiskasvienSatotiedot.Säilörehu": "Ensilage",
  "RehunurmenSatotiedot.Kuivaheinä": "Torrhö",
  "RehunurmenSatotiedot.Säilörehu": "Ensilage, färskensilering",
  "RehunurmenSatotiedot.SäilörehuEsikuivattu": "Ensilage, förtorkning",
  "RehunurmenSatotiedot.Niittorehu": "Saftfoder",
  "RehunurmenSatotiedot.title": "Skörden av vallfoder",
  "PerunanSatotiedot.title": "Skörd av potatis",
  "PerunanSatotiedot.Ruokaperuna": "Matpotatis",
  "PerunanSatotiedot.Ruokateollisuusperuna": "Potatis för matindustri",
  "PerunanSatotiedot.Varhaisperuna": "Tidig potatis",
  "PerunanSatotiedot.Tärkkelysperuna": "Stärkelsepotatis",
  "PerunanSatotiedot.Siemenperuna": "Sättpotatis",
  "fields.Sadostamyyty1510": "Skördemängd som sålts före 15.10",
  "fields.Varastossa1510": "Mängdi lager 15.10",
  "fields.KorjaamatonSatoVaadittu": "Om inga grödor på rot blivit kvar i åkern, ska du skriva noll i punkten.",
  "fields.KorjaamatonSato": "Grödor på rot",
  "fields.MuuSyy": "Annan orsak, vilken?",
  "Lisätiedot.title": "Tilläggs information",
  "PerunanSatojaVarastot.title": "Skörd av potatis samt lagermängd",
  "PerunanLisätiedot.title": "Tilläggs information",
  "KasvistenSadonkäyttö.title": "Användningen av vegetabilier på gården",
  "KasvistenSadonkäyttö.KäytönOhje": "Ange vad skörden använts till. Om du inte har de exakta mängderna kan du göra en uppskattning utifrån medeltalet för tidigare år. I detta ingår all verksamhet på gården (lagring, beredning för saluföring, förpackning) före eventuell förädling.",
  "KasvistenSadonkäyttö.HävikkisyytOhje": "Bedöm varför en del av skörden används för något annat än livsmedelsbruk. Välj 1–2 av de viktigaste orsakerna.",
  "KorjaamatonSato.title": "Grödor på rot som blivit kvar i åkern",
  "KorjaamatonSato.KäytönOhje": "Om inga grödor på rot blivit kvar i åkern, ska du skriva noll i punkten.",
  "KorjaamatonSato.KorjaamatonSyytOhje": "Bedöm varför grödorna inte skördades. Välj 1–2 av de viktigaste orsakerna."
}
