import * as yup from "yup";

export enum FieldsVastauskoodiKommentti {
  VastauskoodiKommentti = "60376442716299",
}

export enum FieldsVastauskoodi {
  NO_PRODUCTION = "215176442721507",
  NO_INFORMATION = "215176442714599",
  NO_CONTACT = "2151764427391379",
  INFORMATION_AVAILABLE = "215176442714597",
}

export const VASTAUSKOODIT = {
  NO_PRODUCTION: FieldsVastauskoodi.NO_PRODUCTION,
  NO_INFORMATION: FieldsVastauskoodi.NO_INFORMATION,
  NO_CONTACT: FieldsVastauskoodi.NO_CONTACT,
  INFORMATION_AVAILABLE: FieldsVastauskoodi.INFORMATION_AVAILABLE,
};

export const VASTAUSKOODIT_NUMS = {
  [FieldsVastauskoodi.NO_PRODUCTION]: 1,
  [FieldsVastauskoodi.NO_INFORMATION]: 2,
  [FieldsVastauskoodi.NO_CONTACT]: 3,
  [FieldsVastauskoodi.INFORMATION_AVAILABLE]: 4,
};

export type VastauskoodiKommentti = {
  [FieldsVastauskoodiKommentti.VastauskoodiKommentti]: string | null;
};

export type VastauskoodiKommenttiForm = {
  vastauskoodiKommentti: VastauskoodiKommentti;
  vastauskoodi?: Partial<Record<FieldsVastauskoodi, number>>;
};

// regex joka löytää ei sallitut merkit
const invalidCharactersRegex = /[`#$^&*_[\]{};\\|<>~]/;

export const vastauskoodiKommenttiSchemaValidator = yup.object().shape({
  vastauskoodi: yup
    .string()
    .oneOf([
      FieldsVastauskoodi.NO_PRODUCTION,
      FieldsVastauskoodi.NO_INFORMATION,
      FieldsVastauskoodi.NO_CONTACT,
      FieldsVastauskoodi.INFORMATION_AVAILABLE,
    ]),
  vastauskoodiKommentti: yup.object().shape({
    [FieldsVastauskoodiKommentti.VastauskoodiKommentti]: yup
      .string()
      .test(
        "invalidCharacters",
        "fields.errors.invalidCharacters",
        (item: string | undefined | null) =>
          item ? !item.match(invalidCharactersRegex) : true
      )
      .trim(),
  }),
});
