import { useSelector } from "react-redux";
import { ValkuaiskasvienSatotieto } from "../../components/Forms/Sato/ValkuaiskasvienSatotiedot/valkuaiskasvienSatotiedot.schema";
import RootState from "../types";
import { Valkuaiskasvi, Viljakasvi } from "./types";

export type ViljakasviOptions = {
  [key: string]: Viljakasvi;
};

export type ValkuaiskasviOptions = {
  [key: string]: Partial<ValkuaiskasvienSatotieto> & Valkuaiskasvi;
};

export function useTilatyyppi(): number {
  return useSelector(({ meta }: RootState) => meta.ttyyppi) || 1;
}

export function useValkuaiskasviOptions(): ValkuaiskasviOptions {
  const viljakasvit = useSelector((state: RootState) =>
    state.meta.valkuaiskasvit.sort((a, b) => a.order - b.order)
  );
  const korjuutavat = useSelector(
    (state: RootState) => state.form.ValkuaiskasvienSatotiedot
  );

  const opts: ViljakasviOptions = {};

  viljakasvit.forEach((row) => {
    opts[row.id] = {
      ...row,
      ...korjuutavat.find((k) => k.Kasvilaji === row.id),
    };
  });

  return opts;
}
