import * as mui from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { isDevelopment } from "../../../utils";

export interface IBannerProps {
  message: string;
  additionalInformation: string | undefined;
  onClose: (event: Event | React.SyntheticEvent, reason: string) => void;
  severity?: mui.AlertColor;
}

const tenSecondsInMS = 10000;

const ErrorBanner = (props: IBannerProps) => (
  <Banner severity="error" {...props} />
);

const Banner = ({
  message,
  additionalInformation,
  severity,
  onClose,
}: IBannerProps) => {
  const { t } = useTranslation();

  return (
    <mui.Snackbar
      open={true}
      autoHideDuration={tenSecondsInMS}
      onClose={onClose}
    >
      <mui.Alert severity={severity}>
        {t(message)}
        {isDevelopment && additionalInformation && (
          <> ({additionalInformation})</>
        )}
      </mui.Alert>
    </mui.Snackbar>
  );
};

export { ErrorBanner, Banner };
