import { styled, Typography } from "@mui/material";
import React from "react";
import { SubmitHandler, useFormContext } from "react-hook-form";
import { FormState } from "../../store/form/types";
import { NextButton, PrevButton } from "./Buttons";

const Stretcher = styled("span")`
  flex: 1;
`;

const Step = styled(Typography)`
  background-color: #b35c00;
  color: white;
  display: flex;
  align-items: center;
`;

export default function MobileActions({
  onNext,
  onPrevious,
  step,
}: {
  step?: string;
  onNext?: SubmitHandler<Partial<FormState>>;
  onPrevious?: any;
}) {
  const methods = useFormContext();

  return (
    <>
      {onPrevious === undefined ? (
        <Stretcher />
      ) : (
        <PrevButton sx={{ border: 0 }} disableElevation onClick={onPrevious} />
      )}
      <Step>{step}</Step>
      {onNext !== undefined && (
        <NextButton
          sx={{ border: 0 }}
          disableElevation
          onClick={methods.handleSubmit(onNext)}
        ></NextButton>
      )}
    </>
  );
}
