import React from "react";

interface IProps {
  children: React.ReactNode;
  Virhenakyma: React.ElementType;
}

interface IState {
  virheTapahtunut: boolean;
}

class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { virheTapahtunut: false };
  }

  static getDerivedStateFromError() {
    return { virheTapahtunut: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // TODO: logita virhe
  }

  render() {
    const { Virhenakyma } = this.props;

    if (this.state.virheTapahtunut) {
      return <Virhenakyma />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
