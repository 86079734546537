import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DELETE_TOKEN } from "../../store/auth/types";
import { RESET_VALUES } from "../../store/form/types";
import useTokenState from "./useTokenState";
import useTokenUser from "./useTokenUserInfo";

export default function useLogout() {
  const token = useTokenState();
  const navigate = useNavigate();
  const user = useTokenUser();
  const dispatch = useDispatch();

  return async () => {
    dispatch({ type: DELETE_TOKEN });
    dispatch({ type: RESET_VALUES });
    localStorage.clear();

    // Redirect to SAML logout if not in development or using non-Suomifi token.
    if (user && user.upn) {
      navigate("/uloskirjauduttu");
    } else {
      window.location.href =
        process.env.REACT_APP_LOGOUT_URL + `?t=${token}&app_id=sato`;
    }
  };
}
