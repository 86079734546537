import { Box, Typography } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Fields } from "./valkuaiskasvienSatotiedot.schema";

export default function NoFarmedArea({ index }: any) {
  const { t } = useTranslation();

  const methods = useFormContext();

  const viljelyAla = methods.watch(
    `ValkuaiskasvienSatotiedot.${index}.${Fields.Viljelyala}`
  );

  return (
    <>
      {viljelyAla === 0 && (
        <Box
          sx={{
            width: "100%",
            minHeight: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography>
            {t("ValkuaiskasvienSatotiedot.eiKorjattuaAlaa")}
          </Typography>
        </Box>
      )}
    </>
  );
}
