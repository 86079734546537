import { Grid } from "@mui/material";
import React from "react";
import { Controller, useFieldArray, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { HehtaariField } from "../../../common/Fields";
import FormSection from "../../../common/FormSection";
import FormSectionTitle from "../../../common/FormSectionTitle";
import {
  Fields,
  RehunurmenSatotiedotForm,
} from "./rehunurmenSatotiedot.schema";

interface IKorjuualaOsio {
  methods: UseFormReturn<RehunurmenSatotiedotForm, any>;
}

const KorjuualaOsio = ({ methods }: IKorjuualaOsio) => {
  const { t } = useTranslation();

  const { fields } = useFieldArray({
    control: methods.control,
    name: "RehunurmenSatotiedot.Korjuu",
  });

  return (
    <>
      <FormSectionTitle>
        {t("RehunurmenSatotiedot.otsikko.Korjuukerrat")}
      </FormSectionTitle>
      {fields.map((field, index) => (
        <FormSection key={field[Fields.Korjuu]}>
          <FormSectionTitle>
            {index + 1}. {t("fields.Korjuukerta")}
          </FormSectionTitle>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              {field[Fields.Korjuu] === "_386967" && (
                <Controller
                  name={`RehunurmenSatotiedot.Korjuu.${index}.${Fields.Kuivaheinä}`}
                  control={methods.control}
                  render={({ field: { ref, ...field } }) => (
                    <HehtaariField
                      inputRef={ref}
                      {...field}
                      label={"Kuivaheinä"}
                      fullWidth
                    />
                  )}
                />
              )}
              <Controller
                name={`RehunurmenSatotiedot.Korjuu.${index}.${Fields.Säilörehu}`}
                control={methods.control}
                render={({ field: { ref, ...field } }) => (
                  <HehtaariField
                    inputRef={ref}
                    {...field}
                    label={"Säilörehu"}
                    fullWidth
                  />
                )}
              />
              <Controller
                name={`RehunurmenSatotiedot.Korjuu.${index}.${Fields.SäilörehuEsikuivattu}`}
                control={methods.control}
                render={({ field: { ref, ...field } }) => (
                  <HehtaariField
                    inputRef={ref}
                    {...field}
                    label={"SäilörehuEsikuivattu"}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                name={`RehunurmenSatotiedot.Korjuu.${index}.${Fields.Niittorehu}`}
                control={methods.control}
                render={({ field: { ref, ...field } }) => (
                  <HehtaariField
                    inputRef={ref}
                    {...field}
                    label={"Niittorehu"}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </FormSection>
      ))}
    </>
  );
};

export default KorjuualaOsio;
