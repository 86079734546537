// ==== action type types ====

export enum ErrorActionType {
  SET_ERROR = "SET_ERROR",
  DELETE_ERROR = "DELETE_ERROR",
}

// ==== action object types ====

export interface ISetErrorAction {
  type: ErrorActionType.SET_ERROR;
  payload: IError;
}

export interface IDeleteErrorAction {
  type: ErrorActionType.DELETE_ERROR;
  payload?: number;
}

// ==== states ====

export interface IError {
  id: number;
  message: string;
  additionalInformation?: string;
}

export interface IErrorState {
  errors: IError[];
}

// ==== available actions ====

export type ErrorActions = ISetErrorAction | IDeleteErrorAction;
