import { Button } from "@mui/material";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useBreakpoints from "../../../../breakpoints";
import paths from "../../../../paths";
import { useIsInquiryDataAvailable } from "../../../../store/form/selectors";
import RootState from "../../../../store/types";
import { formInquiryPath } from "../../../../utils";
import { FormActions } from "../../../common";
import { FinishButton } from "../../../common/Buttons";
import DefaultActions from "../../../common/DefaultActions";
import FormInstructions from "../../../common/FormInstructions";
import FormPage from "../../../common/FormPage";
import FormPageTitle from "../../../common/FormPageTitle";
import MobileActions from "../../../common/MobileActions";
import MobileFooter from "../../../common/MobileFooter";
import useFinishForm from "../../../common/useFinishForm";
import useFocusFirstField from "../../../common/useFocus";
import useNavigateWithSearch from "../../../common/useNavigateWithSearch";
import YhteenvetoLisatiedot from "../Lisatiedot/YhteenvetoLisatiedot";
import YhteenvetoMuutViljelykasvit from "../MuutViljelykasvit/YhteenvetoMuutViljelykasvit";
import YhteenvetoPerunanSatotiedot from "../PerunanSatotiedot/YhteenvetoPerunanSatotiedot";
import YhteenvetoRehunurmenSatotiedot from "../RehunurmenSatotiedot/YhteenvetoRehunurmenSatotiedot";
import YhteenvetoSyyskylvot from "../Syyskylvot/YhteenvetoSyyskylvot";
import YhteenvetoValkuaiskasvienSatotiedot from "../ValkuaiskasvienSatotiedot/YhteenvetoValkuaiskasvienSatotiedot";
import YhteenvetoVarastotiedot from "../Varastotiedot/YhteenvetoVarastotiedot";
import YhteenvetoViljanSatotiedot from "../ViljanSatotiedot/YhteenvetoViljanSatotiedot";
import PrintHeader from "./PrintHeader";

export const PrintButton = ({ fullWidth, onClick }: any) => {
  const { t } = useTranslation();

  return (
    <Button
      fullWidth={!!fullWidth}
      variant="contained"
      sx={{ marginRight: "16px" }}
      onClick={onClick}
    >
      {t("common.download_pdf")}
    </Button>
  );
};

export function SatoSummaries() {
  return (
    <>
      <PrintHeader title={"common.satoPdfTitle"} />
      <YhteenvetoViljanSatotiedot />
      <YhteenvetoVarastotiedot />
      <YhteenvetoValkuaiskasvienSatotiedot />
      <YhteenvetoMuutViljelykasvit />
      <YhteenvetoPerunanSatotiedot />
      <YhteenvetoSyyskylvot />
      <YhteenvetoRehunurmenSatotiedot />
      <YhteenvetoLisatiedot />
    </>
  );
}

export default function Yhteenveto() {
  const id = useSelector((state: RootState) => state.form.periodId);
  const canFillInForms = useIsInquiryDataAvailable();
  const { finish } = useFinishForm(id);
  const navigate = useNavigateWithSearch();
  const breakpoints = useBreakpoints();

  const { t } = useTranslation();

  const handlePreviousClick = () => {
    navigate(
      formInquiryPath(canFillInForms ? paths.lisatiedot : paths.vastauskoodit)
    );
  };

  const handleFinishClick = async () => {
    await finish.execute();
    navigate(formInquiryPath(paths.kiitos));
  };

  const handleDownloadClick = () => {
    window.print();
  };

  useFocusFirstField();

  return (
    <>
      <FormPage>
        <FormPageTitle
          sx={{
            ["@media print"]: {
              display: "none",
            },
          }}
        >
          9. {t("yhteenveto.title")}
        </FormPageTitle>
        <FormInstructions>
          <Trans
            i18nKey={"yhteenveto.ohjeteksti"}
            components={{
              b: <b />,
              p: <p />,
            }}
          />
        </FormInstructions>
        <div className={breakpoints.mobilePrintButton}>
          <PrintButton fullWidth onClick={handleDownloadClick} />
        </div>
        <SatoSummaries />
        <FormActions>
          <DefaultActions onPrevious={handlePreviousClick} />
          <div>
            <PrintButton onClick={handleDownloadClick} />
            <FinishButton
              disabled={finish.loading || !canFillInForms}
              onClick={handleFinishClick}
            >
              {t("common.finish")}
            </FinishButton>
          </div>
        </FormActions>
      </FormPage>
      <MobileFooter>
        <MobileActions onPrevious={handlePreviousClick} step={"9/9"} />
        <FinishButton
          disableElevation
          sx={{ border: 0 }}
          onClick={handleFinishClick}
          disabled={finish.loading}
        />
      </MobileFooter>
    </>
  );
}
