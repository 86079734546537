import * as utilDecimals from "@lukefi-private/sato-util-numbers/decimals";
import { t } from "i18next";
import { sum } from "lodash";
import { FormState } from "react-hook-form";
import * as yup from "yup";
import { TestFunction } from "yup";
import { AnyObject } from "yup/lib/types";

type stringNumberNull = string | number | null;

// -------------------------------- HELPER FUNCTIONS -----------------------------------------

const transformValueToNullableNumber = (_: any, value: stringNumberNull) => {
  if (typeof value === "string") {
    // Yup interprets value as string and it needs to be handled
    if (value === "") return null;
    else {
      // Replace dot with comma, trim out whitespaces
      value = value.replace(/,/, ".");
      value = value.replaceAll(/\s/gi, "");

      return +value;
    }
  } else return value; // Value is number or null
};

// -------------------------------- VALIDATORS -----------------------------------------

// Validation check for incorrect data, not seen by user
// can be null, x >= 0
export const defaultNumber = yup
  .number()
  .typeError(t("fields.validation.numberRequired"));

export const nullableNumber = yup
  .number()
  .min(0)
  .transform(transformValueToNullableNumber)
  .nullable(true);

export const getNumberValue = (value: number | string | null): number => {
  let input = value?.toString();

  if (!input) return 0;

  input = input?.toString().replace(/ /g, "");
  input = input?.replace(/,/, ".");

  return Number(input);
};

function requiredWith(this: yup.NumberSchema, field: string, message: string) {
  return this.when(field, {
    is: (value: number | null) => {
      return !!value;
    },
    then: (schema: yup.AnySchema) => schema.required(message),
  });
}

yup.addMethod(yup.number, "requiredWith", requiredWith);

/* 
  Non-zero non-nullable number with the following rules
  != null & MIN >= 0
  min can be set to zero in order to let other yup validation functions
  pass their error messages when a minimum is reached
 */
function notNull(this: yup.NumberSchema, minimum?: number, message?: string) {
  message = t(message || "fields.validation.numberRequired");

  if (minimum === undefined) {
    return this.required(message);
  } else return this.required(message).min(minimum, message);
}

yup.addMethod(yup.number, "notNull", notNull);

/**
 * Test satotaso's maximum value.
 *
 * Example usage:
 *
 * ```
 * type TKasvilaji =
 *   | "ruokaperuna"
 *   | "ruokateollisuusperuna"
 *   | "varhaisperuna"
 *   | "tarkkelysperuna"
 *   | "siemenperuna";
 *
 * [Fields.Satotaso]: defaultNumber.test(
 *   ...testSatotasoMax<TKasvilaji>(
 *     {
 *       ruokaperuna: 80000,
 *       ruokateollisuusperuna: 80000,
 *       varhaisperuna: 50000,
 *       tarkkelysperuna: 80000,
 *       siemenperuna: 80000,
 *     },
 *     {
 *       kasvilaji: Fields.Kasvilaji,
 *     }
 *   )
 * ),
 * ```
 *
 * @param maximumValues - object with keys of generic type EKasvilaji and values of number
 * @param fields - field name of "kasvilaji" in parent
 * @returns array that can be desctructured as params for yup's `test` function
 */
export function testSatotasoMax<Kasvilaji extends string>(
  maximumValues: { [key in Kasvilaji]: number | null },
  fields: { kasvilaji: string }
): [string, string, TestFunction<number | undefined | null, AnyObject>] {
  return [
    "satotasoMax",
    "fields.errors.satotaso.hehtaarisatoylittääsallitunarvon",
    function (satotaso?: number | null | "") {
      if (!satotaso) return true;

      const kasvilaji = this.parent[fields.kasvilaji] as Kasvilaji;
      const max = maximumValues[kasvilaji] as number | undefined;

      if (!max) return true;

      return satotaso <= max;
    },
  ];
}

export const validators = {
  boolean: yup.boolean().nullable(),
  defaultNumber: defaultNumber.min(0, "field.errors.Min"),
  istutusalaNotZero: nullableNumber.required("fields.errors.istutusalaNotZero"),
  percentage: nullableNumber.min(0).max(100, "fields.errors.percentageMax"),
  million: nullableNumber
    .min(0, "fields.errors.Min")
    .max(1000000, "fields.errors.MaxMillion"),
  millions: nullableNumber
    .min(0, "fields.errors.Min")
    .max(10000000, "fields.errors.MaxMillions"),
  hundreds: nullableNumber
    .min(0, "fields.errors.Min")
    .max(999.99, "fields.errors.MaxHundreds"),
  thousands: nullableNumber
    .min(0, "fields.errors.Min")
    .max(9999.99, "fields.errors.MaxThousands"),
  tuoreSäilöviljanKosteusprosentti: nullableNumber
    .min(11, "fields.errors.TuoresäilöviljanKosteusprosenttiMin")
    .max(80, "fields.errors.TuoresäilöviljanKosteusprosenttiMax"),
  viljanKuivaaineProsentti: nullableNumber
    .min(12, "fields.errors.ViljanKuivaaineProsenttiMin")
    .max(80, "fields.errors.ViljanKuivaaineProsenttiMax"),
  valkuaiskasvienKosteusprosentti: defaultNumber
    .notNull()
    .min(5, "fields.errors.ValkuaiskasvienKosteusprosenttiMin")
    .max(50, "fields.errors.ValkuaiskasvienKosteusprosenttiMax"),
  valkuaiskasvienKuivaaineProsentti: nullableNumber
    .min(9, "fields.errors.ValkuaiskasvienKuivaaineProsenttiMin")
    .max(80, "fields.errors.ValkuaiskasvienKuivaaineProsenttiMax"),
  öljykasvienKosteusprosentti: defaultNumber
    .notNull()
    .min(4, "fields.errors.ÖljykasvienKosteusprosenttiMin")
    .max(40, "fields.errors.ÖljykasvienKosteusprosenttiMax"),
  säilörehu: nullableNumber.max(99999999, "fields.errors.SäilörehuMax"),
  nurmirehuKuivaaineProsentti: defaultNumber
    .notNull()
    .min(10, "fields.errors.NurmirehuKuivaaineProsenttiMin")
    .max(60, "fields.errors.NurmirehuKuivaaineProsenttiMax"),
  säilörehuEsikuivattuKuivaaineProsentti: defaultNumber
    .notNull()
    .min(15, "fields.errors.SäilörehuEsikuivattuKuivaaineprosenttiMin")
    .max(70, "fields.errors.SäilörehuEsikuivattuKuivaaineprosenttiMax"),
  perunaSadonkäyttöProsentti: nullableNumber
    .min(0)
    .max(100, "fields.errors.PercentageMaxSadonkäyttö"),
  viljakasvienKorjuuala: nullableNumber.test(
    "harvestedAreaMax",
    "fields.errors.yliViljelyalan",
    function (item) {
      if (!item) return true;

      if (!this.parent._610920941) return true;

      return utilDecimals.gte(
        this.parent._610920941,
        sum([
          this.parent._610920919,
          this.parent._610920919ts,
          this.parent._610920919kvs,
        ])
      );
    }
  ),
  valkuaiskasvienKorjuuala: nullableNumber.test(
    "harvestedAreaMax",
    "fields.errors.yliViljelyalan",
    function (item) {
      if (!item) return true;

      if (!this.parent._610920941) return true;

      return utilDecimals.gte(
        this.parent._610920941,
        sum([
          this.parent._610920919,
          this.parent._610920919sr,
          this.parent._610920919_öljy,
        ])
      );
    }
  ),
};

export const hasDirtyFields = (state: FormState<any>): boolean => {
  return !!Object.keys(state.dirtyFields).length;
};

export { yup as extendedYup };
