import * as mui from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useDispatch } from "react-redux";
import { deleteError } from "../../../store/error/actions";
import { useErrors } from "../../../store/error/selectors";
import { ErrorBanner } from "../Banner/Banner";

const useStyles = makeStyles(() => ({
  box: {
    position: "absolute",
    top: 0,
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
}));

const BannerShower = ({}) => {
  const errors = useErrors();
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClose = (id: number) => () => {
    dispatch(deleteError(id));
  };

  return (
    <mui.Box className={classes.box}>
      {errors.map(({ id, message, additionalInformation }) => (
        <ErrorBanner
          key={id}
          onClose={handleClose(id)}
          message={message}
          additionalInformation={additionalInformation}
        />
      ))}
    </mui.Box>
  );
};

export default BannerShower;
