import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useTranslation } from "react-i18next";
import Virhe from "../Virhe";
import ErrorBoundary from "./ErrorBoundary";

const useStyles = makeStyles((theme) => ({
  teksti: {
    width: "100%",
    maxHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  virhe: {
    fontWeight: "700",
    marginBottom: "1rem",
  },
  ohje: {},
}));

const Virhenakyma = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.teksti}>
      <Virhe
        virhe={t("virhe.errorboundary.kysymyssarja.virhe")}
        ohje={t("virhe.errorboundary.kysymyssarja.ohje")}
      />
    </div>
  );
};

interface IProps {
  children?: React.ReactNode;
}

const KysymyssivuErrorBoundary = (props: IProps) => {
  const { children } = props;
  return <ErrorBoundary Virhenakyma={Virhenakyma}>{children}</ErrorBoundary>;
};

export default KysymyssivuErrorBoundary;

export { Virhenakyma };
