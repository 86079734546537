import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setFormValues } from "../../../../store/form/actions";
import { useValkuaiskasvienSatotiedotCrops } from "../../../../store/form/selectors";
import {
  useTilatyyppi,
  useValkuaiskasviOptions,
} from "../../../../store/meta/selectors";
import { FormActions } from "../../../common";
import DefaultActions from "../../../common/DefaultActions";
import { HehtaariField } from "../../../common/Fields";
import FormInstructions from "../../../common/FormInstructions";
import FormPage from "../../../common/FormPage";
import FormPageTitle from "../../../common/FormPageTitle";
import FormSection from "../../../common/FormSection";
import FormSectionTitle from "../../../common/FormSectionTitle";
import { hasDirtyFields } from "../../../common/FormUtils";
import Kasvilaji from "../../../common/Kasvilaji";
import MobileActions from "../../../common/MobileActions";
import MobileFooter from "../../../common/MobileFooter";
import NoPresetsForPage from "../../../common/NoPresetsForPage";
import ConfirmationPrompt from "../../../common/Prompt/ConfirmationPrompt";
import useFocusFirstField from "../../../common/useFocus";
import useNavigateWithSearch from "../../../common/useNavigateWithSearch";
import useSatotasoCalculator from "../../../common/useSatotasoCalculator";
import NoFarmedArea from "./NoFarmedArea";
import Oljykasvi from "./Oljykasvi";
import PuituSiemen from "./PuituSiemen";
import SailoRehu from "./SailoRehu";
import schema, {
  Fields,
  ValkuaiskasvienSatotiedotForm,
} from "./valkuaiskasvienSatotiedot.schema";

const useStyles = makeStyles(() => ({
  acreField: {
    minWidth: "275px",
  },
  sectionHeader: {
    "&:first-letter": {
      textTransform: "capitalize",
    },
  },
}));

export default function ValkuaiskasvienSatotiedot() {
  const classes = useStyles();
  const navigate = useNavigateWithSearch();
  const dispatch = useDispatch();
  const cropVals = useValkuaiskasvienSatotiedotCrops();
  const cropOpts = useValkuaiskasviOptions();
  const { t } = useTranslation();

  const methods = useForm<ValkuaiskasvienSatotiedotForm>({
    shouldFocusError: true,
    defaultValues: {
      ValkuaiskasvienSatotiedot: cropVals,
    },
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });

  const getSatotaso = useSatotasoCalculator();
  const ttyyppi = useTilatyyppi();

  const { fields } = useFieldArray({
    control: methods.control,
    name: "ValkuaiskasvienSatotiedot",
  });

  const handleNextClick = (values: Partial<ValkuaiskasvienSatotiedotForm>) => {
    dispatch(setFormValues({ ...values, step: 3 }));
    navigate("/sato/muiden-viljelykasvien-satotiedot");
  };
  const handlePreviousClick = (
    values: Partial<ValkuaiskasvienSatotiedotForm>
  ) => {
    navigate("/sato/viljan-varastot");
  };

  useFocusFirstField();

  return (
    <FormProvider {...methods}>
      <FormPage>
        <FormPageTitle>3. {t("ValkuaiskasvienSatotiedot.title")}</FormPageTitle>
        {cropVals?.length === 0 ? (
          <NoPresetsForPage />
        ) : (
          <>
            <FormInstructions>
              {ttyyppi === 2
                ? t("ValkuaiskasvienSatotiedot.ohjeteksti.tayttoohje.luomu")
                : t("ValkuaiskasvienSatotiedot.ohjeteksti.tayttoohje")}
            </FormInstructions>

            {fields.map((field, index) => (
              <FormSection key={field.Kasvilaji}>
                <FormSectionTitle>
                  <Kasvilaji id={field.Kasvilaji} />
                </FormSectionTitle>

                <Grid spacing={4} container item xs={12}>
                  <Grid item md={12}>
                    <Controller
                      name={`ValkuaiskasvienSatotiedot.${index}.${Fields.Viljelyala}`}
                      control={methods.control}
                      render={({ field: { ref, ...field } }) => (
                        <HehtaariField
                          inputRef={ref}
                          {...field}
                          className={classes.acreField}
                          label={"Viljelyala"}
                        />
                      )}
                    />
                  </Grid>
                  <NoFarmedArea index={index} />
                  <PuituSiemen field={field} index={index} />
                  <SailoRehu field={field} index={index} />
                  <Oljykasvi field={field} index={index} />
                </Grid>
              </FormSection>
            ))}
          </>
        )}
        <FormActions>
          <DefaultActions
            onNext={handleNextClick}
            onPrevious={handlePreviousClick}
          />
        </FormActions>
      </FormPage>
      <MobileFooter>
        <MobileActions
          step={"3/9"}
          onNext={handleNextClick}
          onPrevious={handlePreviousClick}
        />
      </MobileFooter>
      <ConfirmationPrompt
        open={
          hasDirtyFields(methods.formState) && !methods.formState.isSubmitting
        }
      />
    </FormProvider>
  );
}
