import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
  action: {
    textDecoration: "none",
  },
  media: {
    height: "140px",
  },
  title: {
    "&:first-letter": {
      textTransform: "capitalize",
    },
  },
  card: {
    height: "100%",
  },
}));

type ActionProps = {
  title: string;
  subtitle?: JSX.Element;
  href: string;
  image?: string;
};

export default function Action({ title, subtitle, image, href }: ActionProps) {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <Card className={classes.card} onClick={() => navigate(href)}>
      <CardActionArea>
        {image && (
          <CardMedia className={classes.media} title={title} image={image} />
        )}
        <CardContent>
          <Typography className={classes.title} gutterBottom variant="h3">
            {title}
          </Typography>
          {subtitle}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
