import { Box, styled, Typography } from "@mui/material";
import dfFormat from "date-fns/format";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import logoRgb from "../../../../assets/Luke_FI_virall_RGB.png";
import { default as logoSE } from "../../../../assets/Luke_SE_virall_RGB.png";
import svtLogo from "../../../../assets/svt_logo.jpg";
import RootState from "../../../../store/types";
import { useQuery } from "../../../common";
import useInquiries from "../../../common/useInquiries";
import useTokenMetadata from "../../../common/useTokenMetadata";
import { ActionStatus, StatusCode } from "../../Aloitus";

interface IAikaleima {
  pvm: string;
  klo: string;
}

const muodostaAikaleima = (aika?: string | number | Date): IAikaleima => {
  const nyt = aika ? new Date(aika) : new Date();
  const pvm = dfFormat(nyt, "d.M.y");
  const klo = dfFormat(nyt, "HH:mm");

  return { pvm, klo };
};

const GenerointiAikaleima = () => {
  const Aikaleima = styled(Typography)``;

  const aikaleima = muodostaAikaleima();

  return (
    <Aikaleima>
      {aikaleima.pvm} {aikaleima.klo}
    </Aikaleima>
  );
};

const LähetysAikaleima = () => {
  const lähetettyAikaleima = useSelector(
    (state: RootState) => state.form.finished_at
  );

  if (!lähetettyAikaleima) return null;

  const aikaleima = muodostaAikaleima(lähetettyAikaleima);

  return (
    <Typography variant="body1" component="span">
      ({aikaleima.pvm} {aikaleima.klo})
    </Typography>
  );
};

/**
 * Näytetään onko lomake aloitettu, kesken vai lähetetty.
 */
const LomakkeenTila = () => {
  const { t } = useTranslation();
  const { inquiries } = useInquiries();
  const query = useQuery();

  const SRunko = styled(Typography)``;
  const STila = styled("span")`
    & > * {
      display: inline;
    }
  `;

  const tila: StatusCode =
    inquiries.inquiries.find((i) => i.period_id === query.get("id"))?.status ||
    0;

  return (
    <SRunko>
      {t("common.status")}:{" "}
      <STila>
        <ActionStatus status={tila} />
        {tila === StatusCode.Lähetetty && (
          <>
            {" "}
            <LähetysAikaleima />
          </>
        )}
      </STila>
    </SRunko>
  );
};

const Tilatunnus = styled(Typography)``;
const Kysely = styled(Typography)``;

interface IPrintHeaderProps {
  title: string;
}

export default function PrintHeader({ title }: IPrintHeaderProps) {
  const { t, i18n } = useTranslation();
  const meta = useTokenMetadata();

  return (
    <Box
      sx={{
        display: "none",
        justifyContent: "space-between",
        ["@media print"]: {
          display: "flex",
        },
      }}
    >
      <div>
        {i18n.language === "fi" ? (
          <img
            style={{
              height: "100px",
            }}
            src={logoRgb}
            alt={"Luonnonvarakeskuksen logo"}
          />
        ) : (
          <img
            style={{
              height: "100px",
            }}
            src={logoSE}
            alt={"Luonnonvarakeskuksen logo"}
          />
        )}

        <Kysely>{t(title)}</Kysely>
        <GenerointiAikaleima />
        <Tilatunnus>
          {t("common.tilatunnus")}:{" "}
          {meta.find((row) => row.key === "TILTU")?.value}
        </Tilatunnus>
        <LomakkeenTila />
      </div>
      <div>
        <img style={{ maxWidth: "250px" }} src={svtLogo} />
      </div>
    </Box>
  );
}
