import { sum } from "lodash";
import * as yup from "yup";
import {
  nullableNumber,
  testSatotasoMax,
  validators,
} from "../../../common/FormUtils";

export interface ViljanSatotiedotForm {
  ViljanSatotiedot: Satotieto[];
}

type TKasvilaji =
  | "_386591"
  | "_386903"
  | "_387267"
  | "_386587"
  | "_386583"
  | "_61201"
  | "_61203"
  | "_386907"
  | "_61209"
  | "_61197"
  | "_386911"
  | "_386915";

export enum Fields {
  Kasvilaji = "Kasvilaji",
  KorjattuKuivana = "Korjattu_KuivattavaVilja",
  KorjattuTuoresäilörehu = "Korjattu_Tuoresailovilja",
  KorjattuKokoviljasäilörehu = "Korjattu_Kokoviljasailo",
  Viljelyala = "_610920941",
  KorjuualaKuivattavaVilja = "_610920919",
  SatomääräKuivattavaVilja = "_566014545kv",
  VarastoHeinäkuu = "_5660386923",
  VarastoTammikuu = "_5660386927",
  KorjuualaTuoresäilövilja = "_610920919ts",
  SatomääräTuoresäilövilja = "_566014545ts",
  KosteusTuoresäilövilja = "_6115386935",
  KuivaaineKokoviljasäilörehu = "_6115386931",
  KorjuualaVihantavilja = "_610920919vv",
  SatomääräVihantavilja = "_566014545vv",
  KorjuualaKokoviljasäilörehu = "_610920919kvs",
  SatomääräKokoviljasäilörehu = "_566014545kvs",
  SatotasoKuivattavaVilja = "_Satotaso_KuivattavaVilja",
  SatotasoTuoresäilövilja = "_Satotaso_Tuoresailovilja",
  SatotasoKokoviljasäilörehu = "_Satotaso_Kokoviljasailorehu",
  SatotasoVihantavilja = "_Satotaso_Vihantavilja",
  KuivaaineVihantavilja = "_6115386931vv",
  KorjuualaMallasohra = "610920919mo",
  SatomääräMallasohra = "566014545mo",
}

export class Satotieto {
  constructor(Kasvilaji: TKasvilaji, Viljelyala?: number) {
    this.Kasvilaji = Kasvilaji;
    if (Viljelyala) this[Fields.Viljelyala] = Viljelyala;
  }

  Kasvilaji: TKasvilaji;
  [Fields.KorjattuKuivana]: boolean = false;
  [Fields.KorjattuTuoresäilörehu]: boolean = false;
  [Fields.KorjattuKokoviljasäilörehu]: boolean = false;
  [Fields.Viljelyala]: number | null = null;
  [Fields.KorjuualaKuivattavaVilja]: number | null = null;
  [Fields.SatomääräKuivattavaVilja]: number | null = null;
  [Fields.VarastoHeinäkuu]: number | null = null;
  [Fields.VarastoTammikuu]: number | null = null;
  [Fields.KorjuualaTuoresäilövilja]: number | null = null;
  [Fields.SatomääräTuoresäilövilja]: number | null = null;
  [Fields.KosteusTuoresäilövilja]: number | null = null;
  [Fields.KuivaaineKokoviljasäilörehu]: number | null = null;
  [Fields.KorjuualaKokoviljasäilörehu]: number | null = null;
  [Fields.SatomääräKokoviljasäilörehu]: number | null = null;
  [Fields.SatomääräVihantavilja]: number | null = null;
  [Fields.KorjuualaVihantavilja]: number | null = null;
  [Fields.SatotasoKuivattavaVilja]: number | null = null;
  [Fields.SatotasoKokoviljasäilörehu]: number | null = null;
  [Fields.SatotasoTuoresäilövilja]: number | null = null;
  [Fields.SatotasoVihantavilja]: number | null = null;
  [Fields.KuivaaineVihantavilja]: number | null = null;
  [Fields.KorjuualaMallasohra]: number | null = null;
  [Fields.SatomääräMallasohra]: number | null = null;
}

export default yup.object().shape({
  ViljanSatotiedot: yup.array().of(
    yup.object().shape({
      [Fields.Kasvilaji]: yup.string().required(),
      [Fields.KorjattuKuivana]: validators.boolean.test(
        "korjuutapaRequired",
        function (item) {
          // Checkboxes are not required for vihantavilja or mallasohra
          if (
            this.parent[Fields.Kasvilaji] === "_386583" ||
            this.parent[Fields.Kasvilaji] === "_61201"
          )
            return true;

          // Return false if none of the checkboxes are checked
          if (
            this.parent[Fields.Viljelyala] &&
            !item &&
            !this.parent[Fields.KorjattuKokoviljasäilörehu] &&
            !this.parent[Fields.KorjattuTuoresäilörehu]
          )
            return false;

          return true;
        }
      ),
      [Fields.KorjattuTuoresäilörehu]: validators.boolean,
      [Fields.KorjattuKokoviljasäilörehu]: validators.boolean,
      [Fields.Viljelyala]: validators.thousands.notNull(
        0,
        "fields.errors.viljelyalaRequired"
      ),
      [Fields.KorjuualaKuivattavaVilja]:
        validators.viljakasvienKorjuuala.requiredWith(
          Fields.KorjattuKuivana,
          "fields.errors.kuivakorjuualaRequired"
        ),
      [Fields.SatomääräKuivattavaVilja]: validators.millions.requiredWith(
        Fields.KorjuualaKuivattavaVilja,
        "fields.errors.kuivattuSatoRequired"
      ),
      [Fields.KorjuualaTuoresäilövilja]:
        validators.viljakasvienKorjuuala.requiredWith(
          Fields.KorjattuTuoresäilörehu,
          "fields.errors.tuorekorjuualaRequired"
        ),
      [Fields.SatomääräTuoresäilövilja]: validators.millions.requiredWith(
        Fields.KorjuualaTuoresäilövilja,
        "fields.errors.tuoresatoRequired"
      ),
      [Fields.KosteusTuoresäilövilja]:
        validators.tuoreSäilöviljanKosteusprosentti.requiredWith(
          Fields.SatomääräTuoresäilövilja,
          "fields.errors.kosteusprosenttiRequired"
        ),
      [Fields.KorjuualaKokoviljasäilörehu]:
        validators.viljakasvienKorjuuala.requiredWith(
          Fields.KorjattuKokoviljasäilörehu,
          "fields.errors.kokoviljasäilörehukorjuualaRequired"
        ),
      [Fields.SatomääräKokoviljasäilörehu]: validators.millions.requiredWith(
        Fields.KorjuualaKokoviljasäilörehu,
        "fields.errors.kokoviljasäilörehusatoRequired"
      ),
      [Fields.KuivaaineKokoviljasäilörehu]:
        validators.viljanKuivaaineProsentti.requiredWith(
          Fields.SatomääräKokoviljasäilörehu,
          "fields.errors.kuivaaineprosenttiRequired"
        ),
      [Fields.SatomääräVihantavilja]: validators.millions.requiredWith(
        Fields.KorjuualaVihantavilja,
        "fields.errors.sadonKokonaismääräRequired"
      ),
      [Fields.KorjuualaVihantavilja]: nullableNumber
        .test(
          "korjuualaRequired",
          "fields.errors.korjuualaRequired",
          function (item) {
            if (
              this.parent[Fields.Kasvilaji] === "_386583" &&
              (item === undefined || item === null) &&
              this.parent[Fields.Viljelyala] !== 0
            ) {
              return false;
            }
            return true;
          }
        )
        .test(
          "harvestedAreaMax",
          "fields.errors.yliViljelyalan",
          function (item) {
            if (!item || this.parent[Fields.Kasvilaji] !== "_386583")
              return true;

            if (!this.parent[Fields.Viljelyala]) return true;

            return (
              this.parent[Fields.Viljelyala] >=
              sum([this.parent[Fields.KorjuualaVihantavilja]])
            );
          }
        ),
      [Fields.KuivaaineVihantavilja]:
        validators.viljanKuivaaineProsentti.requiredWith(
          Fields.KorjuualaVihantavilja,
          "fields.errors.kuivaaineprosenttiRequired"
        ),
      [Fields.KorjuualaMallasohra]: nullableNumber
        .test(
          "korjuualaRequired",
          "fields.errors.korjuualaRequired",
          function (item) {
            if (
              this.parent[Fields.Kasvilaji] === "_61201" &&
              this.parent[Fields.Viljelyala] &&
              (item === undefined || item === null)
            ) {
              return false;
            }

            return true;
          }
        )
        .test(
          "harvestedAreaMax",
          "fields.errors.yliViljelyalan",
          function (item) {
            if (!item || this.parent[Fields.Kasvilaji] !== "_61201")
              return true;

            if (!this.parent[Fields.Viljelyala]) return true;

            return (
              this.parent._610920941 >=
              sum([this.parent[Fields.KorjuualaMallasohra]])
            );
          }
        ),
      [Fields.SatomääräMallasohra]: validators.millions.requiredWith(
        Fields.KorjuualaMallasohra,
        "fields.errors.sadonKokonaismääräRequired"
      ),
      [Fields.SatotasoKokoviljasäilörehu]: nullableNumber.test(
        ...testSatotasoMax<TKasvilaji>(
          {
            _386591: 30000,
            _386903: 30000,
            _386587: 30000,
            _387267: 30000,
            _386583: null,
            _61201: null,
            _61209: 30000,
            _61203: 30000,
            _386907: 30000,
            _61197: 30000,
            _386911: 30000,
            _386915: 30000,
          },
          {
            kasvilaji: Fields.Kasvilaji,
          }
        )
      ),
      [Fields.SatotasoKuivattavaVilja]: nullableNumber.test(
        ...testSatotasoMax<TKasvilaji>(
          {
            _386591: 10000,
            _386903: 10000,
            _386587: 10000,
            _387267: 10000,
            _386583: null,
            _61201: 10000,
            _61209: 10000,
            _61203: 10000,
            _386907: 10000,
            _61197: 10000,
            _386911: 10000,
            _386915: 10000,
          },
          {
            kasvilaji: Fields.Kasvilaji,
          }
        )
      ),
      [Fields.SatotasoTuoresäilövilja]: nullableNumber.test(
        ...testSatotasoMax<TKasvilaji>(
          {
            _386591: 25000,
            _386903: 25000,
            _386587: 25000,
            _387267: 25000,
            _386583: null,
            _61201: null,
            _61209: 25000,
            _61203: 25000,
            _386907: 25000,
            _61197: 25000,
            _386911: 25000,
            _386915: 25000,
          },
          {
            kasvilaji: Fields.Kasvilaji,
          }
        )
      ),
      [Fields.SatotasoVihantavilja]: nullableNumber.test(
        ...testSatotasoMax<TKasvilaji>(
          {
            _386591: null,
            _386903: null,
            _386587: null,
            _387267: null,
            _386583: 35000,
            _61201: null,
            _61209: null,
            _61203: null,
            _386907: null,
            _61197: null,
            _386911: null,
            _386915: null,
          },
          {
            kasvilaji: Fields.Kasvilaji,
          }
        )
      ),
    })
  ),
});
