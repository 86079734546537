import { InputAdornment, TextFieldProps } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { NumberFormatValues } from "react-number-format";
import { Fields as PerunanSatotiedotFields } from "../Forms/Peruna/PerunanSatotiedot/perunanSatotiedot.schema";
import { Fields as RehunurmenSatotiedotFields } from "../Forms/Sato/RehunurmenSatotiedot/rehunurmenSatotiedot.schema";
import { Fields as SyyskylvotFields } from "../Forms/Sato/Syyskylvot/syyskylvot.schema";
import { Fields as ValkuaiskasvienSatotiedotFields } from "../Forms/Sato/ValkuaiskasvienSatotiedot/valkuaiskasvienSatotiedot.schema";
import { Fields as VarastotiedotFields } from "../Forms/Sato/Varastotiedot/varastotiedot.schema";
import { Fields as ViljanSatotiedotFields } from "../Forms/Sato/ViljanSatotiedot/viljanSatotiedot.schema";
import NumberField from "./NumberField";

type i18nextFieldProps = {
  /**
   * i18next interpolation values, in the shape of an object.
   * The prop is passed as a second param to i18n function `t`.
   * Keys are names of interpolated values, and values the inserts.
   */
  tProps?: Object;
};

type FieldProps = {
  label: string;
  name: string;
  tooltip?: string;
  readonly?: boolean;
  allowZeroForInputValue?: boolean;
} & i18nextFieldProps &
  TextFieldProps;

type HehtaariFieldProps = {
  allowNegative?: boolean;
} & FieldProps;

export function HehtaariField({
  name,
  label,
  tProps = {},
  allowNegative = false,
  ...rest
}: HehtaariFieldProps) {
  const { t } = useTranslation();

  return (
    <NumberField
      name={name}
      variant="standard"
      label={t(`fields.${label}`, tProps)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{t("common.ha")}</InputAdornment>
        ),
        inputProps: {
          maxLength: 8,
          allowNegative,
          decimalScale: 2,
          decimalSeparator: ",",
        },
      }}
      {...rest}
    />
  );
}

export function KgField({ name, label, tProps = {}, ...rest }: FieldProps) {
  const { t } = useTranslation();

  return (
    <NumberField
      variant="standard"
      name={name}
      label={t(`fields.${label}`, tProps)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{t("common.kg")}</InputAdornment>
        ),
        inputProps: {
          maxLength: 9,
          allowNegative: false,
          decimalSeparator: ",",
        },
      }}
      {...rest}
    />
  );
}

export function KgPerHaField({ name, label, ...rest }: FieldProps) {
  const { t } = useTranslation();

  return (
    <NumberField
      InputLabelProps={{
        shrink: true,
      }}
      variant="standard"
      name={name}
      label={t(`fields.${label}`)}
      InputProps={{
        readOnly: true,
        endAdornment: (
          <InputAdornment position="end">{t("common.kg-ha")}</InputAdornment>
        ),
        inputProps: {
          maxLength: 10,
          allowNegative: false,
          decimalScale: 2,
          decimalSeparator: ",",
        },
      }}
      {...rest}
    />
  );
}

export function ProsenttiField({
  name,
  label,
  InputProps,
  tProps = {},
  ...rest
}: FieldProps & { decimalScale?: number }) {
  const { t } = useTranslation();

  return (
    <NumberField
      variant="standard"
      name={name}
      label={t(`fields.${label}`, tProps)}
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
        inputProps: {
          maxLength: 5,
          decimalScale: rest.decimalScale || 2,
          allowNegative: false,
          decimalSeparator: ",",
          isAllowed: ({ floatValue }: NumberFormatValues) => {
            if (floatValue === undefined || isNaN(floatValue)) return true;

            return floatValue >= 0 && floatValue <= 100;
          },
        },
        ...InputProps,
      }}
      {...rest}
    />
  );
}

export function SatotasoField({ ...rest }: FieldProps) {
  const { t } = useTranslation();

  return (
    <KgPerHaField
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{t("common.kg-ha")}</InputAdornment>
        ),
        inputProps: {
          readOnly: true,
          maxLength: 10,
          allowNegative: false,
          decimalScale: 0,
          decimalSeparator: ",",
        },
      }}
      {...rest}
    />
  );
}

export const FieldMap: { [key: string]: { label: string; unit: string } } = {
  [ViljanSatotiedotFields.Viljelyala]: {
    label: "fields.Viljelyala",
    unit: "common.ha",
  },
  [ViljanSatotiedotFields.SatotasoKuivattavaVilja]: {
    label: "fields.Satotaso",
    unit: "common.kg-ha",
  },
  [ViljanSatotiedotFields.KorjuualaKuivattavaVilja]: {
    label: "fields.Korjuuala",
    unit: "common.ha",
  },
  [ViljanSatotiedotFields.SatomääräKuivattavaVilja]: {
    label: "fields.SatoYhteensä",
    unit: "common.kg",
  },
  [ViljanSatotiedotFields.KorjuualaVihantavilja]: {
    label: "fields.Korjuuala",
    unit: "common.ha",
  },
  [ViljanSatotiedotFields.SatomääräVihantavilja]: {
    label: "fields.SatoYhteensä",
    unit: "common.kg",
  },
  [ViljanSatotiedotFields.SatotasoVihantavilja]: {
    label: "fields.Satotaso",
    unit: "common.kg-ha",
  },
  [ViljanSatotiedotFields.KuivaaineVihantavilja]: {
    label: "fields.KuivaaineProsentti",
    unit: "%",
  },
  [VarastotiedotFields.VarastoarvioTammikuu]: {
    label: "fields.VarastoarvoTammikuuArvioYhteenveto",
    unit: "common.kg",
  },
  [VarastotiedotFields.VarastoarvoHeinäkuu]: {
    label: "fields.VarastoarvoHeinäkuuYhteenveto",
    unit: "common.kg",
  },
  [PerunanSatotiedotFields.Satotaso]: {
    label: "fields.Satotaso",
    unit: "common.kg-ha",
  },
  [PerunanSatotiedotFields.Istutusala]: {
    label: "fields.Istutusala",
    unit: "common.ha",
  },
  [ValkuaiskasvienSatotiedotFields.Sato]: {
    label: "fields.SatoYhteensä",
    unit: "common.kg",
  },
  [ValkuaiskasvienSatotiedotFields.SatotasoKuiva]: {
    label: "fields.Satotaso",
    unit: "common.kg-ha",
  },
  [ValkuaiskasvienSatotiedotFields.SatotasoTuore]: {
    label: "fields.Satotaso",
    unit: "common.kg-ha",
  },
  [ValkuaiskasvienSatotiedotFields.SatoSäilörehu]: {
    label: "fields.SatoYhteensä",
    unit: "common.kg",
  },
  [ValkuaiskasvienSatotiedotFields.KorjuualaSäilörehu]: {
    label: "fields.Korjuuala",
    unit: "common.ha",
  },
  [ValkuaiskasvienSatotiedotFields.KorjuualaÖljykasvi]: {
    label: "fields.Korjuuala",
    unit: "common.ha",
  },
  [ValkuaiskasvienSatotiedotFields.SatomääräÖljykasvi]: {
    label: "fields.SatoYhteensä",
    unit: "common.kg",
  },
  [ValkuaiskasvienSatotiedotFields.SatotasoÖljykasvi]: {
    label: "fields.Satotaso",
    unit: "common.kg-ha",
  },
  [SyyskylvotFields.Kylvöala]: {
    label: "fields.Kylvoala",
    unit: "common.ha",
  },
  [SyyskylvotFields.Suorakylvöala]: {
    label: "fields.Suorakylvoala",
    unit: "common.ha",
  },
  [RehunurmenSatotiedotFields.Kuivaheinä]: {
    label: "fields.Kuivaheinä",
    unit: "common.ha",
  },
  [RehunurmenSatotiedotFields.Niittorehu]: {
    label: "fields.Niittorehu",
    unit: "common.ha",
  },
  [RehunurmenSatotiedotFields.Säilörehu]: {
    label: "fields.Säilörehu",
    unit: "common.ha",
  },
  [RehunurmenSatotiedotFields.SäilörehuEsikuivattu]: {
    label: "fields.SäilörehuEsikuivattu",
    unit: "common.ha",
  },
  [RehunurmenSatotiedotFields.NiittorehuKuivaaineProsentti]: {
    label: "fields.KuivaaineProsentti",
    unit: "%",
  },
  [RehunurmenSatotiedotFields.KuivaheinäYhteensäKg]: {
    label: "fields.Kuivaheinä",
    unit: "common.kg",
  },
  [RehunurmenSatotiedotFields.SäilörehuYhteensäKg]: {
    label: "fields.Säilörehu",
    unit: "common.kg",
  },
  [RehunurmenSatotiedotFields.SäilörehuEsikuivattuYhteensäKg]: {
    label: "fields.SäilörehuEsikuivattu",
    unit: "common.kg",
  },
  [RehunurmenSatotiedotFields.NiittorehuYhteensäKg]: {
    label: "fields.Niittorehu",
    unit: "common.kg",
  },
  [RehunurmenSatotiedotFields.SäilörehuKuivaaineProsentti]: {
    label: "fields.KuivaaineProsentti",
    unit: "%",
  },
  [RehunurmenSatotiedotFields.SäilörehuEsikuivattuKuivaaineProsentti]: {
    label: "fields.KuivaaineProsentti",
    unit: "%",
  },
  [RehunurmenSatotiedotFields.Korjuuala]: {
    label: "fields.RehunurmialaYhteensä",
    unit: "common.ha",
  },
  [RehunurmenSatotiedotFields.Korjattuala]: {
    label: "fields.Korjattuala",
    unit: "common.ha",
  },
  [RehunurmenSatotiedotFields.Pystyynosto]: {
    label: "fields.Pystyynosto",
    unit: "common.ha",
  },
  [RehunurmenSatotiedotFields.Pystyynmyynti]: {
    label: "fields.Pystyynmyynti",
    unit: "common.ha",
  },
  [RehunurmenSatotiedotFields.Katoala]: {
    label: "fields.Katoala",
    unit: "common.ha",
  },
  [RehunurmenSatotiedotFields.SatotasoKuivaheinaYhteensa]: {
    label: "fields.satotaso",
    unit: "common.kg-ha",
  },
  [RehunurmenSatotiedotFields.SatotasoSailorehuYhteensa]: {
    label: "fields.satotaso",
    unit: "common.kg-ha",
  },
  [RehunurmenSatotiedotFields.SatotasoSailorehuEsikuivattuYhteensa]: {
    label: "fields.satotaso",
    unit: "common.kg-ha",
  },
  [RehunurmenSatotiedotFields.SatotasoNiittorehuYhteensa]: {
    label: "fields.satotaso",
    unit: "common.kg-ha",
  },
  [RehunurmenSatotiedotFields.KuivaheinaKorjuuAlaKaikkiKorjuukerratYhteensa]: {
    label: "fields.KorjattuAlaYhteensä",
    unit: "common.ha",
  },
  [RehunurmenSatotiedotFields.SailorehuKorjuuAlaKaikkiKorjuukerratYhteensa]: {
    label: "fields.KorjattuAlaYhteensä",
    unit: "common.ha",
  },
  [RehunurmenSatotiedotFields.SailorehuEsikuivattuKorjuuAlaKaikkiKorjuukerratYhteensa]: {
    label: "fields.KorjattuAlaYhteensä",
    unit: "common.ha",
  },
  [RehunurmenSatotiedotFields.NiittorehuKorjuuAlaKaikkiKorjuukerratYhteensa]: {
    label: "fields.KorjattuAlaYhteensä",
    unit: "common.ha",
  },

  [ViljanSatotiedotFields.SatotasoTuoresäilövilja]: {
    label: "fields.Satotaso",
    unit: "common.kg-ha",
  },
  [ViljanSatotiedotFields.SatotasoKokoviljasäilörehu]: {
    label: "fields.Satotaso",
    unit: "common.kg-ha",
  },
  [ViljanSatotiedotFields.KosteusTuoresäilövilja]: {
    label: "fields.Kosteusprosentti",
    unit: "%",
  },
  [ViljanSatotiedotFields.KorjuualaTuoresäilövilja]: {
    label: "fields.Korjuuala",
    unit: "common.ha",
  },
  [ViljanSatotiedotFields.SatomääräTuoresäilövilja]: {
    label: "fields.SatoYhteensä",
    unit: "common.kg",
  },
  [ViljanSatotiedotFields.KuivaaineKokoviljasäilörehu]: {
    label: "fields.KuivaaineProsentti",
    unit: "%",
  },
  [ViljanSatotiedotFields.SatomääräKokoviljasäilörehu]: {
    label: "fields.SatoYhteensä",
    unit: "common.kg",
  },
  [ViljanSatotiedotFields.KorjuualaKokoviljasäilörehu]: {
    label: "fields.Korjuuala",
    unit: "common.ha",
  },
  [ViljanSatotiedotFields.KorjuualaMallasohra]: {
    label: "fields.Korjuuala",
    unit: "common.ha",
  },
  [ViljanSatotiedotFields.SatomääräMallasohra]: {
    label: "fields.SadonKokonaismäärä",
    unit: "common.kg",
  },
  [PerunanSatotiedotFields.Sadostamyyty1510]: {
    label: "fields.Sadostamyyty1510",
    unit: "common.kg",
  },
  [PerunanSatotiedotFields.Varastossa1510]: {
    label: "fields.Varastossa1510",
    unit: "common.kg",
  },
};
