import { FormControl, FormHelperText, Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  HehtaariField,
  KgField,
  ProsenttiField,
  SatotasoField,
} from "../../../common/Fields";
import { useSatotasoCalculator } from "../../../common/useSatotasoCalculator";
import { CropFieldContext } from "./CropContext";
import { Fields } from "./viljanSatotiedot.schema";

export default function Vihantavilja() {
  const { t } = useTranslation();
  const { field, index } = useContext(CropFieldContext);
  const methods = useFormContext();
  const [showRequiredError, setRequiredError] = useState(false);

  const viljelyala = methods.watch(
    `ViljanSatotiedot.${index}.${[Fields.Viljelyala]}`
  );
  useEffect(() => {
    if (viljelyala === 0) {
      methods.setValue(
        `ViljanSatotiedot.${index}.${[Fields.KorjuualaVihantavilja]}`,
        null
      );
      methods.setValue(
        `ViljanSatotiedot.${index}.${[Fields.SatomääräVihantavilja]}`,
        null
      );
      methods.setValue(
        `ViljanSatotiedot.${index}.${[Fields.KuivaaineVihantavilja]}`,
        null
      );
    }
  }, [viljelyala]);

  const korjuuala = methods.watch(
    `ViljanSatotiedot.${index}.${[Fields.KorjuualaVihantavilja]}`
  );
  useEffect(() => {
    if (korjuuala === 0) {
      methods.setValue(
        `ViljanSatotiedot.${index}.${[Fields.SatomääräVihantavilja]}`,
        null
      );
      methods.setValue(
        `ViljanSatotiedot.${index}.${[Fields.KuivaaineVihantavilja]}`,
        null
      );
    }
  }, [korjuuala]);

  const { handleChangeAcres, handleChangeKilograms } = useSatotasoCalculator(
    `ViljanSatotiedot.${index}.${Fields.SatomääräVihantavilja}`,
    `ViljanSatotiedot.${index}.${Fields.KorjuualaVihantavilja}`,
    `ViljanSatotiedot.${index}.${Fields.SatotasoVihantavilja}`
  );

  useEffect(() => {
    setRequiredError(
      methods.formState.errors.ViljanSatotiedot &&
        methods.formState.errors.ViljanSatotiedot[index] &&
        methods.formState.errors.ViljanSatotiedot[index][
          Fields.KorjuualaVihantavilja
        ]
    );
  }, [methods.formState]);

  return (
    <>
      {field.Kasvilaji === "_386583" && (
        <Grid item md={6} xs={12}>
          {showRequiredError && (
            <FormControl error>
              <FormHelperText>
                {t("fields.errors.muuviljarequired")}
              </FormHelperText>
            </FormControl>
          )}
          <Controller
            name={`ViljanSatotiedot.${index}.${Fields.KorjuualaVihantavilja}`}
            control={methods.control}
            render={({ field: { ref, ...field } }) => (
              <HehtaariField
                tooltip={t(
                  "ViljanSatotiedot.ohjeteksti.TuoreenaKorjattavaViljaOhje"
                )}
                inputRef={ref}
                {...field}
                label={"VihantaviljaKorjuuala"}
                fullWidth
                onChange={(e) => {
                  field.onChange(e);
                  handleChangeAcres(e);
                }}
                disabled={viljelyala === 0}
              />
            )}
          />

          <Controller
            name={`ViljanSatotiedot.${index}.${Fields.SatomääräVihantavilja}`}
            control={methods.control}
            render={({ field: { ref, ...field } }) => (
              <KgField
                inputRef={ref}
                {...field}
                label={"VihantaviljaKokonaismäärä"}
                fullWidth
                onChange={(e) => {
                  field.onChange(e);
                  handleChangeKilograms(e);
                }}
                disabled={korjuuala === 0 || viljelyala === 0}
              />
            )}
          />

          <Controller
            name={`ViljanSatotiedot.${index}.${Fields.SatotasoVihantavilja}`}
            control={methods.control}
            render={({ field: { ref, ...field } }) => (
              <SatotasoField
                inputRef={ref}
                {...field}
                label={"satotaso"}
                fullWidth
                disabled={korjuuala === 0 || viljelyala === 0}
              />
            )}
          />

          <Controller
            name={`ViljanSatotiedot.${index}.${Fields.KuivaaineVihantavilja}`}
            control={methods.control}
            render={({ field: { ref, ...field } }) => (
              <ProsenttiField
                inputRef={ref}
                {...field}
                label={"KuivaaineProsentti"}
                fullWidth
                disabled={korjuuala === 0 || viljelyala === 0}
              />
            )}
          />
        </Grid>
      )}
    </>
  );
}
