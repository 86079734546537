import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import RootState from "../../../../store/types";
import { Summary, SummaryData } from "../../../common/Summary";

export default function YhteenvetoMuutViljelykasvit() {
  const { t } = useTranslation();
  const values = useSelector(
    ({ form }: RootState) => form.MuutViljelykasvitSatotiedot as any
  );

  if (!values || !values.length) return null;

  return (
    <Summary
      title={t("muutViljelyKasvit.title")}
      data={values as SummaryData}
    />
  );
}
