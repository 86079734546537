import { Box, Fade as MuiFade } from "@mui/material";
import React, { useEffect, useState } from "react";
export default function Fade({ children }: React.PropsWithChildren<any>) {
  const [fade, setFade] = useState(false);

  useEffect(() => {
    setTimeout(() => setFade(true), 200);
  }, []);

  return (
    <MuiFade in={fade}>
      <Box>{children}</Box>
    </MuiFade>
  );
}
