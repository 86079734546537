import React, { useContext } from "react";
import FormSectionTitle from "../../../common/FormSectionTitle";
import Kasvilaji from "../../../common/Kasvilaji";
import { CropFieldContext } from "./CropContext";

export default function CropTitle() {
  const { field } = useContext(CropFieldContext);

  return (
    <FormSectionTitle>
      <Kasvilaji id={field.Kasvilaji} />
    </FormSectionTitle>
  );
}
