import { sum } from "lodash";
import {
  defaultNumber,
  extendedYup as yup,
  nullableNumber,
  testSatotasoMax,
} from "../../../common/FormUtils";

type TMuuKasvilaji = "_387247" | "_387251" | "_387255" | "_387259";

export type MuutViljelykasvitForm = {
  MuutViljelykasvitSatotiedot: MuutViljelyKasvit<TMuuKasvilaji>[];
};

export enum Fields {
  Kasvilaji = "Kasvilaji",
  Viljelyala = "_610920941",
  Korjuuala = "_610920919",
  Satomäärä = "_566014545",
  Satotaso = "_Satotaso",
}

export class MuutViljelyKasvit<TKasvilaji> {
  constructor(kasvilaji: TKasvilaji, viljelyala?: number) {
    this[Fields.Kasvilaji] = kasvilaji;
    if (viljelyala) {
      this[Fields.Viljelyala] = viljelyala;
    }
  }
  [Fields.Kasvilaji]: TKasvilaji;
  [Fields.Viljelyala]: number | null = null;
  [Fields.Korjuuala]: number | null = null;
  [Fields.Satomäärä]: number | null = null;
  [Fields.Satotaso]: number | null = null;
}

export default yup.object().shape({
  MuutViljelykasvitSatotiedot: yup.array().of(
    yup.object().shape({
      [Fields.Kasvilaji]: yup.string().required(),
      [Fields.Viljelyala]: defaultNumber.notNull(
        0,
        "fields.errors.viljelyalaRequired"
      ),
      [Fields.Korjuuala]: nullableNumber
        .requiredWith(Fields.Viljelyala, "fields.errors.korjuualaRequired")
        .test(
          "harvestedAreaMax",
          "fields.errors.yliViljelyalan",
          function (item) {
            if (!item) return true;
            return this.parent[Fields.Viljelyala] >= sum([item]);
          }
        ),
      [Fields.Satomäärä]: nullableNumber.requiredWith(
        Fields.Korjuuala,
        "fields.errors.sadonKokonaismääräRequired"
      ),
      [Fields.Satotaso]: nullableNumber.test(
        ...testSatotasoMax<TMuuKasvilaji>(
          {
            _387247: 4000,
            _387251: 4000,
            _387255: 70000,
            _387259: 3000,
          },
          {
            kasvilaji: Fields.Kasvilaji,
          }
        )
      ),
    })
  ),
});
