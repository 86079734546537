import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import RootState from "../../../../store/types";
import { Summary, SummaryData } from "../../../common/Summary";

export default function YhteenvetoSyyskylvot() {
  const { t } = useTranslation();
  const values = useSelector(({ form }: RootState) => form.Syyskylvot as any);

  if (!values || !values.length) return null;

  return <Summary title={t("syyskylvot.title")} data={values as SummaryData} />;
}
