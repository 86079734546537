import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "../Footer";
import Header from "../Header";
import KyselyOtsake from "../KyselyOtsake";
import Virhe from "../Virhe";

const useStyles = makeStyles((theme) => ({
  sivu: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    minHeight: "100vh",
  },
  sisalto: {
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
}));

export default function SivuaEiLoytynyt() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <KyselyOtsake title={t("common.app")} />
      <div className={classes.sisalto}>
        <Virhe
          virhe={t("virhe.404.sivuaEiLoytynyt.virhe")}
          Ohje={() => (
            <>
              {t("virhe.404.sivuaEiLoytynyt.ohje.alku")}{" "}
              <a href="/">{t("virhe.404.sivuaEiLoytynyt.ohje.linkki")}</a>.
            </>
          )}
        />
      </div>
      <Footer />
    </>
  );
}
