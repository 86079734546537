import React, { useCallback } from "react";
import useBlocker from "./useBlocker";
import useConfirm from "./useConfirm";

export type PromptProps = {
  when: boolean;
  children: (data: {
    isActive: boolean;
    onCancel: (value: unknown) => void;
    onConfirm: (value: unknown) => void;
  }) => React.ReactNode;
};

const Prompt: React.FC<PromptProps> = ({ when, children }) => {
  const {
    isActive,
    proceed,
    cancel,
    onConfirm,
    hasConfirmed,
    resetConfirmation,
  } = useConfirm();

  const blocker = useCallback(
    async (tx) => {
      if (await onConfirm()) {
        resetConfirmation();
        tx.retry();
      }
    },
    [resetConfirmation, onConfirm]
  );

  useBlocker(blocker, when && !hasConfirmed);

  return (
    <div>
      {children({
        isActive,
        onConfirm: proceed,
        onCancel: cancel,
      })}
    </div>
  );
};

export default Prompt;
