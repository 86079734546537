import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import "array-flat-polyfill";
import React from "react";
import ReactDOM, { render } from "react-dom";
import App from "./App";
import "./i18n";
import "./index.css";
import { isDevelopment } from "./utils";

if (isDevelopment) {
  const axe = require("@axe-core/react");
  if (typeof axe == "function") {
    void axe(React, ReactDOM, 3000);
  }
}

if (process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY) {
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY,
    },
  });
  appInsights.loadAppInsights();
  appInsights.trackPageView();
}

render(<App />, document.getElementById("root"));
