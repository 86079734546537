import { matchPath } from "react-router-dom";
import { AppPath, AppPathWithInquiry, Inquiry } from "./paths";

/**
 * Form an inquiry sub path using the current location.pathname
 * @example when on a sato form page
 * formInquiryPath('yhteenveto') // /sato/yhteenveto
 */
export const formInquiryPath = (path: AppPath): AppPathWithInquiry => {
  const match = matchPath("/:inquiry/*", window.location.pathname);
  const inquiry = match?.params.inquiry as Inquiry;
  return `/${inquiry}/${path}`;
};

export const isDevelopment = process.env.NODE_ENV === "development"
