import { sum } from "lodash";
import { useSelector } from "react-redux";
import * as yup from "yup";
import RootState from "../../../../store/types";
import { nullableNumber } from "../../../common/FormUtils";

export type SyyskylvötForm = {
  Syyskylvot: Syyskylvo[];
};

export enum Fields {
  Kasvilaji = "Kasvilaji",
  Kylvöala = "_6109386939",
  Suorakylvöala = "_6109386943",
}

export class Syyskylvo {
  constructor(
    Kasvilaji: string,
    kylvöala?: number | null,
    suorakylvöala?: number | null
  ) {
    this[Fields.Kasvilaji] = Kasvilaji;

    if (kylvöala) this[Fields.Kylvöala] = kylvöala;

    if (suorakylvöala) this[Fields.Suorakylvöala] = suorakylvöala;
  }
  [Fields.Kasvilaji]: string;
  [Fields.Kylvöala]: number | null = null;
  [Fields.Suorakylvöala]: number | null = null;
}

export default yup.object().shape({
  Syyskylvot: yup.array().of(
    yup.object().shape({
      [Fields.Kasvilaji]: yup.string().required(),
      [Fields.Kylvöala]: nullableNumber,
      [Fields.Suorakylvöala]: nullableNumber.test(
        "harvestedAreaMax",
        "fields.errors.yliKylvöalan",
        function (item) {
          if (!item) return true;

          return this.parent[Fields.Kylvöala] >= sum([item]);
        }
      ),
    })
  ),
});

export function useSyyskylvoValues(): Syyskylvo[] {
  const vals = useSelector((state: RootState) => state.form.Syyskylvot);

  const syyskylvoViljat = useSelector(
    (state: RootState) => state.meta.viljakasvit
  ).filter((value) => value.syyskylvo);

  const syyskylvoValkuaiskasvit = useSelector(
    (state: RootState) => state.meta.valkuaiskasvit
  ).filter((value) => value.syyskylvo);

  return syyskylvoViljat.concat(syyskylvoValkuaiskasvit).map((row) => {
    const match = (vals || []).find((v) => v.Kasvilaji === row.id);

    if (match) {
      return new Syyskylvo(
        row.id,
        match[Fields.Kylvöala],
        match[Fields.Suorakylvöala]
      );
    }

    return new Syyskylvo(row.id);
  });
}
