import axios from "axios";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { isDevelopment } from "../../utils";
import { setError } from "../error/actions";
import RootState from "../types";
import { FormState, SetValuesAction } from "./types";

const backend = {
  UpdateData: `${process.env.REACT_APP_DATA_FUNCTION_URL}/UpdateData`,
  GetData: `${process.env.REACT_APP_DATA_FUNCTION_URL}/GetData`,
};

const getPeriod = (state: RootState) => state.form.periodId;

const updateData = async (values: any): Promise<Partial<FormState>> =>
  await axios.post(backend.UpdateData, values);

function* updateApi(action: SetValuesAction): Generator<any> {
  try {
    let periodId = yield select(getPeriod);

    yield call(updateData, { ...action.payload, periodId });
  } catch (e) {
    console.warn(e);
    const additionalInformation = isDevelopment
      ? (e as Error).toString()
      : undefined;
    yield put(setError("virhe.palvelin.tallennus", additionalInformation));
  }
}

function* apiSaga() {
  yield takeEvery("SET_VALUES", updateApi);
}

export default apiSaga;
