import { Box, Collapse, Fade, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1.5rem",
  },
  box: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: "209px",
    boxSizing: "border-box",
    textShadow:
      "0.01em 0 rgba(0, 0, 0, 0.2),0 0.01em rgba(0, 0, 0, 0.2),-0.01em 0 rgba(0, 0, 0, 0.2),0 -0.01em rgba(0, 0, 0, 0.2)",
  },
  shrinkBox: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: "100px",
    display: "flex",
    alignItems: "center",
  },
}));

export default function ShrinkingTitle({
  title,
  variant,
}: {
  title: string;
  variant?: Variant;
}) {
  const [fade, setFade] = useState(false);
  const [animate, setAnimate] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setAnimate(false);
  }, []);

  const onCollapsed = () => {
    setCollapsed(true);
    setFade(true);
  };

  return (
    <>
      {!collapsed && (
        <Collapse in={animate} collapsedSize={60} onExited={onCollapsed}>
          <Box className={classes.box} pr={8} pl={8} pt={8} pb={8}></Box>
        </Collapse>
      )}
      {collapsed && (
        <Box className={classes.shrinkBox} pr={8} pl={8}>
          <Fade in={fade} timeout={200}>
            <Typography className={classes.title} variant="h1">
              {title}
            </Typography>
          </Fade>
        </Box>
      )}
    </>
  );
}
