import jwt from "jsonwebtoken";
import useTokenState from "./useTokenState";

export type RespondentMeta = { key: string; value: any };

type TokenUserInfo = {
  gn: string; // User given name
  n: string; // User full name
  c: string; // Company name
  upn?: string;
  i: {
    tilatunnus: string[];
  };
};

export type TokenUserInfoPayload = {
  u: TokenUserInfo;
  m: RespondentMeta[];
};

export default function useTokenUserInfo(): TokenUserInfo | null {
  const token = useTokenState();

  if (token) {
    try {
      const { u } = jwt.decode(token) as TokenUserInfoPayload;

      return u;
    } catch (e) {
      console.error(e);
    }
  }

  return null;
}
