import axios from "axios";
import { useAsync } from "react-async-hook";

export default function useFinishForm(id: string | null) {
  const endpoint =
    process.env.REACT_APP_DATA_FUNCTION_URL + "/MarkFinished" ||
    "localhost:7071/api/MarkFinished";

  const finish = useAsync(
    async () => {
      try {
        await axios.post(endpoint, { id });
        return true;
      } catch (e) {
        return true;
      }
    },
    [],
    { executeOnMount: false }
  );

  return {
    finish,
    loading: finish.loading || finish.loading,
  };
}
