import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useLogout from "./useLogout";
import useTokenUserInfo from "./useTokenUserInfo";

const useStyles = makeStyles((theme) => ({
  desktopIcon: {
    [theme.breakpoints.down("md")]: {
      display: "none !important",
    },
  },
  mobileIcon: {
    [theme.breakpoints.down("md")]: {
      display: "flex !important",
    },
    [theme.breakpoints.up("md")]: {
      display: "none !important",
    },
  },
}));

interface ILanguagePickerButtonProps {
  language: "fi" | "sv";
  /** Additional code to be executed on onclick event. */
  onClick: () => void;
  label: string;
}

/**
 * Compare two language codes, and see if they are the same.
 *
 * We set language to be either 'fi' and 'sv', but it can be something else as well.
 * This is because the language comes from react-i18n's languageDetector,
 * and the detector might find 'fi-FI' or something else from user's browser.
 *
 * In addition, react-i18n expects all languages to be given in the format
 * defined by BCP 47 (eg. "fi-FI").
 *
 * Because of this, we will consider languages to be same if their
 * language part is same - the region part of the code can be different.
 *
 * @param expectedLanguage - language code of the button - either "fi" or "sv".
 * @param currentLanguage - language code from `i18n.language`.
 */
const isSameLanguage = (expectedLanguage: string, currentLanguage: string) => {
  return (
    currentLanguage === expectedLanguage ||
    new RegExp(`^${expectedLanguage}-`).test(currentLanguage)
  );
};

/**
 * Language picker button. Allows changing language.
 *
 * Button is not shown, if its language is already in use.
 */
const LanguagePickerButton = ({
  language,
  onClick,
  label,
}: ILanguagePickerButtonProps) => {
  const { t, i18n } = useTranslation();

  if (isSameLanguage(language, i18n.language)) return null;

  const handleLanguageChange = (language: string) => {
    onClick();
    setTimeout(() => {
      i18n.changeLanguage(language);
    }, 100);
  };

  return (
    <MenuItem onClick={() => handleLanguageChange(language)}>{label}</MenuItem>
  );
};

export default function UserMenu() {
  const user = useTokenUserInfo();
  const navigate = useNavigate();
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleLogout = useLogout();

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => setAnchorEl(null);

  const handleLanguageChange = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        className={classes.desktopIcon}
        aria-label="Avaa käyttäjävalikko"
        onClick={handleMenuClick}
      >
        <ExpandMoreIcon />
      </IconButton>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        className={classes.mobileIcon}
        aria-label="Avaa käyttäjävalikko"
        onClick={handleMenuClick}
      >
        <MoreIcon />
      </IconButton>
      <Menu
        open={open}
        onClose={handleMenuClose}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <LanguagePickerButton
          language="fi"
          label={t("common.fi")}
          onClick={handleLanguageChange}
        />
        <LanguagePickerButton
          language="sv"
          label={t("common.se")}
          onClick={handleLanguageChange}
        />

        <MenuItem onClick={handleLogout}>{t("common.logout")}</MenuItem>
      </Menu>
    </>
  );
}
