import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  HehtaariField,
  KgField,
  ProsenttiField,
  SatotasoField,
} from "../../../common/Fields";
import SectionSubtitle from "../../../common/SectionSubtitle";
import { useSatotasoCalculator } from "../../../common/useSatotasoCalculator";
import { Fields } from "./valkuaiskasvienSatotiedot.schema";

export default function SailoRehu({ field, index }: any) {
  const { t } = useTranslation();

  const methods = useFormContext();

  const viljelyAla = methods.watch(
    `ValkuaiskasvienSatotiedot.${index}.${Fields.Viljelyala}`
  );
  const korjuuala = methods.watch(
    `ValkuaiskasvienSatotiedot.${index}.${Fields.KorjuualaSäilörehu}`
  );
  useEffect(() => {
    if (korjuuala === 0) {
      methods.setValue(
        `ValkuaiskasvienSatotiedot.${index}.${[Fields.SatoSäilörehu]}`,
        null
      );
      methods.setValue(
        `ValkuaiskasvienSatotiedot.${index}.${[Fields.SatotasoTuore]}`,
        null
      );
      methods.setValue(
        `ValkuaiskasvienSatotiedot.${index}.${[Fields.KuivaaineSäilörehu]}`,
        null
      );
    }
  }, [korjuuala]);

  useEffect(() => {
    if (viljelyAla === 0) {
      methods.setValue(
        `ValkuaiskasvienSatotiedot.${index}.${Fields.KorjuualaSäilörehu}`,
        null
      );
      methods.setValue(
        `ValkuaiskasvienSatotiedot.${index}.${Fields.SatoSäilörehu}`,
        null
      );
      methods.setValue(
        `ValkuaiskasvienSatotiedot.${index}.${Fields.SatotasoTuore}`,
        null
      );
      methods.setValue(
        `ValkuaiskasvienSatotiedot.${index}.${Fields.KuivaaineSäilörehu}`,
        null
      );
    }
  }, [viljelyAla]);

  useEffect(() => {
    if (korjuuala === 0) {
      methods.clearErrors([
        `ValkuaiskasvienSatotiedot.${index}.${Fields.Korjuuala}`,
      ]);
    } else
      methods.clearErrors([
        `ValkuaiskasvienSatotiedot.${index}.${Fields.SatoSäilörehu}`,
        `ValkuaiskasvienSatotiedot.${index}.${Fields.KuivaaineSäilörehu}`,
      ]);
  }, [korjuuala]);

  const { handleChangeAcres, handleChangeKilograms } = useSatotasoCalculator(
    `ValkuaiskasvienSatotiedot.${index}.${Fields.SatoSäilörehu}`,
    `ValkuaiskasvienSatotiedot.${index}.${Fields.KorjuualaSäilörehu}`,
    `ValkuaiskasvienSatotiedot.${index}.${Fields.SatotasoTuore}`
  );

  if (viljelyAla === 0) return null;

  if (field.Kasvilaji !== "_386579" && field.Kasvilaji !== "_61223") {
    return null;
  }

  return (
    <>
      <Grid item md={6} xs={12}>
        <SectionSubtitle>
          {t("ValkuaiskasvienSatotiedot.Säilörehu")}
        </SectionSubtitle>

        <Controller
          name={`ValkuaiskasvienSatotiedot.${index}.${Fields.KorjuualaSäilörehu}`}
          control={methods.control}
          render={({ field: { ref, ...field } }) => (
            <HehtaariField
              disabled={viljelyAla === 0}
              inputRef={ref}
              {...field}
              label={"SäilörehunKorjuuala"}
              fullWidth
              onChange={(e) => {
                field.onChange(e);
                handleChangeAcres(e);
              }}
            />
          )}
        />

        <Controller
          name={`ValkuaiskasvienSatotiedot.${index}.${Fields.SatoSäilörehu}`}
          control={methods.control}
          render={({ field: { ref, ...field } }) => (
            <KgField
              inputRef={ref}
              {...field}
              label={"TuoresadonKokonaismäärä"}
              fullWidth
              onChange={(e) => {
                field.onChange(e);
                handleChangeKilograms(e);
              }}
              disabled={korjuuala === 0 || viljelyAla === 0}
            />
          )}
        />

        <Controller
          name={`ValkuaiskasvienSatotiedot.${index}.${Fields.SatotasoTuore}`}
          control={methods.control}
          render={({ field: { ref, ...field } }) => (
            <SatotasoField
              inputRef={ref}
              {...field}
              label={"TuoresadonSatotaso"}
              fullWidth
              disabled={korjuuala === 0 || viljelyAla === 0}
            />
          )}
        />

        <Controller
          name={`ValkuaiskasvienSatotiedot.${index}.${Fields.KuivaaineSäilörehu}`}
          control={methods.control}
          render={({ field: { ref, ...field } }) => (
            <ProsenttiField
              inputRef={ref}
              {...field}
              label={"KuivaaineProsentti"}
              fullWidth
              disabled={korjuuala === 0}
            />
          )}
        />
      </Grid>
    </>
  );
}
