import { Grid } from "@mui/material";
import React from "react";

export default function SelectedHarvestMethods({
  children,
}: React.PropsWithChildren<any>) {
  return (
    <Grid container item xs={12} spacing={2}>
      {children}
    </Grid>
  );
}
