import React from "react";
import NumberFormat, { NumberFormatValues } from "react-number-format";

/**
 * Pick correct value, from incoming values.
 *
 * If value is a number, we can use `values.floatValue`.
 *
 * But if the `floatValue` is `undefined`, because it was e.g. cleared,
 * we need to pass empty string from `values.formattedValue`,
 * so that field stays controlled, and `react-hook-form` doesn't pass it default value.
 *
 * @see https://github.com/s-yadav/react-number-format/issues/528#issuecomment-825787850
 * @param values
 */
const pickCorrectValue = (values: NumberFormatValues) =>
  values.floatValue !== undefined ? values.floatValue : values.formattedValue;

// eslint-disable-next-line react/display-name
export default React.forwardRef((props: any, ref): JSX.Element => {
  const { onChange, ...rest } = props;

  return (
    <NumberFormat
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: pickCorrectValue(values),
          },
        });
      }}
      maxLength={props.maxLength || 8} // Default maxlength to 8
      decimalScale={props.decimalScale || 0} // Default scale to 0
      decimalSeparator={","} // Default decimal separator to ,
      thousandSeparator={" "} // Default thousand separator to " "
      {...rest}
    />
  );
});
