import { Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { Header } from "../common";
import { NextButton } from "../common/Buttons";
import Footer from "../common/Footer";
import useLogin from "../common/useLogin";
import useTokenState from "../common/useTokenState";

const useStyles = makeStyles((theme) => ({
  title: {
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(4),
      minHeight: "100%",
    },
    padding: theme.spacing(8),
    backgroundColor: theme.palette.primary.main,
    color: "white",
    textShadow:
      // eslint-disable-next-line max-len
      "0.01em 0 rgba(0, 0, 0, 0.2),0 0.01em rgba(0, 0, 0, 0.2),-0.01em 0 rgba(0, 0, 0, 0.2),0 -0.01em rgba(0, 0, 0, 0.2)",
  },
  lukeLink: {
    color: "#fff",
  },
  authenticate: {
    display: "flex",
    alignItems: "flex-end",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "column",
  },
  flexContent: {
    flex: "auto",
  },
  instructionsBox: {
    paddingTop: "10px",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.75rem !important",
    },
  },
  error: {
    "&.Mui-error": {
      color: "white !important",
    },
  },
  button: {
    marginTop: "12px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

export default function Kirjaudu() {
  const login = useLogin();
  const auth = useTokenState();
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const handleLoginClick = () => {
    localStorage.clear();
    login();
  };

  const handleValtuutettuLoginClick = () => {
    localStorage.clear();

    window.location.href = (process.env.REACT_APP_LOGIN_URL_VALTUUTETUT +
      `&lang=` +
      i18n.language) as string;
  };

  if (auth) return <Navigate to="/aloitus" />;

  return (
    <>
      <Header isLoggedIn={false} />
      <Box className={classes.title}>
        <Typography variant="h1" className={classes.instructionsBox}>
          {t("kirjaudu.title")}
        </Typography>
        <Box>
          <br />
          <br />
          <Typography variant="body1">{t("kirjaudu.ohjeteksti1.1")}</Typography>
          <Typography variant="body1">{t("kirjaudu.ohjeteksti1.2")}</Typography>
          <br />
          <Typography variant="body1">{t("kirjaudu.ohjeteksti2.1")}</Typography>
          <Typography variant="body1">{t("kirjaudu.ohjeteksti2.2")}</Typography>
          <br />
          <Typography variant="body1">
            {t("kirjaudu.ohjeteksti3.1")} <br />
            <Trans
              i18nKey={"kirjaudu.ohjeteksti3.2"}
              components={{
                b: <b />,
              }}
            />
          </Typography>
          <Typography>
            {t("kirjaudu.linkkiteksti1") + " "}
            <a
              className={classes.lukeLink}
              target="_blank"
              rel="noreferrer noopener"
              href={t("kirjaudu.linkki")}
            >
              {t("kirjaudu.linkkiteksti2")}
            </a>
          </Typography>
          <br />
          <br />
        </Box>
        <Box mt={3} className={classes.authenticate}>
          <Grid container spacing={2}>
            <Grid item md={2} xs={12}>
              <NextButton
                fullWidth
                className={classes.button}
                color="secondary"
                sx={{
                  fontWeight: "bold",
                }}
                text={t("kirjaudu.suomifi")}
                onClick={handleLoginClick}
              />
            </Grid>
            {/* <Grid item lg={3} md={4} xs={12}>
              <NextButton
                fullWidth
                className={classes.button}
                color="secondary"
                sx={{
                  fontWeight: "bold",
                }}
                text={t("kirjaudu.suomifi.valtuutettu")}
                onClick={handleValtuutettuLoginClick}
              />
            </Grid> */}
          </Grid>
        </Box>
      </Box>
      <div style={{ flex: 1 }}></div>
      <Footer />
    </>
  );
}
