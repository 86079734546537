import { Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles(() => ({
  popper: {
    margin: "-30px 0 !important",
  },
}));

interface IFieldTooltip {
  title?: string;
  /**
   * open is for testing purposes
   */
  open?: boolean;
  children: React.ReactNode;
}

const FieldTooltip = ({ title, children, open }: IFieldTooltip) => {
  const classes = useStyles();

  if (!title) return <>{children}</>;

  return (
    <Tooltip
      PopperProps={{ className: classes.popper }}
      title={title}
      placement="top"
      arrow={true}
      open={open}
      describeChild
    >
      <span>{children}</span>
    </Tooltip>
  );
};

export default FieldTooltip;
