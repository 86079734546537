import { Typography } from "@mui/material";
import React from "react";

const FormSectionTitle = ({ children }: React.PropsWithChildren<any>) => (
  <Typography
    sx={{
      "&:first-letter": {
        textTransform: "capitalize",
      },
    }}
    mb={1}
    variant="h3"
  >
    {children}
  </Typography>
);

export default FormSectionTitle;
