import { Box, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CropFieldContext } from "./CropContext";
import { Fields } from "./viljanSatotiedot.schema";

export default function NoMethodsSelected() {
  const { t } = useTranslation();

  const { field, index } = useContext(CropFieldContext);
  const methods = useFormContext();

  const values = methods.watch("ViljanSatotiedot");

  /**
   * Vihantavilja and mallasohra have no harvest method selection.
   */
  if (field.Kasvilaji === "_386583" || field.Kasvilaji === "_61201") {
    return <></>;
  }

  return (
    <>
      {!values[index][Fields.KorjattuKuivana] &&
        !values[index][Fields.KorjattuKokoviljasäilörehu] &&
        !values[index][Fields.KorjattuTuoresäilörehu] && (
          <Box
            sx={{
              width: "100%",
              minHeight: "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography>
              {t("ViljanSatotiedot.eiKorjuutapaaValittu")}
            </Typography>
          </Box>
        )}
    </>
  );
}
