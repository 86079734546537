import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useContext } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { HehtaariField } from "../../../common/Fields";
import { CropFieldContext } from "./CropContext";
import { Fields } from "./viljanSatotiedot.schema";

const useStyles = makeStyles(() => ({
  field: {
    minWidth: "275px",
  },
}));
export default function CropAcres() {
  const classes = useStyles();
  const methods = useFormContext();
  const { field, index } = useContext(CropFieldContext);

  return (
    <Grid item xs={12} mb={2}>
      <Controller
        name={`ViljanSatotiedot.${index}.${Fields.Viljelyala}`}
        control={methods.control}
        render={({ field: { ref, ...field } }) => (
          <HehtaariField inputRef={ref} {...field} label={"Viljelyala"} />
        )}
      />
    </Grid>
  );
}
