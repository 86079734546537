import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useContext, useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import Checkbox from "../../../common/Checkbox";
import { CropFieldContext } from "./CropContext";
import { Fields } from "./viljanSatotiedot.schema";

const useStyles = makeStyles({
  transText: { fontSize: "1.2em" },
});

export default function CropHarvestMethods() {
  const errRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const { field, index } = useContext(CropFieldContext);

  const classes = useStyles();
  const methods = useFormContext();
  const viljelyAla = methods.watch(
    `ViljanSatotiedot.${index}.${[Fields.Viljelyala]}`
  );

  useEffect(() => {
    if (viljelyAla === 0) {
      methods.setValue(
        `ViljanSatotiedot.${index}.${Fields.KorjattuKuivana}`,
        false
      );
      methods.setValue(
        `ViljanSatotiedot.${index}.${Fields.KorjattuTuoresäilörehu}`,
        false
      );
      methods.setValue(
        `ViljanSatotiedot.${index}.${Fields.KorjattuKokoviljasäilörehu}`,
        false
      );

      methods.clearErrors(
        `ViljanSatotiedot.${index}.${Fields.KorjattuKuivana}`
      );
    }
  }, [viljelyAla]);

  /**
   * Vihantavilja and mallasohra have no harvest method selection.
   */
  if (field.Kasvilaji === "_386583" || field.Kasvilaji === "_61201") {
    return <></>;
  }

  const noMethodsSelected =
    methods.formState.errors.ViljanSatotiedot &&
    methods.formState.errors.ViljanSatotiedot[index] &&
    methods.formState.errors.ViljanSatotiedot[index][Fields.KorjattuKuivana];

  useEffect(() => {
    const errors =
      methods.formState.errors.ViljanSatotiedot &&
      methods.formState.errors.ViljanSatotiedot[index]
        ? Object.keys(methods.formState.errors.ViljanSatotiedot[index])
        : [];

    if (errors.length) {
      window.scrollTo({
        top: errRef.current?.offsetTop,
      });
    }
  }, [errRef.current, methods.formState.errors]);

  return (
    <Grid item xs={12} mb={2} ref={errRef}>
      <FormControl error={!!noMethodsSelected}>
        <FormLabel component="legend" sx={{ display: "flex" }}>
          <Box sx={{ marginRight: "8px" }}>
            {t("ViljanSatotiedot.valitseKorjuutapa")}
          </Box>
          <Tooltip
            tabIndex={0}
            title={
              <Typography className={classes.transText}>
                <Trans
                  i18nKey={"ViljanSatotiedot.ohjeteksti.ValitseKorjuutapa"}
                  components={{
                    b: <b />,
                  }}
                >
                  {t("ViljanSatotiedot.ohjeteksti.ValitseKorjuutapa")}
                </Trans>
              </Typography>
            }
          >
            <InfoIcon sx={{ color: "#0079C9" }} />
          </Tooltip>
        </FormLabel>
        <FormGroup
          row={true}
          sx={{
            width: "100%",
            alignItems: "space-between",
          }}
        >
          <Checkbox
            name={`ViljanSatotiedot.${index}.${Fields.KorjattuKuivana}`}
            label={t(`ViljanSatotiedot.KuivattavaVilja`)}
            disabled={viljelyAla === 0}
            id={`ViljanSatotiedot.${index}.${Fields.KorjattuKuivana}`}
          />

          <Checkbox
            disabled={viljelyAla === 0}
            label={t(`ViljanSatotiedot.TuoreenaKorjattavaVilja`)}
            name={`ViljanSatotiedot.${index}.${Fields.KorjattuTuoresäilörehu}`}
          />
          <Checkbox
            disabled={viljelyAla === 0}
            label={t(`ViljanSatotiedot.Kokoviljasäilörehu`)}
            name={`ViljanSatotiedot.${index}.${Fields.KorjattuKokoviljasäilörehu}`}
          />
        </FormGroup>

        {!!noMethodsSelected && (
          <FormHelperText>
            {t("fields.errors.korjuutaparequired")}
          </FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
}
