import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setFormValues } from "../../../../store/form/actions";
import { usePerunaCrops } from "../../../../store/form/selectors";
import { useTilatyyppi } from "../../../../store/meta/selectors";
import { FormActions } from "../../../common";
import DefaultActions from "../../../common/DefaultActions";
import FormInstructions from "../../../common/FormInstructions";
import FormPage from "../../../common/FormPage";
import FormPageTitle from "../../../common/FormPageTitle";
import { hasDirtyFields } from "../../../common/FormUtils";
import MobileActions from "../../../common/MobileActions";
import MobileFooter from "../../../common/MobileFooter";
import NoPresetsForPage from "../../../common/NoPresetsForPage";
import ConfirmationPrompt from "../../../common/Prompt/ConfirmationPrompt";
import useNavigateWithSearch from "../../../common/useNavigateWithSearch";
import Yhteenveto from "../../Peruna/PerunanSatotiedot/Yhteenveto";
import Perunalaji from "./Perunalaji";
import schema, { PerunanSatotiedotForm } from "./perunanSatotiedot.schema";

export default function PerunanSatotiedot() {
  const navigate = useNavigateWithSearch();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const PerunanSatotiedot = usePerunaCrops();
  const ttyyppi = useTilatyyppi();

  const methods = useForm<PerunanSatotiedotForm>({
    shouldFocusError: true,
    defaultValues: {
      PerunanSatotiedot: PerunanSatotiedot,
    },
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });

  const { fields } = useFieldArray({
    control: methods.control,
    name: "PerunanSatotiedot",
  });

  const handlePreviousClick = (values: Partial<PerunanSatotiedotForm>) => {
    navigate("/sato/muiden-viljelykasvien-satotiedot");
  };

  const handleNextClick = (values: Partial<PerunanSatotiedotForm>) => {
    dispatch(setFormValues({ ...values, step: 5 }));
    navigate("/sato/syyskylvot");
  };

  return (
    <FormProvider {...methods}>
      <FormPage>
        <FormPageTitle>5. {t("perunanSato.title")}</FormPageTitle>
        {PerunanSatotiedot?.length === 0 ? (
          <NoPresetsForPage />
        ) : (
          <>
            <FormInstructions>
              {ttyyppi === 2
                ? t("PerunanSatotiedot.ohjeteksti.tayttoohje.luomu")
                : t("PerunanSatotiedot.ohjeteksti.tayttoohje")}
            </FormInstructions>
            {fields.map((field, index) => (
              <Perunalaji key={field.Kasvilaji} field={field} index={index} />
            ))}

            <Yhteenveto fields={fields} />
          </>
        )}

        <FormActions>
          <DefaultActions
            onNext={handleNextClick}
            onPrevious={handlePreviousClick}
          />
        </FormActions>
      </FormPage>
      <MobileFooter>
        <MobileActions
          step={"5/9"}
          onNext={handleNextClick}
          onPrevious={handlePreviousClick}
        />
      </MobileFooter>
      <ConfirmationPrompt
        open={
          hasDirtyFields(methods.formState) && !methods.formState.isSubmitting
        }
      />
    </FormProvider>
  );
}
