import { applyMiddleware, compose, createStore } from "redux";
import { persistCombineReducers, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import { isDevelopment } from "../utils";
import apiEffects from "./form/effects";
import rootReducer from "./rootReducer";

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: "root",
  storage,
  debug: true,
  whitelist: ["auth", "meta", "form"],
};

const persistedReducer = persistCombineReducers(persistConfig, rootReducer);
const composeEnhancers =
  (isDevelopment && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

let store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

let persistor = persistStore(store);

sagaMiddleware.run(apiEffects);

export { store, persistor };
