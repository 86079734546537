import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles(() => ({
  instructions: {
    backgroundColor: "#E5F7CE",
    ["@media print"]: {
      border: "solid !important",
      display: "none",
    },
  },
}));

export default function FormInstructions({
  children,
}: React.PropsWithChildren<any>) {
  const classes = useStyles();

  return (
    <Box p={3} className={classes.instructions} mt={2} mb={2}>
      <Typography component="div">{children}</Typography>
    </Box>
  );
}
