import { useSelector } from "react-redux";
import { FieldsVastauskoodi } from "../../components/common/vastauskoodit.schema";
import { PerunanSatotieto } from "../../components/Forms/Sato/PerunanSatotiedot/perunanSatotiedot.schema";
import { ValkuaiskasvienSatotieto } from "../../components/Forms/Sato/ValkuaiskasvienSatotiedot/valkuaiskasvienSatotiedot.schema";
import { Varastotiedot } from "../../components/Forms/Sato/Varastotiedot/varastotiedot.schema";
import { Satotieto } from "../../components/Forms/Sato/ViljanSatotiedot/viljanSatotiedot.schema";
import { default as RootState } from "../types";

export function useSelectedCropValues(): Satotieto[] {
  return useSelector((state: RootState) => state.form.ViljanSatotiedot);
}

export function useVarastoarvot(): Varastotiedot {
  return useSelector((state: RootState) => state.form.Varastotiedot);
}

export function usePerunaCrops(): PerunanSatotieto[] {
  const perunansatotiedot = useSelector(
    (state: RootState) => state.form.PerunanSatotiedot
  ).sort((a, b) => {
    const varhaisperunaId = "_21575"
    if (a.Kasvilaji === varhaisperunaId) {
      return -1;
    }
    else if (a.Kasvilaji === b.Kasvilaji) {
      return 0;
    }
    else {
      return 1;
    }
  })
  return (
    perunansatotiedot
  )
}

export function useValkuaiskasvienSatotiedotCrops(): ValkuaiskasvienSatotieto[] {
  return useSelector(
    (state: RootState) => state.form.ValkuaiskasvienSatotiedot
  );
}

export function useVastausKoodi() {
  return useSelector((state: RootState) => state.form.vastauskoodi);
}

/**
 * Is data for inquiry available aka. can the forms be filled in.
 * If vastauskoodi is not given (user a farmer) or if vastauskoodi is [VASTAUSKOODIT.INFORMATION_AVAILABLE] then
 * the user can navigate to form pages and submit the form from the summary page.
 *
 * @TODO: this should probably be split into two checks using the user's role from the auth token and such.
 * However, this is not possble at the moment because the dev environment
 * doesn't support logging in as users with different roles.
 */
export function useIsInquiryDataAvailable() {
  const vastauskoodi = useVastausKoodi();
  return (
    vastauskoodi === undefined ||
    (vastauskoodi && !!vastauskoodi[FieldsVastauskoodi.INFORMATION_AVAILABLE])
  );
}
