import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setFormValues } from "../../../../store/form/actions";
import { useSelectedCropValues } from "../../../../store/form/selectors";
import { useTilatyyppi } from "../../../../store/meta/selectors";
import { Kasvi } from "../../../../store/meta/types";
import RootState from "../../../../store/types";
import DefaultActions from "../../../common/DefaultActions";
import FormActions from "../../../common/FormActions";
import FormInstructions from "../../../common/FormInstructions";
import FormPage from "../../../common/FormPage";
import FormPageTitle from "../../../common/FormPageTitle";
import FormSection from "../../../common/FormSection";
import { hasDirtyFields } from "../../../common/FormUtils";
import MobileActions from "../../../common/MobileActions";
import MobileFooter from "../../../common/MobileFooter";
import NoPresetsForPage from "../../../common/NoPresetsForPage";
import ConfirmationPrompt from "../../../common/Prompt/ConfirmationPrompt";
import useNavigateWithSearch from "../../../common/useNavigateWithSearch";
import CropAcres from "./CropAcres";
import CropContext from "./CropContext";
import CropHarvestMethods from "./CropHarvestMethods";
import CropTitle from "./CropTitle";
import Kokoviljasailorehu from "./Kokoviljasailorehu";
import KuivattavaVilja from "./KuivattavaVilja";
import Mallasohra from "./Mallasohra";
import NoMethodsSelected from "./NoMethodsSelected";
import SelectedHarvestMethods from "./SelectedHarvestMethods";
import TuoreenaKorjattavaVilja from "./TuoreenaKorjattavaVilja";
import Vihantavilja from "./Vihantavilja";
import schema, { ViljanSatotiedotForm } from "./viljanSatotiedot.schema";

export default function ViljanSatotiedot() {
  const navigate = useNavigateWithSearch();
  const dispatch = useDispatch();

  const viljakasvit = useSelector((state: RootState) => state.meta.viljakasvit);
  const selectedValues = useSelectedCropValues();

  const ttyyppi = useTilatyyppi();

  const { t } = useTranslation();
  const methods = useForm<ViljanSatotiedotForm>({
    shouldFocusError: true,
    defaultValues: {
      ViljanSatotiedot: selectedValues.sort((a, b) => {
        const cropA = viljakasvit.find(
          (row) => row.id === a.Kasvilaji
        ) as Kasvi;
        const cropB = viljakasvit.find(
          (row) => row.id === b.Kasvilaji
        ) as Kasvi;

        if (!cropA || !cropB) {
          return -1;
        }

        return cropA.order - cropB.order;
      }),
    },
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });
  const { fields } = useFieldArray({
    control: methods.control,
    name: "ViljanSatotiedot",
  });

  const handleNextClick = (values: Partial<ViljanSatotiedotForm>) => {
    dispatch(setFormValues({ ...values, step: 1 }));
    navigate("/sato/viljan-varastot");
  };

  return (
    <FormProvider {...methods}>
      <FormPage>
        <FormPageTitle>1. {t("ViljanSatotiedot.title")}</FormPageTitle>
        {selectedValues?.length === 0 ? (
          <NoPresetsForPage />
        ) : (
          <>
            <FormInstructions>
              {ttyyppi === 2
                ? t("ViljanSatotiedot.ohjeteksti.tayttoohje.luomu")
                : t("ViljanSatotiedot.ohjeteksti.tayttoohje")}
            </FormInstructions>
            {fields.map((field, index) => (
              <FormSection sx={{ minHeight: "400px" }} key={field.Kasvilaji}>
                <CropContext field={field} index={index}>
                  <CropTitle />
                  <CropAcres />
                  <CropHarvestMethods />
                  <SelectedHarvestMethods>
                    <NoMethodsSelected />
                    <KuivattavaVilja />
                    <TuoreenaKorjattavaVilja />
                    <Kokoviljasailorehu />
                    <Vihantavilja />
                    <Mallasohra />
                  </SelectedHarvestMethods>
                </CropContext>
              </FormSection>
            ))}
          </>
        )}

        <FormActions>
          <span />
          <DefaultActions onNext={handleNextClick} />
        </FormActions>
      </FormPage>
      <MobileFooter>
        <MobileActions step={"1/9"} onNext={handleNextClick} />
      </MobileFooter>
      <ConfirmationPrompt
        open={
          hasDirtyFields(methods.formState) && !methods.formState.isSubmitting
        }
      />
    </FormProvider>
  );
}
