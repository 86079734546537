import * as mui from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { KgField } from "../../../common/Fields";
import {
  Fields,
  IVarastotiedotVilja,
  VarastoViljat,
} from "./varastotiedot.schema";
import varastoTilannePvm from "./varastoTilannePvm.data";

export const viljat = {
  [VarastoViljat.vehnä]: "Varastotiedot.vehnä",
  [VarastoViljat.ruis]: "Varastotiedot.ruis",
  [VarastoViljat.ohra]: "Varastotiedot.ohra",
  [VarastoViljat.kaura]: "Varastotiedot.kaura",
};

const useStyles = makeStyles(() => ({
  sectionHeader: {
    "&:first-letter": {
      textTransform: "capitalize",
    },
  },
}));

/**
 * Due to historical reasons, some of the pages in sato use `kasvilaji` key,
 * while majority of the pages use the key `Kasvilaji`.
 *
 * (The difference is in the capitalization.)
 *
 * Varastotiedot is one of the pages using the key `kasvilaji`.
 *
 * There is a slight ambiguity in the code, indicating the possibility
 * that Varastotiedot might also be using `Kasvilaji` key.
 */
interface IFieldWithKasvilaji {
  Kasvilaji?: VarastoViljat;
  kasvilaji?: VarastoViljat;
}

/**
 * Get the kasvilaji value from either `kasvilaji` or `Kasvilaji` key.
 */
const getKasvilaji = (field: IFieldWithKasvilaji) => {
  const { kasvilaji, Kasvilaji } = field;

  if (kasvilaji) return kasvilaji;
  if (Kasvilaji) return Kasvilaji;

  return undefined;
};

interface ILajinNimiOtsakeProps {
  field: IFieldWithKasvilaji;
}

const LajinNimiOtsake = ({ field }: ILajinNimiOtsakeProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const laji = getKasvilaji(field);

  let otsake = "-";

  if (laji) {
    const tunnisteI18N = viljat[laji];
    otsake = t(tunnisteI18N);
  }

  return (
    <mui.Typography className={classes.sectionHeader} variant="h5">
      {otsake}
    </mui.Typography>
  );
};

const ViljakohtaisetKentät = () => {
  const methods = useFormContext();

  const { fields } = useFieldArray({
    control: methods.control,
    name: "Varastotiedot",
  });

  return (
    <>
      {fields.map((f, index) => {
        const field = f as unknown as IVarastotiedotVilja;

        return (
          <mui.Box
            key={`Varastotiedot.${index}.${Fields.Kasvilaji}`}
            component={mui.Paper}
            p={2}
            mt={2}
            mb={2}
          >
            <mui.Grid spacing={4} container item xs={12}>
              <mui.Grid item xs={12}>
                <LajinNimiOtsake field={field} />
              </mui.Grid>
            </mui.Grid>

            <mui.Grid container item spacing={4} xs={12}>
              <mui.Grid item md={6} xs={12}>
                <Controller
                  name={`Varastotiedot.${index}.${Fields.VarastoarvoHeinäkuu}`}
                  control={methods.control}
                  render={({ field: { ref, ...field } }) => (
                    <KgField
                      inputRef={ref}
                      {...field}
                      label={"VarastoarvoHeinäkuu"}
                      tProps={{ heinäkuu: varastoTilannePvm.heinäkuu }}
                      fullWidth
                    />
                  )}
                />
              </mui.Grid>

              <mui.Grid item md={6} xs={12}>
                <Controller
                  name={`Varastotiedot.${index}.${Fields.VarastoarvioTammikuu}`}
                  control={methods.control}
                  render={({ field: { ref, ...field } }) => (
                    <KgField
                      inputRef={ref}
                      {...field}
                      label={"VarastoarvoTammikuuArvio"}
                      tProps={{ tammikuu: varastoTilannePvm.tammikuu }}
                      fullWidth
                    />
                  )}
                />
              </mui.Grid>
            </mui.Grid>
          </mui.Box>
        );
      })}
    </>
  );
};

export default ViljakohtaisetKentät;
