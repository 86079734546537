import { pick } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import RootState from "../../../../store/types";
import { Summary, SummaryData } from "../../../common/Summary";
import { Fields } from "./valkuaiskasvienSatotiedot.schema";

interface ISummaryKuivattavaKasvi {
  [Fields.Korjuuala]: number | null;
  [Fields.Sato]: number | null;
  [Fields.SatotasoKuiva]: number | null;
}

interface ISummarySäilörehu {
  [Fields.KorjuualaSäilörehu]: number | null;
  [Fields.SatoSäilörehu]: number | null;
  [Fields.SatotasoTuore]: number | null;
  [Fields.KuivaaineSäilörehu]: number | null;
}

interface ISummaryKorjuutiedot {
  [Fields.SatomääräÖljykasvi]: number | null;
  [Fields.KorjuualaÖljykasvi]: number | null;
  [Fields.SatotasoÖljykasvi]: number | null;
}

interface ISummary {
  [Fields.Kasvilaji]: string;
  [Fields.Viljelyala]: number | null;
  "ValkuaiskasvienSatotiedot.KuivattavaKasvi"?: ISummaryKuivattavaKasvi;
  "ValkuaiskasvienSatotiedot.Säilörehu"?: ISummarySäilörehu;
  "ValkuaiskasvienSatotiedot.Korjuutiedot"?: ISummaryKorjuutiedot;
}

export default function YhteenvetoValkuaiskasvienSatotiedot() {
  const { t } = useTranslation();

  const values = useSelector(({ form }: RootState) =>
    form.ValkuaiskasvienSatotiedot.map((row) => {
      let summary: ISummary = pick(row, [Fields.Kasvilaji, Fields.Viljelyala]);

      if (row[Fields.Korjuuala]) {
        summary["ValkuaiskasvienSatotiedot.KuivattavaKasvi"] = pick(row, [
          Fields.Korjuuala,
          Fields.Sato,
          Fields.SatotasoKuiva,
        ]);
      }

      if (row[Fields.KorjuualaSäilörehu]) {
        summary["ValkuaiskasvienSatotiedot.Säilörehu"] = pick(row, [
          Fields.KorjuualaSäilörehu,
          Fields.SatoSäilörehu,
          Fields.SatotasoTuore,
          Fields.KuivaaineSäilörehu,
        ]);
      }

      if (row[Fields.KorjuualaÖljykasvi]) {
        summary["ValkuaiskasvienSatotiedot.Korjuutiedot"] = pick(row, [
          Fields.SatomääräÖljykasvi,
          Fields.KorjuualaÖljykasvi,
          Fields.SatotasoÖljykasvi,
        ]);
      }

      return summary;
    })
  );

  if (!values || !values.length) return null;

  // cast to `any`, as `SummaryData` type is horribly broken.
  const data = values as any as SummaryData;

  return <Summary title={t("ValkuaiskasvienSatotiedot.title")} data={data} />;
}
