import { DEFAULT_DECIMAL_PRECISION } from "@lukefi-private/sato-util-numbers/decimals";
import { convertFieldToNumber, TDecimalFromFormField } from "./common";

const rehunurmilaskuri = (
  korjuualaRaaka: TDecimalFromFormField,
  pystyynmyyntiRaaka: TDecimalFromFormField,
  katoalaRaaka: TDecimalFromFormField,
  pystyynostoRaaka: TDecimalFromFormField
) => {
  const korjuuala = convertFieldToNumber(korjuualaRaaka);
  const pystyynmyynti = convertFieldToNumber(pystyynmyyntiRaaka);
  const katoala = convertFieldToNumber(katoalaRaaka);
  const pystyynosto = convertFieldToNumber(pystyynostoRaaka);

  /**
   * Kaava:
   *
   * ```
   * korjattu ala =
   *    tukihakemuksessa ilmoitettu rehunurmiala
   *  - pystyyn myyty ala
   *  - ei korjuuta tai niitetty maahan
   *  + pystyyn ostettu
   * ```
   */
  const value = korjuuala - pystyynmyynti - katoala + pystyynosto;

  /**
   * If a value is a very small number close to zero, its due to decimal math processing in
   * javascript, and the correct value is actually 0.
   *
   * (Values can only be given with 2 decimals in the form, and there's only sum and difference
   * operations used in the calculator.)
   */
  if (-DEFAULT_DECIMAL_PRECISION < value && value < DEFAULT_DECIMAL_PRECISION) {
    return 0;
  }

  return value;
};

export { rehunurmilaskuri };
