{
  "kirjaudu.title": "Tervetuloa Luonnonvarakeskuksen tilastokyselyyn",
  "kirjaudu.ohjeteksti1.1": "Tämän sähköisen tiedonkeruunpalvelun kautta vastataan Luonnonvarakeskuksen satotilastokyselyyn ja perunan hävikki- ja varastokyselyyn.",
  "kirjaudu.ohjeteksti1.2": "Satotilastokysely on avoinna 30.9.-30.10. välisenä aikana. Perunan hävikki- ja varastokysely on avoinna 30.9.-30.10. klo 23.59 välisenä aikana.",
  "kirjaudu.ohjeteksti2.1": "Vastaajiksi kutsuttujen viljelijöiden tulee vastata tiedonkeruuseen yllä olevien aikataulujen puitteissa.",
  "kirjaudu.ohjeteksti2.2": "Olemme haastattelijoiden toimesta yhteydessä niihin viljelijöihin, joilta ei ole saatu vastausta sähköisen tiedonkeruun päättymiseen mennessä.",
  "kirjaudu.ohjeteksti3.1": "Luonnonvarakeskuksen tilastokyselyt ovat lakisääteistä viranomaistoimintaa. Antamasi tiedot käsitellään luottamuksellisina ja niitä käytetään vain tilastotarkoituksiin.",
  "kirjaudu.ohjeteksti3.2": "Tunnistaudu viljelijänä valitsemalla <b>Tunnistaudu</b>. Jos olet viljelijän valtuuttama henkilö, valitse <b>Tunnistaudu valtuutettuna.</b>",
  "kirjaudu.linkkiteksti1": "Ohjeita tunnistautumiseen ja valtuuttamiseen löydät Luken verkkosivuilta kohdasta",
  "kirjaudu.linkkiteksti2": "Suomi.fi-tunnistus ja -valtuudet viljelijätiedonkeruissa",
  "kirjaudu.linkki": "https://www.luke.fi/fi/tilastot/tietoa-tilastoista/suomifitunnistus-ja-valtuudet-viljelijatiedonkeruissa",
  "kirjaudu.suomifi": "Tunnistaudu",
  "kirjaudu.suomifi.valtuutettu": "Tunnistaudu valtuutettuna",
  "kirjaudu.tunnistautuminenEpaonnistui.title": "Tunnistautuminen sovellukseen keskeytyi.",
  "kirjaudu.tunnistautuminenEpaonnistui.return": "Palaa etusivulle",
  "Accessibility.title": "Saavutettavuusseloste",
  "Accessibility.tervetuloa": "Tervetuloa, tämä on organisaation Luonnonvarakeskus saavutettavuusseloste",
  "Accessibility.lakiperuste": "Tämä saavutettavuusseloste koskee palvelua <a>https://sato.luke.fi</a> ja on laadittu / päivitetty 21.09.2022. Palvelua koskee laki digitaalisten palvelujen tarjoamisesta, jossa edellytetään, että julkisten verkkopalvelujen on oltava saavutettavia.",
  "Accessibility.arviointi": "Olemme arvioineet palvelun saavutettavuuden itse",
  "Accessibility.saavutettavuustila": "Digipalvelun saavutettavuuden tila",
  "Accessibility.tayttaaVaatimukset": "Täyttää saavutettavuusvaatimukset osittain.",
  "Accessibility.saavuttamatonsisalto": "Ei-saavutettava sisältö",
  "Accessibility.eiVaatimustenMukainen": "Verkkosivusto ei ole vielä kaikilta osin vaatimusten mukainen",
  "Accessibility.mobiilikaytto": "Komponenttien toiminta mobiilikäytössä",
  "Accessibility.mobiilikaytto.teksti": "Mobiilikäytössä osa verkkosivun komponenteista toimivat eri tavalla, kuin tietokoneella. Saavutettavuustestausta ei ole tehty mobiilisivustolle. (WCAG 1.4.13)",
  "Accessibility.pdfLuettavuus": "Sivustolta ladattavan PDF-tiedoston luettavuus",  
  "Accessibility.pdfLuettavuus.teksti": "Sivusto tarjoaa kyselyyn vastanneelle mahdollisuutta ladata omista vastauksistaan PDF-tiedoston. Tämän tiedosto koostetaan sivulla näkyvästä yhteenvedosta ja on tarjottu lisätoiminto käyttäjälle. Käyttäjä saa samat tiedot sivuston käyttöliittymän kautta saavutettavasti. <br />Ladattavan PDF-tiedoston saavutettavuus ei toteudu kaikilta osin täydellisesti. Tietokoneelle ladatun tiedoston saavutettavuudessa on joitakin haasteita. (WCAG 1.3.1).",
  "Accessibility.saavutettavuuspuute": "Huomasitko saavutettavuuspuutteen digipalvelussamme? Kerro se meille ja teemme parhaamme puutteen korjaamiseksi",
  "Accessibility.sahkopostilla": "Sähköpostilla",  
  "Accessibility.kirjaamo": "kirjaamo@luke.fi",
  "Accessibility.muillatavoin": "Muilla tavoin",
  "Accessibility.muillatavoin.teksti": "Jos huomaat sivustollamme saavutettavuusongelmia, niin kerrothan niistä meille. Lähetä saavutettavuutta koskeva palautteesi osoitteeseen kirjaamo@luke.fi. Vastaamme palautteeseesi 14 päivän sisällä. <br /> <br /> Huomaathan, että voit myös pyytää lisätietoja osoitteesta kirjaamo@luke.fi, mikäli tarvitsemasi tieto ei ole saavutettavassa muodossa verkkosivuillamme. Vastaamme pyyntöösi 14 päivän sisällä. Jos pyyntö koskee laajaa sisältöä, voimme jatkaa vastausaikaa enintään kahdella viikolla. <br /> <br /> Tiedonkeruusovellusten käyttöongelmiin liittyvissä kysymyksissä voit ottaa yhteyttä osoitteeseen tilastokyselyt@luke.fi. Tilastollisten tiedonkeruiden aikana Luonnonvarakeskus auttaa tiedonluovuttajia antamaan tiedot tarvittaessa vaihtoehtoisella tavalla.",
  "Accessibility.valvontaviranomainen": "Valvontaviranomainen",
  "Accessibility.valvontaviranomainen.teksti": "Jos huomaat sivustolla saavutettavuusongelmia, anna ensin palautetta meille eli sivuston ylläpitäjälle. Vastauksessa voi mennä 14 päivää. Jos et ole tyytyväinen saamaasi vastaukseen tai et saa vastausta lainkaan kahden viikon aikana, <a href=\"https://www.saavutettavuusvaatimukset.fi/oikeutesi/\" target=\"_blank\"> voit tehdä ilmoituksen Etelä-Suomen aluehallintovirastoon <span>Avautuu uuteen ikkunaan</span></a> . Etelä-Suomen aluehallintoviraston sivulla kerrotaan tarkasti, miten ilmoituksen voi tehdä ja miten asia käsitellään.",
  "Accessibility.valvontaYhteystiedot": "Valvontaviranomaisen yhteystiedot",
  "Accessibility.valvontaYhteystiedot.teksti": "Etelä-Suomen aluehallintovirasto <br /> Saavutettavuuden valvonnan yksikkö <br /> www.saavutettavuusvaatimukset.fi <br /> saavutettavuus(at)avi.fi <br /> puhelinnumero vaihde 0295 016 000",
  "Accessibility.saavutettavuudenParantaminen": "Teemme jatkuvasti työtä saavutettavuuden parantamiseksi",
  "Accessibility.tarjoammeTukea": "Tarjoamme tukea käyttäjille joille digipalvelut eivät ole saavutettavissa",
  "Accessibility.tarjoammetukea.teksti": "Mikäli käyttäjä ei pysty vastaamaan lakisääteiseen tiedonkeruuseen tiedonkeruulomaketta käyttäen, Luonnonvarakeskus on puhelimitse yhteydessä näihin henkilöihin tietojen saamiseksi.",
  "Accessibility.julkaisu": "Tämä verkkosivusto/sovellus on julkaistu",
  "Accessibility.julkaisu.pvm": "29.09.2022",


  "common.app": "Satosovellus",
  "common.logout": "Kirjaudu ulos",
  "common.edellinen": "Edellinen",
  "common.seuraava": "Seuraava",
  "common.kirjautumisvirhe": "Vastaajatunnus on virheellinen.",
  "common.syota_vastaajatunnus": "Vastaajatunnus",
  "common.lähetä": "Lähetä",
  "common.hello": "Hei",
  "common.fi": "Suomeksi",
  "common.se": "På svenska",
  "common.satotilastokysely": "Satotilastokysely",
  "common.satotilastokysely.luomu": "Satotilastokysely luomu",
  "common.peruna": "Perunan sato ja varastot",
  "common.loggedOut": "Olet kirjautunut ulos. Tilastokyselyihin annetut vastaukset tallentuvat automaattisesti.",
  "common.timedOut": "Istuntosi on vanhentunut ja tiedot tallennettu automaattisesti. Kirjaudu uudelleen sisään jatkaaksesi.",
  "common.toFrontpage": "Palaa etusivulle",
  "common.uusilomake": "Palaa alkuun",
  "common.kiitos": "Kiitos vastauksistasi!",
  "common.tilatunnus": "Tilatunnus",
  "common.status": "Vastauksen tila",
  "common.download_pdf": "Lataa PDF",
  "common.kiitosTekstiSato1.1": "Kiitos vastauksesta satotilastokyselyyn.",
  "common.kiitosTekstiSato1.2": "Voit muuttaa vastauksiasi 30.10. saakka. Jos teet vastauksiin muutoksia, lähetä kysely uudelleen.",
  "common.kiitosTekstiPeruna1.1": "Kiitos vastauksesta perunan hävikki- ja varastokyselyyn.",
  "common.kiitosTekstiPeruna1.2": "Voit muuttaa vastauksiasi 30.10. klo 23.59 saakka. Jos teet vastauksiin muutoksia, lähetä kysely uudelleen.",
  "common.kiitosTilastoteksti": "Antamiasi tietoja käytetään Luonnonvarakeskuksen tilastojulkistuksissa. Voit tilata Luken tilastojulkistukset sähköpostiisi sivulta",
  "common.kiitosTilastolinkki": "https://www.luke.fi/fi/form/subscribe-to-releases",
  "common.kiitos.linkiteksti": "Tilaa tilastojulkistuksia",
  "common.eiEsitäyttötietoja": " Tietojemme mukaan et viljele tähän osioon liittyviä kasvilajeja. Voit jatkaa seuraavaan osioon painamalla <b>Seuraava</b> -painiketta.",
  "common.tuntematonVirheOtsikko": "Tapahtui virhe.",
  "common.tuntematonVirheViesti": "Päivitä sivu yrittääksesi uudelleen.",
  "virhe.404.sivuaEiLoytynyt.virhe": "Hakemaasi sivua ei löytynyt.",
  "virhe.404.sivuaEiLoytynyt.ohje.alku": "Pääset sivun alkuun",
  "virhe.404.sivuaEiLoytynyt.ohje.linkki": "tästä",
  "virhe.404.kysymyssarjaaEiLoytynyt.virhe": "Hakemaasi kysymyssarjaa ei löytynyt.",
  "virhe.404.kysymyssarjaaEiLoytynyt.ohje": "Valitse haluamasi kysymyssarja valikosta.",
  "virhe.errorboundary.kysymyssarja.virhe": "Tapahtui virhe.",
  "virhe.errorboundary.kysymyssarja.ohje": "Yritä valita haluamasi kysymyssarja uudelleen valikosta.",
  "virhe.errorboundary.router.virhe": "Tapahtui virhe.",
  "virhe.errorboundary.router.ohje": "Päivitä sivu yrittääksesi uudelleen.",
  "virhe.palvelin.tallennus": "Tallennus palvelimelle epäonnistui. Yritä kohta uudelleen.",
  "virhe.palvelin.luku": "Luku palvelimelta epäonnistui. Yritä kohta uudelleen päivittämällä sivu.",
  "muutViljelyKasvit.title": "Muut viljelykasvit",
  "muutViljelyKasvit.oljykasvit.title": "Öljykasvit",
  "muutViljelyKasvit.oljykasvit.kevätrypsi": "Kevätrypsi",
  "muutViljelyKasvit.oljykasvit.kevätrapsi": "Kevätrapsi",
  "muutViljelyKasvit.oljykasvit.syysrypsi": "Syysrypsi",
  "muutViljelyKasvit.oljykasvit.syysrapsi": "Syysrapsi",
  "muutViljelyKasvit.kumina": "Kumina",
  "muutViljelyKasvit.kumina.ohjeteksti.Viljelyala": "Viljelyala sisältää sen tukihakemukseen ilmoitetun kumina-alan, jota on viljelty samalla peltolohkolla vuosina {{ed_vuosi}} ja {{vuosi}}. Anna tiedot korjatulta kumina-alalta. Viljely- ja korjuualat eivät saa sisältää alaa, jolta satoa korjataan tulevina vuosina.",
  "muutViljelyKasvit.tattari": "Tattari",
  "muutViljelyKasvit.sokerijuurikas": "Sokerijuurikas",
  "muutViljelyKasvit.timoteinsiemen": "Timoteinsiemen",
  "muutViljelyKasvit.oljykasvit.öljypellava": "Öljypellava",
  "muutViljelyKasvit.oljykasvit.öljyhamppu": "Öljyhamppu",
  "muutViljelyKasvit.viljelyala": "Viljelyala",
  "muutViljelyKasvit.korjuuala": "Korjuuala",
  "muutViljelyKasvit.ohjeteksti.KiljelyalaOhje": "Viljelyala on esitäytetty tukihakemuksen tiedoilla vuosilta 2021 ja 2022.",
  "muutViljelyKasvit.ohjeteksti.KorjuualaOhje": "Ilmoita korjuualaan vain korjattu viljelyala. Korjuuala ei saa sisältää alaa, jolta satoa korjataan tulevina vuosina.",
  "muutViljelyKasvit.ohjeteksti.tayttoohje": "Viljelyala on esitäytetty tukihakemuksen tiedoilla. Lisää siihen tarvittaessa pystyyn ostettu ala ja vähennä pystyyn myyty ala.",
  "common.ha": "ha",
  "common.kg": "kg",
  "common.kg-ha": "kg/ha",
  "common.ei": "Ei",
  "common.kyllä": "Kyllä",
  "sato.title": "Satotilastokysely",
  "sato.description1.1": "Tervetuloa vastaamaan tilastokyselyyn, jossa selvitetään vuoden 2022 peltokasvituotantoa Suomessa.",
  "sato.description1.1.2": "Kyselyssä on mukana noin 6 200 erikokoista ja erilaista tuotantoa harjoittavaa maatilaa eri puolilta Suomea. Näistä tiloista luomutuotannossa on noin 700 tilaa.",
  "sato.description1.2": "Maatilanne osalta kysely sisältää peltokasvien kylvö- ja korjuualat sekä kokonaissadot, syyskylvöalat sekä viljan varastomäärät.",
  "sato.description1.2.1": "Luomutuotantoa harjoittavilta tiloilta kysytään vain luomutuotannossa olevan peltoalan tiedot. Tiedoista koostetaan Suomen virallisia tilastoja, joista tärkein on satotilasto.",
  "sato.luovutus": "Yksikkötason tietoja ei luovuteta missään olosuhteissa hallinnollista päätöksentekoa, tutkintaa, valvontaa tai muuta vastaavaa käyttöä varten.",
  "peruna.title": "Perunan sato ja varastot",
  "peruna.description1.1": "Tervetuloa vastaamaan tilastokyselyyn, jossa selvitetään maatilojen varastoissa olevan ruoka- ja ruokateollisuusperunan määrää sekä tämän vuoden peltokasvituotantoa Suomessa.",
  "peruna.description1.2": "Kyselyssä on mukana noin 200 maatilaa eri puolilta Suomea. Tiedoista koostetaan ruokaperuna varastotilasto ja satotilasto.",
  "peruna.ohjeteksti.SatotiedotOhje": "Korjuuala ei sisällä katoalaa",
  "footer.saavutettavuus": "Saavutettavuusseloste",
  "footer.tietosuoja": "Tietosuojaseloste",
  "ViljanSatotiedot.title": "Viljakasvit",
  "kasvilajit.label": "Kasvilajit",
  "ViljanSatotiedot.ohjeteksti.tayttoohje": "Viljelyala on esitäytetty tukihakemuksen tiedoilla. Lisää siihen tarvittaessa pystyyn ostettu ala ja vähennä pystyyn myyty ala.",
  "ViljanSatotiedot.ohjeteksti.ValitseKorjuutapa": "<b>Kuivattava vilja</b> on tuleentunut vilja, joka on kuivattu varastokosteuteen. <b>Tuoresäilövilja</b> on puimalla korjattu, tuoreena säilötty jyvävilja. <b>Kokoviljasäilörehu</b> on tähkimisen jälkeen niittämällä korjattu kokonainen viljakasvusto.",
  "ViljanSatotiedot.ohjeteksti.ViljelyalaOhje": "Lisää pystyyn ostettu ala ja vähennä pystyyn myyty ala.",
  "ViljanSatotiedot.ohjeteksti.KuivattavaViljaKorjuualaOhje": "Korjuuala ei sisällä katoalaa, eikä pystyyn myytyä alaa",
  "ViljanSatotiedot.ohjeteksti.KokoviljaSäilörehuKorjuualaOhje": "Korjuuala ei sisällä katoalaa, eikä pystyyn myytyä alaa",
  "ViljanSatotiedot.ohjeteksti.TuoreenaKorjattavaViljaOhje": "Korjuuala ei sisällä katoalaa, eikä pystyyn myytyä alaa",
  "ViljanSatotiedot.KuivattavaVilja": "Kuivattava vilja",
  "ViljanSatotiedot.TuoreenaKorjattavaVilja": "Tuoresäilövilja",
  "ViljanSatotiedot.Kokoviljasäilörehu": "Kokoviljasäilörehu",
  "ViljanSatotiedot.Vihantavilja": "Vihantavilja",
  "ViljanSatotiedot.ohjeteksti.VihantaviljaKorjuualaOhje": "Alasta vähennetään laidun-ja niittorehuala.",
  "ViljanSatotiedot.lajiohjeet": "Valitse viljellyt kasvilajit ja syötä satotiedot kullekin lajille. Tukihakemuksessa ilmoittamasi kasvilajit on valittu sinulle automaattisesti.",
  "Varastotiedot.title": "Varastovilja",
  "Varastotiedot.ohje": "Ilmoita viljan varastomäärä {{heinakuussa}} ja arvio viljan varastomäärästä {{tammikuussa}}. Mukaan lasketaan tilan omistama viljamäärä, tilan ulkopuoliset varastot sekä kauppaan ja/tai teollisuuteen myyty vilja, joka on tilan varastossa edellä mainittuina päivämäärinä. <b>Ilmoita vain kuivana korjatun viljan varastomäärä.</b>",
  "Varastotiedot.ohje.luomu": "Ilmoita luomuna tuotetun viljan varastomäärä 1.7.2022 ja arvio viljan varastomäärästä 1.1.2023. Mukaan lasketaan tilan omistama viljamäärä, tilan ulkopuoliset varastot sekä kauppaan ja/tai teollisuuteen myyty vilja, joka on tilan varastossa edellä mainittuina päivämäärinä. <b>Ilmoita vain kuivana korjatun viljan varastomäärä.</b>",
  "Varastotiedot.vehnä": "Vehnä",
  "Varastotiedot.ruis": "Ruis",
  "Varastotiedot.kaura": "Kaura",
  "Varastotiedot.ohra": "Ohra",
  "fields.Kuivakorjuuala": "Kuivan sadon korjuuala",
  "fields.Viljelyala": "Viljelyala",
  "fields.KuivattuKokonaismäärä": "Kuivatun sadon kokonaismäärä",
  "fields.VarastoarvoHeinäkuu": "Viljaa varastossa {{heinäkuu}}",
  "fields.VarastoarvoHeinäkuuYhteenveto": "Viljaa varastossa 1.7.2022",
  "fields.VarastoarvoTammikuuArvio": "Viljaa varastosta {{tammikuu}}, arvio",
  "fields.VarastoarvoTammikuuArvioYhteenveto": "Viljaa varastossa 1.1.2023, arvio",
  "fields.onkoVarastossaViljaa": "Varastossa on viljaa",
  "fields.TuoreviljanKorjuuala": "Tuoreviljan korjuuala",
  "fields.TuoresadonKokonaismäärä": "Tuoresadon kokonaismäärä",
  "fields.KokoviljasäilörehuKorjuuala": "Kokoviljasäilörehun korjuuala",
  "fields.KokoviljasäilörehuKokonaismäärä": "Kokoviljasäilörehusadon kokonaismäärä",
  "fields.KuivaaineProsentti": "Kuiva-aineprosentti",
  "fields.Kosteusprosentti": "Kosteusprosentti",
  "fields.Vihantavilja": "Korjattiinko tilallanne vihantaviljaa?",
  "fields.VihantaviljaKorjuuala": "Korjuuala",
  "fields.VihantaviljaKokonaismäärä": "Sadon kokonaismäärä",
  "fields.SadonKokonaismäärä": "Sadon kokonaismäärä",
  "fields.Kylvoala": "Kylvöala",
  "fields.Suorakylvoala": "Suorakylvöala",
  "fields.KuivasadonSatotaso": "Kuivasadon satotaso kg/ha",
  "fields.TuoresadonSatotaso": "Tuoresadon satotaso kg/ha",
  "fields.Korjuuala": "Korjuuala",
  "fields.Korjuukerta": "Korjuukerta",
  "fields.SatoYhteensä": "Satomäärä",
  "fields.Niittorehu": "Niittorehu",
  "fields.Säilörehu": "Säilörehu, tuore",
  "fields.SäilörehuEsikuivattu": "Säilörehu, esikuivattu",
  "fields.Satotaso": "Satotaso",
  "RehunurmenSatotiedot.otsikko.KorjattuRehunurmiala": "Rehunurmiala kasvukaudella",
  "RehunurmenSatotiedot.otsikko.Kokonaissato": "Kokonaissato",
  "RehunurmenSatotiedot.otsikko.Korjuukerrat": "Korjuukerrat kasvukaudella",
  "RehunurmenSatotiedot.tooltip.Korjattuala": "Korjattu rehunurmiala = tukihakemuksessa ilmoitettu rehunurmiala - pystyyn myyty ala - ei korjuuta tai niitetty maahan + pystyyn ostettu",
  "fields.Pystyynmyynti": "Pystyyn myyty",
  "fields.RehunurmialaYhteensä": "Tukihakemuksessa ilmoitettu ala",
  "fields.Pystyynosto": "Pystyyn ostettu",
  "fields.Katoala": "Ei korjuuta tai niitetty maahan",
  "fields.Korjattuala": "Ala, jolta korjattu satoa",
  "fields.ohjeteksti.Esitaytto": "Esitäytetty tukihakemuksen tiedoilla.",
  "fields.Kuivaheinä": "Kuivaheinä",
  "fields.SäilörehunKorjuuala": "Säilörehun korjuuala",
  "fields.SäilörehuEsikuivattuKuivaaineProsentti": "Esikuivatun säilörehun kuiva-aineprosentti",
  "fields.SäilörehuKuivaaineProsentti": "Tuoreen säilörehun kuiva-aineprosentti",
  "fields.NiittorehuKuivaaineProsentti": "Niittorehun kuiva-aineprosentti",
  "fields.Istutusala": "Istutusala",
  "fields.TuotettuSato": "Tilalla korjattu satoa vuonna {{vuosi}}",
  "fields.Korjaamaton": "Korjaamaton peltoon jäänyt sato",
  "fields.TuotettuSatoYhteenveto": "Tilalla tuotettu satoa vuonna 2021",
  "fields.Elintarvikekäyttöön": "Elintarvikekäyttöön päätynyt sato",
  "fields.Rehuksi": "Rehuksi päätynyt sato",
  "fields.Kompostointiin": "Kompostointiin/biojätekeräykseen päätynyt sato",
  "fields.Muu": "Muuhun käyttöön päätynyt sato",
  "fields.MuuSelite": "Muun käytön selite",
  "fields.KayttoYhteensa": "Käyttö yhteensä",
  "fields.muutsyyt": "Syyt, joiden vuoksi satoa käytettiin muuhun kuin elintarvikekäyttöön",
  "fields.Lisätiedot": "Lisätiedot",
  "fields.Hävikkikokolaatu": "Koko, paino, muoto tai muu ulkonäköön liittyvä syy / ostajan kriteerit eivät täyty",
  "fields.HävikkiSuurisato": "Ennakoitua suurempi sato /sadon valmistuminen ennakoitua aiemmin",
  "fields.HävikkiMenekkivaikeudet": "Menekkivaikeudet",
  "fields.HävikkiVarastointitappio": "Varastointitappio",
  "fields.Sää": "Sää",
  "fields.Kokopaino": "Ulkonäkö- ja muut laatuvaatimukset",
  "fields.Nostohävikki": "Nostohävikki",
  "fields.errors.Min": "Arvo ei voi olla negatiivinen",
  "fields.satotaso": "Satotaso",
  "fields.errors.MaxHundreds": "Arvo saa olla maksimissaan 999,99 ha.",
  "fields.errors.MaxThousands": "Arvo saa olla maksimissaan 9999,99 ha.",
  "fields.errors.MaxMillion": "Arvo saa olla maksimissaan 999 999 kg.",
  "fields.errors.MaxMillions": "Arvo saa olla maksimissaan 9 999 999 kg.",
  "fields.errors.TuoresäilöviljanKosteusprosenttiMin": "Tuoresäilöviljan kosteusprosentin on oltava vähintään 11%.",
  "fields.errors.TuoresäilöviljanKosteusprosenttiMax": "Tuoresäilöviljan kosteusprosentti voi olla enintään 80%.",
  "fields.errors.ViljanKuivaaineProsenttiMin": "Kuiva-aineprosentin on oltava vähintään 12%.",
  "fields.errors.ViljanKuivaaineProsenttiMax": "Kuiva-aineprosentti saa olla enintään 80%.",
  "fields.errors.ValkuaiskasvienKosteusprosenttiMin": "Valkuaiskasvisadon kosteusprosentin on oltava vähintään 5%.",
  "fields.errors.ValkuaiskasvienKosteusprosenttiMax": "Valkuaiskasvisadon kosteusprosentti voi olla enintään 50%.",
  "fields.errors.ValkuaiskasvienKuivaaineProsenttiMin": "Valkuaiskasvisadon kuiva-aineprosentin on oltava vähintään 12%.",
  "fields.errors.ValkuaiskasvienKuivaaineProsenttiMax": "Valkuaiskasvisadon kuiva-aineprosentti voi olla enintään 80%.",
  "fields.errors.ÖljykasvienKosteusprosenttiMin": "Öljykasvisadon kosteusprosentin on oltava vähintään 5%.",
  "fields.errors.ÖljykasvienKosteusprosenttiMax": "Öljykasvisadon kosteusprosentti voi olla enintään 50%.",
  "fields.errors.SäilörehuMax": "Säilörehun satomäärä saa olla enintään 99 999 999 kg.",
  "fields.errors.NurmirehuKuivaaineProsenttiMin": "Rehunurmen kuiva-aineprosentin on oltava vähintään 10%.",
  "fields.errors.NurmirehuKuivaaineProsenttiMax": "Rehunurmen kuiva-aineprosentti voi olla enintään 60%",
  "fields.errors.SäilörehuEsikuivattuKuivaaineprosenttiMin": "Esikuivatun säiliörehun kuiva-aineprosentin on oltava vähintään 15%.",
  "fields.errors.SäilörehuEsikuivattuKuivaaineprosenttiMax": "Esikuivatun säiliörehun kuiva-aineprosentti voi olla enintään 70%.",
  "fields.errors.SäilörehuSatoYhteensä": "Säilörehusadon on oltava enemmän kuin 0 kg.",
  "fields.errors.SäilörehuSatoYhteensäMax": "Säilörehusato voi olla enintään 99 999 999 kg.",
  "fields.errors.PercentageMaxSadonkäyttö": "Käytön yhteenlaskettu prosenttimäärä voi olla enintään 100%.",
  "fields.errors.yliKylvöalan": "Suorakylvöala ei saa olla suurempi kuin kylvöala.",
  "fields.errors.yliViljelyalan": "Korjuuala ei saa ylittää viljelyalaa.",
  "fields.errors.yliIstutusalan": "Korjuuala ei saa ylittää istutusalaa.",
  "fields.errors.kuivattuSatoRequired": "Ilmoita kuivatun sadon kokonaismäärä.",
  "fields.errors.korjuualaRequired": "Ilmoita korjuuala. Jos et ole korjannut satoa, ilmoita korjuualaksi 0.",
  "fields.errors.korjuualaValkuaiskasvitRequired": "Ilmoita korjuuala vähintään yhdelle korjuutavalle. Jos et ole korjannut satoa, ilmoita korjuualaksi 0.",
  "fields.errors.kuivakorjuualaRequired": "Ilmoita kuivan sadon korjuuala.",
  "fields.errors.tuoresatoRequired": "Ilmoita tuoresadon kokonaismäärä.",
  "fields.errors.kosteusprosenttiRequired": "Ilmoita kosteusprosentti.",
  "fields.errors.kuivaaineprosenttiRequired": "Ilmoita kuiva-aineprosentti.",
  "fields.errors.sadonKokonaismääräRequired": "Ilmoita sadon kokonaismäärä.",
  "fields.errors.kokoviljasäilörehukorjuualaRequired": "Ilmoita sadon kokonaismäärä.",
  "fields.errors.kokoviljasäilörehusatoRequired": "Ilmoita sadon kokonaismäärä.",
  "fields.errors.tuorekorjuualaRequired": "Ilmoita tuoreviljan korjuuala.",
  "fields.errors.myymättäJäänytRequired": "Ilmoita myymättä jääneen sadon määrä kiloissa. Jos satoa ei ole jäänyt myymättä, anna kenttään luku 0.",
  "fields.errors.korjaamattaJäänytRequired": "Ilmoita korjaamattoman, peltoon jääneen sadon määrä kiloissa. Jos satoa ei ole jäänyt korjaamatta, anna kenttään luku 0.",
  "fields.errors.muuviljarequired": "Anna satotiedot. Jos sinulla ei ole viljelyalaa esimerkiksi pystyyn myynnin takia, ilmoita viljelyalaksi 0.",
  "fields.errors.korjuutaparequired": "Valitse ainakin yksi korjuutapa. Jos et ole korjannut satoa, syötä viljelyalaksi 0.",
  "fields.validation.numberRequired": "Syötä numeroarvo.",
  "fields.validation.positiveNumberRequired": "Syötä numeroarvo joka on suurempi kuin nolla.",
  "fields.errors.viljelyalaRequired": "Viljelyala on pakollinen tieto. Jos viljelyala poikkeaa EU-tukihakemukseen ilmoitetusta tiedosta, ilmoita uusi ala tai merkitse kenttään luku 0, jos olet esimerkiksi myynyt pystyyn koko alan.",
  "syyskylvot.title": "Syyskylvöt",
  "fields.errors.korjuu1eroaakokonaiskorjuusta": "Antamasi tiedot eivät täsmää. Ensimmäisen korjuun hehtaarialojen summa tulee olla yhtä suuri, kuin Korjattu rehunurmiala -kentässä oleva luku. Erotus on {{erotus}}.",
  "fields.errors.korjuu2eroaakokonaiskorjuusta": "Antamasi tiedot eivät täsmää. Toisen korjuun hehtaarialojen summan tulee olla yhtä suuri tai vähemmän kuin korjattu rehunurmiala. Erotus on {{erotus}}.",
  "fields.errors.korjuu3eroaakokonaiskorjuusta": "Antamasi tiedot eivät täsmää. Kolmannen korjuun hehtaarialojen summan tulee olla yhtä suuri tai vähemmän kuin korjattu rehunurmiala. Erotus on {{erotus}}.",
  "fields.errors.rehunurmensatotiedot.kokonaissatovastaakorjuuta": "Kokonaissato ei voi olla tyhjä tai nolla, sillä kasvukaudella on ilmoitettu korjuualaa. Anna satomäärä kiloissa.",
  "fields.errors.rehunurmensatotiedot.kokonaissadonkuivaaineprosenttiannettu": "Kuiva-aineprosentti ei voi olla tyhjä, sillä kokonaissadon määrä on annettu. Ilmoita sadon kuiva-aineprosentti.",
  "fields.errors.satotaso.hehtaarisatoylittääsallitunarvon": "Hehtaarisato ylittää sallitun arvon. Tarkista sadon korjuuala ja kokonaismäärä.",
  "fields.errors.peruna.käyttöyhteensä": "Käyttökohteiden yhteenlaskettu prosenttiluku on {{prosenttiYhteensa}}%. Luvun tulee olla tasan 100%.",
  "syyskylvot.ohjeteksti.ohje": "Ilmoita hehtaarit, jotka tilan peltoalasta on kylvetty syksyllä {{vuosi}}.",
  "syyskylvot.ohjeteksti.selitys": "Suorakylvöalalla tarkoitetaan, että kylvö tehty edeltävän kasvin sänkeen.",
  "syyskylvot.ohjeteksti.SuorakylvoalaOhje": "Kylvö tehty edeltävän kasvin sänkeen.",
  "syyskylvot.syysvehna": "Syysvehnä",
  "syyskylvot.syysohra": "Syysohra",
  "syyskylvot.syysrypsi": "Syysrypsi",
  "syyskylvot.syysruis": "Syysruis",
  "syyskylvot.syysrapsi": "Syysrapsi",
  "syyskylvot.syysvehna.spelttivehna": "(sis. spelttivehnän)",
  "ValkuaiskasvienSatotiedot.title": "Valkuais- ja öljykasvit",
  "ValkuaiskasvienSatotiedot.KuivattavaKasvi": "Puitu siemen",
  "ValkuaiskasvienSatotiedot.Korjuutiedot": "Korjuutiedot",
  "ValkuaiskasvienSatotiedot.Säilörehu": "Säilörehu",
  "ValkuaiskasvienSatotiedot.ohjeteksti.tayttoohje": "Viljelyala on esitäytetty tukihakemuksen tiedoilla. Lisää siihen tarvittaessa pystyyn ostettu ala ja vähennä pystyyn myyty ala. ",
  "RehunurmenSatotiedot.ohjeteksti": "Esitäytetty",
  "RehunurmenSatotiedot.ohjeteksti1": "sisältää kuivaheinä-, säilörehu- ja tuorerehunurmena ilmoitetun alan. Anna ensin erikseen rehunurmialat, jotka on",
  "RehunurmenSatotiedot.ohjeteksti_a": "myyty pystyyn,",
  "RehunurmenSatotiedot.ohjeteksti_b": "ei korjattu kertaakaan kasvukaudella (niitetty maahan tai ei korjattu kertaakaan) tai",
  "RehunurmenSatotiedot.ohjeteksti_c": "ostettu pystyyn.",
  "RehunurmenSatotiedot.tukihakemus": "tukihakemuksessa ilmoitettu ala",
  "RehunurmenSatotiedot.ohjeteksti2": "Anna jokaiselta korjuukerralta korjatun rehun alat (ha).",
  "RehunurmenSatotiedot.ohjeteksti3": "Anna jokaisesta rehusta kokonaissato (kg) säilöntäkosteudessa. Anna myös ilmoittamasi kokonaissadon kuiva-aine-%.",
  "RehunurmenSatotiedot.Kuivaheinä": "Kuivaheinä",
  "RehunurmenSatotiedot.Kuivaheinän": "Kuivaheinän",
  "RehunurmenSatotiedot.Säilörehu": "Säilörehu, tuoresäilötty",
  "RehunurmenSatotiedot.TuoresäilöttySäilörehun": "Tuoresäilötyn säilörehun",
  "RehunurmenSatotiedot.SäilörehuEsikuivattu": "Säilörehu, esikuivattu",
  "RehunurmenSatotiedot.EsikuivattuSäilörehun": "Esikuivatun säilörehun",
  "RehunurmenSatotiedot.Niittorehu": "Niittorehu",
  "RehunurmenSatotiedot.title": "Rehunurmi",
  "PerunanSatotiedot.title": "Peruna",
  "PerunanSatotiedot.Ruokaperuna": "Ruokaperuna",
  "PerunanSatotiedot.Ruokateollisuusperuna": "Ruokateollisuusperuna",
  "PerunanSatotiedot.Varhaisperuna": "Varhaisperuna",
  "PerunanSatotiedot.Tärkkelysperuna": "Tärkkelysperuna",
  "PerunanSatotiedot.Siemenperuna": "Siemenperuna",
  "PerunanSatotiedot.ohjeteksti.tayttoohje": "Vain omaan käyttöön viljeltyä perunaa ei tarvitse ilmoittaa.",
  "PerunanSatotiedot.ohjeteksti.VarastossaOhje": "Omalla tilalla viljellyn kauppakelpoisen perunan varastomäärä 15.10.2022. Mukaan lasketaan sekä tilan omassa varastossa että tilan ulkopuolella vuokravarastossa oleva perunamäärä.Mukaan ei lasketa omassa varastossa olevaa muilta viljelijöiltä ostettua tai muiden tuottamaa vuokravarastoitua perunamäärää",
  "PerunanSatotiedot.ohjeteksti.KorjuualaOhje": "Korjuuala ei sisällä katoalaa",
  "PerunanSatotiedotYhteensa.ohjeteksti.yhteenveto": "Alla yhteenveto antamistasi tiedoista. Muokkaa yllä olevia tietoja tarvittaessa.",
  "PerunanSatotiedotYhteensa.otsikko": "Yhteensä",
  "fields.Sadostamyyty1510": "Sadosta myyty 15.10. mennessä",
  "fields.Varastossa1510": "Varastossa 15.10.",
  "fields.KorjaamatonSatoVaadittu": "Jos peltoon jäänyttä korjaamatonta satoa ei ole, ilmoita nolla.",
  "fields.KorjaamatonSato": "Korjaamaton sato",
  "fields.MuuSyy": "Muu syy, esimerkiksi työvoiman saatavuus tai konerikko",
  "fields.MuuSyyKasvistenSadonkäyttöTilalla": "Muu syy, esimerkiksi tärkkelysperunatehdas",
  "fields.satoakorjaamatta": "Syyt, jonka vuoksi satoa jäi korjaamatta:",
  "fields.IstutusalaYhteensä": "Istutusala yhteensä",
  "fields.KorjattuAlaYhteensä": "Korjattu ala yhteensä",
  "fields.KorjaamatonAlaYhteensä": "Korjaamaton ala yhteensä",
  "Lisätiedot.title": "Lisätiedot",
  "PerunanSatojaVarastot.title": "Perunan sato ja varastot",
  "PerunanLisätiedot.title": "Lisätiedot",
  "KasvistenSadonkäyttö.title": "Vuoden {{ed_vuosi}} sadonkäyttö tilalla",
  "KasvistenSadonkäyttö.KäytönOhje": "Ilmoita, mihin korjattu sato on käytetty. Jos määristä ei ole tarkkaa tietoa, arvion voi tehdä aikaisempien vuosien keskiarvon mukaan. Tähän sisältyy kaikki omalla tilalla tapahtuva toiminta (varastointi, kauppakunnostus, pakkaaminen) ennen mahdollista jatkojalostusta.",
  "KasvistenSadonkäyttö.HävikkisyytOtsikko": "Mistä syistä osa korjatusta sadosta päätyi muuhun kuin elintarvike- tai rehukäyttöön?",
  "KasvistenSadonkäyttö.KäyttökohteetOtsikko": "Mihin käyttökohteisiin korjattu sato päätyi?",
  "KasvistenSadonkäyttö.KäyttökohteetOhje": "Arvioi, kuinka suuri osa viime syksynä korjatusta kuoripäällisestä perunasta päätyi alla oleviin eri  käyttökohteisiin.",
  "KorjaamatonSato.title": "Peltoon jäänyt korjaamaton sato",
  "KorjaamatonSato.KäytönOhje": "Jos peltoon jäänyttä korjaamatonta satoa ei ole, ilmoita kohtaan nolla.",
  "KorjaamatonSato.KorjaamatonSyytOhje": "Arvioi mistä syistä satoa jäi korjaamatta. Valitse 1-2 tärkeintä syytä.",
  "perunanSato.title": "Perunan satotiedot",
  "confirmdialog.title": "Sivulla on tallentamattomia tietoja",
  "confirmdialog.content": "Sivulla on tallentamattomia tietoja. Jos siirryt toiseen osioon, nämä tiedot menetetään. Jos haluat tallentaa tiedot, valitse Palaa ja paina Seuraava-painiketta sivun oikeassa alalaidassa.",
  "confirmdialog.back": "Palaa",
  "confirmdialog.continue": "Jatka tallentamatta tietoja",
  "lisätiedot.ohjeteksti": "Tarkenna antamiasi tietoja tarvittaessa. Voit esimerkiksi kertoa, miksi satotiedot poikkeavat normaalista.",
  "yhteenveto.title": "Yhteenveto",
  "yhteenveto.ohjeteksti": "<p>Alla näet yhteenvedon antamistasi vastauksista. Tarkista antamasi tiedot. Saat tallennettua vastaukset painamalla painiketta <b>Lataa PDF</b>. Vastausten tallentaminen on mahdollista myös niiden lähettämisen jälkeen.</p><p>Kun kaikki vaaditut tiedot on annettu, paina <b>Lähetä</b>. Voit palata muokkaamaan vastauksia tietojen lähettämisen jälkeen. Muutokset päivittyvät, kun lähetät tiedot uudelleen.</p>",
  "status.aloittamaton": "Aloittamaton",
  "status.kesken": "Kesken",
  "status.lähetetty": "Lähetetty"
}
