import {
  extendedYup as yup,
  nullableNumber,
  testSatotasoMax,
  validators,
} from "../../../common/FormUtils";

export type ValkuaiskasvienSatotiedotForm = {
  ValkuaiskasvienSatotiedot: ValkuaiskasvienSatotieto[];
};

export enum Fields {
  Kasvilaji = "Kasvilaji",
  Viljelyala = "_610920941",
  Korjuuala = "_610920919",
  KorjuualaSäilörehu = "_610920919sr",
  Sato = "_566014545",
  SatoSäilörehu = "_566014545sr",
  KuivaaineSäilörehu = "_6115386931",
  SatotasoKuiva = "Satotaso_Kuiva",
  SatotasoTuore = "Satotaso_Tuore",
  KorjuualaÖljykasvi = "_610920919_öljy",
  SatomääräÖljykasvi = "_566014545_öljy",
  SatotasoÖljykasvi = "_Satotaso_öljy",
}

type TKasvilaji = "_387139" | "_386579" | "_61223";
type TÖljyKasvilaji =
  | "_387023"
  | "_387019"
  | "_387027"
  | "_387243"
  | "_386999"
  | "_387003";

export class ValkuaiskasvienSatotieto {
  constructor(Kasvilaji: TKasvilaji, Viljelyala?: number) {
    this[Fields.Kasvilaji] = Kasvilaji;

    if (Viljelyala) this[Fields.Viljelyala] = Viljelyala;
  }
  [Fields.Kasvilaji]: TKasvilaji;
  [Fields.Viljelyala]: number | null = null;
  [Fields.Korjuuala]: number | null = null;
  [Fields.Sato]: number | null = null;
  [Fields.SatotasoKuiva]: number | null = null;
  [Fields.SatotasoTuore]: number | null = null;
  [Fields.KuivaaineSäilörehu]: number | null = null;
  [Fields.KorjuualaSäilörehu]: number | null = null;
  [Fields.SatoSäilörehu]: number | null = null;
  [Fields.KuivaaineSäilörehu]: number | null = null;
  [Fields.KorjuualaÖljykasvi]: number | null = null;
  [Fields.SatomääräÖljykasvi]: number | null = null;
  [Fields.SatotasoÖljykasvi]: number | null = null;
}

export default yup.object().shape({
  ValkuaiskasvienSatotiedot: yup.array().of(
    yup.object().shape({
      [Fields.Kasvilaji]: yup.string().required(),
      [Fields.Viljelyala]: validators.hundreds.notNull(
        0,
        "fields.errors.viljelyalaRequired"
      ),
      [Fields.Korjuuala]: validators.valkuaiskasvienKorjuuala
        .max(1000000, "fields.errors.MaxMillion")
        .test(
          "korjuualaRequired",
          "fields.errors.korjuualaValkuaiskasvitRequired",
          function (item) {
            // If KorjuualaSäilörehu or KorjuualaÖljykasvi is not given, field is required to have a value.
            if (
              this.parent[Fields.Viljelyala] &&
              (this.parent[Fields.KorjuualaSäilörehu] === undefined ||
                this.parent[Fields.KorjuualaSäilörehu] === null) &&
              (this.parent[Fields.KorjuualaÖljykasvi] === undefined ||
                this.parent[Fields.KorjuualaÖljykasvi] === null) &&
              (item === undefined || item === null)
            )
              return false;

            return true;
          }
        ),
      [Fields.Sato]: validators.millions.requiredWith(
        Fields.Korjuuala,
        "fields.errors.kuivattuSatoRequired"
      ),
      [Fields.KorjuualaSäilörehu]: validators.valkuaiskasvienKorjuuala.test(
        "korjuualaRequired",
        "fields.errors.korjuualaValkuaiskasvitRequired",
        function (item) {
          // If Korjuuala or KorjuualaÖljykasvi is not given, field is required to have a value.
          if (
            this.parent[Fields.Viljelyala] &&
            (this.parent[Fields.Korjuuala] === undefined ||
              this.parent[Fields.Korjuuala] === null) &&
            (this.parent[Fields.KorjuualaÖljykasvi] === undefined ||
              this.parent[Fields.KorjuualaÖljykasvi] === null) &&
            (item === undefined || item === null)
          ) {
            return false;
          }
          return true;
        }
      ),
      [Fields.SatoSäilörehu]: validators.millions.requiredWith(
        Fields.KorjuualaSäilörehu,
        "fields.errors.tuoresatoRequired"
      ),
      [Fields.KuivaaineSäilörehu]:
        validators.valkuaiskasvienKuivaaineProsentti.requiredWith(
          Fields.KorjuualaSäilörehu,
          "fields.errors.kuivaaineprosenttiRequired"
        ),
      [Fields.SatotasoTuore]: nullableNumber.test(
        ...testSatotasoMax<TKasvilaji>(
          { _387139: null, _386579: 35000, _61223: 35000 },
          {
            kasvilaji: Fields.Kasvilaji,
          }
        )
      ),
      [Fields.SatotasoKuiva]: nullableNumber.test(
        ...testSatotasoMax<TKasvilaji>(
          {
            _387139: 10000,
            _386579: 10000,
            _61223: 6000,
          },
          {
            kasvilaji: Fields.Kasvilaji,
          }
        )
      ),
      [Fields.KorjuualaÖljykasvi]: validators.valkuaiskasvienKorjuuala.test(
        "harvestedAreaMax",
        "fields.errors.korjuualaValkuaiskasvitRequired",
        function (item) {
          // If KorjuualaSäilörehu or Korjuuala is not given, field is required to have a value.
          if (
            this.parent[Fields.Viljelyala] &&
            (this.parent[Fields.KorjuualaSäilörehu] === undefined ||
              this.parent[Fields.KorjuualaSäilörehu] === null) &&
            (this.parent[Fields.Korjuuala] === undefined ||
              this.parent[Fields.Korjuuala] === null) &&
            (item === undefined || item === null)
          ) {
            return false;
          } else {
            return true;
          }
        }
      ),
      [Fields.SatomääräÖljykasvi]: validators.million.requiredWith(
        Fields.KorjuualaÖljykasvi,
        "fields.errors.sadonKokonaismääräRequired"
      ),
      [Fields.SatotasoÖljykasvi]: nullableNumber.test(
        ...testSatotasoMax<TÖljyKasvilaji>(
          {
            _387023: 6000,
            _387019: 5000,
            _387027: 5000,
            _387243: 6000,
            _386999: 4000,
            _387003: 4000,
          },
          {
            kasvilaji: Fields.Kasvilaji,
          }
        )
      ),
    })
  ),
});
