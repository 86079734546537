import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Footer, Header } from "../common";

const useStyles = makeStyles((theme) => ({
  middleBox: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  errorCodeAndTitle: {
    display: "flex",
    alignItems: "baseline",
  },
  errorTitleBox: {
    display: "flex",
  },
  errorBox: {
    display: "flex",
    alignItems: "center",
    marginTop: "",
  },
  errorNumber: {
    fontSize: "70px",
  },
  link: { color: "white" },
}));

export default function TunnistautuminenEpaonnistui() {
  const { t } = useTranslation();
  const classes = useStyles();

  //artifact
  var URLstatuscode;

  return (
    <>
      <Header isLoggedIn={false} />
      <Box className={classes.middleBox} pr={8} pl={8} pt={8} pb={8}>
        <Box className={classes.errorCodeAndTitle}>
          {!!URLstatuscode && (
            <Box mr={3} className={classes.errorBox}>
              <Typography className={classes.errorNumber}>
                {URLstatuscode}
              </Typography>
            </Box>
          )}

          <Box className={classes.errorTitleBox}>
            <Typography>
              {t("kirjaudu.tunnistautuminenEpaonnistui.title")}
            </Typography>
          </Box>
        </Box>
        <Box mt={1} className={classes.errorBox}>
          <Typography>
            <Link className={classes.link} to={"/"}>
              {t("kirjaudu.tunnistautuminenEpaonnistui.return")}
            </Link>
          </Typography>
        </Box>
      </Box>
      <Footer sticky />
    </>
  );
}
