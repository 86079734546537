import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { decode } from "jsonwebtoken";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import useLogin from "./useLogin";
import useLogout from "./useLogout";
import useTokenState from "./useTokenState";

export default function TokenExpirationPrompt() {
  const token = useTokenState();
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const login = useLogin();
  const logout = useLogout();

  const onExit = () => {
    setOpen(false);
    logout();
  };

  const onContinue = () => {
    setOpen(false);
    login();
  };

  useEffect(() => {
    const logoutTimer = setInterval(() => {
      if (token) {
        const payload = decode(token) as { exp: number };
        const remainingTokenLifetime =
          (payload?.exp * 1000 - Date.now()) / 60000; // minutes

        if (remainingTokenLifetime < 10 && remainingTokenLifetime > 5) {
          setOpen(true);
        } else if (remainingTokenLifetime < 5) onExit();
      }
    }, 180000);

    return () => clearInterval(logoutTimer);
  }, [token]);

  return (
    <Dialog open={open}>
      <DialogTitle>{t("istuntodialog.title")}</DialogTitle>
      <DialogContent dividers>
        <Typography>
          <Trans
            i18nKey={"istuntodialog.content"}
            components={{
              b: <b />,
              p: <p />,
            }}
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onExit}>
          {t("istuntodialog.back")}
        </Button>
        <Button onClick={onContinue}>{t("istuntodialog.continue")}</Button>
      </DialogActions>
    </Dialog>
  );
}
