import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import RootState from "../../../../store/types";
import {
  Fields,
  Lisatiedot,
} from "../../../common/lisatieto/lisatiedot.schema";

export default function YhteenvetoLisatiedot() {
  const { t } = useTranslation();
  const values: Lisatiedot = useSelector(
    ({ form }: RootState) => form.Lisatiedot
  );

  if (!values) return null;

  return (
    <Box p={2} mt={2} mb={2} sx={{ boxShadow: "0 0 4px rgba(0,0,0, 0.3)" }}>
      <Typography variant="h3">{t("Lisätiedot.title")}</Typography>
      <Box p={2}>
        <Typography>{values[Fields.Lisatiedot]}</Typography>
      </Box>
    </Box>
  );
}
