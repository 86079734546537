import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { HehtaariField } from "../../../common/Fields";
import FormSection from "../../../common/FormSection";
import FormSectionTitle from "../../../common/FormSectionTitle";
import { rehunurmilaskuri } from "./laskuri";
import {
  Fields,
  RehunurmenSatotiedotForm,
} from "./rehunurmenSatotiedot.schema";

const useKorjattualaValue = ({ methods }: IKorjattuala) => {
  const otherFieldValues = methods.watch([
    `RehunurmenSatotiedot.${Fields.Korjuuala}`,
    `RehunurmenSatotiedot.${Fields.Pystyynmyynti}`,
    `RehunurmenSatotiedot.${Fields.Katoala}`,
    `RehunurmenSatotiedot.${Fields.Pystyynosto}`,
  ]);

  const value = rehunurmilaskuri(...otherFieldValues);

  // Form validation needs to be triggered separately,
  // as it is not based on touching this field itself.
  // I.e. there is no `onBlur` event on this field (`Fields.Korjattuala`).
  useEffect(() => {
    methods.setValue(
      `RehunurmenSatotiedot.${Fields.Korjattuala}`,
      value as never
    );
  }, [value]);
};

interface IKorjattuala {
  methods: UseFormReturn<RehunurmenSatotiedotForm, any>;
}

const KorjattuAla = ({ methods }: IKorjattuala) => {
  useKorjattualaValue({ methods });
  const { t } = useTranslation();

  return (
    <>
      <Controller
        name={`RehunurmenSatotiedot.${Fields.Korjattuala}`}
        control={methods.control}
        render={({ field: { ref, ...field } }) => (
          <HehtaariField
            tooltip={t("RehunurmenSatotiedot.tooltip.Korjattuala")}
            disabled
            inputRef={ref}
            {...field}
            fullWidth
            label={"Korjattuala"}
            allowNegative
            allowZeroForInputValue
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
    </>
  );
};

interface IAlkutietoOsio {
  methods: UseFormReturn<RehunurmenSatotiedotForm, any>;
}

const AlkutietoOsio = ({ methods }: IAlkutietoOsio) => {
  const { t } = useTranslation();
  return (
    <FormSection>
      <FormSectionTitle>
        {t("RehunurmenSatotiedot.otsikko.KorjattuRehunurmiala")}
      </FormSectionTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Controller
            name={`RehunurmenSatotiedot.${Fields.Korjuuala}`}
            control={methods.control}
            render={({ field: { ref, ...field } }) => (
              <HehtaariField
                disabled
                inputRef={ref}
                {...field}
                value={field.value || 0}
                fullWidth
                label={"RehunurmialaYhteensä"}
                allowZeroForInputValue
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Controller
            name={`RehunurmenSatotiedot.${Fields.Pystyynosto}`}
            control={methods.control}
            render={({ field: { ref, ...field } }) => (
              <HehtaariField
                inputRef={ref}
                {...field}
                fullWidth
                label={"Pystyynosto"}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Controller
            name={`RehunurmenSatotiedot.${Fields.Pystyynmyynti}`}
            control={methods.control}
            render={({ field: { ref, ...field } }) => (
              <HehtaariField
                inputRef={ref}
                {...field}
                label={"Pystyynmyynti"}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Controller
            name={`RehunurmenSatotiedot.${Fields.Katoala}`}
            control={methods.control}
            render={({ field: { ref, ...field } }) => (
              <HehtaariField
                inputRef={ref}
                {...field}
                fullWidth
                label={"Katoala"}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <KorjattuAla methods={methods} />
        </Grid>
      </Grid>
    </FormSection>
  );
};

export default AlkutietoOsio;
