import jwt from "jsonwebtoken";
import useTokenState from "./useTokenState";
import {RespondentMeta, TokenUserInfoPayload} from "./useTokenUserInfo";

// Take the desired piece of the metadata table's data, determined by function parameter value
export default function useTokenMetadata(): RespondentMeta[] {
  const token = useTokenState();

  if (!token) {
    return [];
  }

  try {
    const { m } = jwt.decode(token) as TokenUserInfoPayload;

    return m;
  } catch (e) {
    console.error(e);
    return [];
  }
}
