import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setFormValues } from "../../../../store/form/actions";
import { useTilatyyppi } from "../../../../store/meta/selectors";
import RootState from "../../../../store/types";
import { FormActions } from "../../../common";
import DefaultActions from "../../../common/DefaultActions";
import FormInstructions from "../../../common/FormInstructions";
import FormPage from "../../../common/FormPage";
import FormPageTitle from "../../../common/FormPageTitle";
import { hasDirtyFields } from "../../../common/FormUtils";
import MobileActions from "../../../common/MobileActions";
import MobileFooter from "../../../common/MobileFooter";
import ConfirmationPrompt from "../../../common/Prompt/ConfirmationPrompt";
import useNavigateWithSearch from "../../../common/useNavigateWithSearch";
import YUPVirheilmoitus, {
  IYUPVirheilmoitus,
} from "../../../common/YUPVirheilmoitus";
import AlkutietoOsio from "./AlkutietoOsio";
import KokonaissatoOsio from "./KokonaissatoOsio";
import KorjuualaOsio from "./KorjuualaOsio";
import schemaCreator, {
  Fields,
  RehunurmenSatotiedotForm,
} from "./rehunurmenSatotiedot.schema";

const useNavigation = () => {
  const navigate = useNavigateWithSearch();
  const dispatch = useDispatch();

  const handleNextClick = (values: Partial<RehunurmenSatotiedotForm>) => {
    dispatch(setFormValues({ ...values, step: 7 }));
    navigate("/sato/lisatiedot");
  };
  const handlePreviousClick = (values: Partial<RehunurmenSatotiedotForm>) => {
    navigate("/sato/syyskylvot");
  };

  return { handleNextClick, handlePreviousClick };
};

const EiKorjuitaNäkyvissäNäkymä = () => {
  const { t } = useTranslation();
  return (
    <>
      <Box
        sx={{
          width: "100%",
          minHeight: "200px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography>
          {t("RehunurmenSatotiedot.ohjeteksti4")}
        </Typography>
      </Box>
    </>
  );
};

export default function RehunurmenSatotiedot() {
  const errRef = useRef<HTMLDivElement | null>(null);
  const values = useSelector(
    (state: RootState) => state.form.RehunurmenSatotiedot
  );
  const { t } = useTranslation();

  const methods = useForm<RehunurmenSatotiedotForm>({
    shouldFocusError: true,
    defaultValues: { RehunurmenSatotiedot: values },
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schemaCreator(t)),
  });

  const { handleNextClick, handlePreviousClick } = useNavigation();

  useLayoutEffect(() => {
    if (
      methods.formState.errors["RehunurmenSatotiedot"] &&
      (methods.formState.errors["RehunurmenSatotiedot"] as IYUPVirheilmoitus)
        .type === "sumOfFields" &&
      errRef.current
    ) {
      window.scrollTo({
        behavior: "smooth",
        top: errRef.current.offsetTop,
      });
    }
  }, [errRef.current, methods.formState.errors]);

  const korjattuala = methods.watch(
    `RehunurmenSatotiedot.${Fields.Korjattuala}`
  );
  const ovatkoKorjuutNäkyvissä = !!korjattuala && korjattuala > 0;

  const ttyyppi = useTilatyyppi();

  useEffect(() => {
    if (!ovatkoKorjuutNäkyvissä) {
      [0, 1, 2].forEach((korjuu) => {
        [
          Fields.Kuivaheinä,
          Fields.Säilörehu,
          Fields.SäilörehuEsikuivattu,
          Fields.Niittorehu,
        ].forEach((field) => {
          methods.resetField(
            `RehunurmenSatotiedot.Korjuu.${korjuu}.${field}` as any,
            {
              defaultValue: null,
            }
          );
        });
      });

      [
        Fields.KuivaheinäYhteensäKg,
        Fields.SäilörehuYhteensäKg,
        Fields.SäilörehuKuivaaineProsentti,
        Fields.SäilörehuEsikuivattuYhteensäKg,
        Fields.SäilörehuEsikuivattu,
        Fields.SäilörehuEsikuivattuKuivaaineProsentti,
        Fields.NiittorehuKuivaaineProsentti,
        Fields.NiittorehuYhteensäKg
      ].forEach((field) => {
        methods.resetField(`RehunurmenSatotiedot.${field}` as any, {
          defaultValue: null,
        });
      });
    }
  }, [korjattuala]);

  return (
    <FormProvider {...methods}>
      <FormPage>
        <FormPageTitle>7. {t("RehunurmenSatotiedot.title")}</FormPageTitle>
        <FormInstructions>
          <Trans
            i18nKey={
              ttyyppi === 2
                ? "RehunurmenSatotiedot.tukihakemus_luomu"
                : "RehunurmenSatotiedot.tukihakemus"
            }
            components={{
              b: <b />,
            }}
          />
        </FormInstructions>
        <AlkutietoOsio methods={methods} />
        <div ref={errRef}>
          <YUPVirheilmoitus
            errors={methods.formState.errors}
            id="RehunurmenSatotiedot"
            type="sumOfFields"
          />
        </div>

        {ovatkoKorjuutNäkyvissä ? (
          <>
            <FormInstructions>
              {t("RehunurmenSatotiedot.ohjeteksti2")}
            </FormInstructions>
            <KorjuualaOsio methods={methods} />
            <FormInstructions>
              <Trans
                i18nKey={"RehunurmenSatotiedot.ohjeteksti3"}
                components={{
                  p: <p />,
                  a: <a />,
                }}
              />
            </FormInstructions>
            <KokonaissatoOsio methods={methods} />
          </>
        ) : (
          <EiKorjuitaNäkyvissäNäkymä />
        )}

        <FormActions>
          <DefaultActions
            onNext={handleNextClick}
            onPrevious={handlePreviousClick}
          />
        </FormActions>
      </FormPage>
      <MobileFooter>
        <MobileActions
          step={"7/9"}
          onNext={handleNextClick}
          onPrevious={handlePreviousClick}
        />
      </MobileFooter>
      <ConfirmationPrompt
        open={
          hasDirtyFields(methods.formState) && !methods.formState.isSubmitting
        }
      />
    </FormProvider>
  );
}
