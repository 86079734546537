import { FormActions, FormState, INIT_VALUES, SET_VALUES } from "./types";

export function initFormValues(values: Partial<FormState>): FormActions {
  return {
    type: INIT_VALUES,
    payload: values,
  };
}

export function setFormValues(values: Partial<FormState>): FormActions {
  return {
    type: SET_VALUES,
    payload: values,
  };
}
