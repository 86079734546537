import { Fields } from "../../components/common/lisatieto/lisatiedot.schema";
import { RehunurmenSatotieto } from "../../components/Forms/Sato/RehunurmenSatotiedot/rehunurmenSatotiedot.schema";
import {
  FormActions,
  FormState,
  INIT_VALUES,
  RESET_VALUES,
  SET_VALUES,
} from "./types";

// fixme: remove ts-ignores
const initialState: FormState = {
  periodId: null,
  ViljanSatotiedot: [],
  Syyskylvot: [],
  ValkuaiskasvienSatotiedot: [],
  // @ts-ignore
  RehunurmenSatotiedot: {
    Korjuu: ["_386967", "_386971", "_386975"].map(
      (n) => new RehunurmenSatotieto(n)
    ),
  },
  MuutViljelykasvitSatotiedot: [],
  PerunanSatotiedot: [],
  Lisatiedot: {
    [Fields.Lisatiedot]: "",
  },
};

export default function FormReducer(
  state = initialState,
  action: FormActions
): FormState {
  switch (action.type) {
    case SET_VALUES: {
      let step = state.step || 0;
      if (action?.payload?.step && action.payload.step > step) {
        step = action.payload.step;
      }

      return {
        ...state,
        ...action.payload,
        step,
      };
    }
    case INIT_VALUES:
      return {
        ...state,
        ...action.payload,
      };

    case RESET_VALUES:
      return initialState;

    default:
      return state;
  }
}
