import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { HehtaariField, KgField, SatotasoField } from "../../../common/Fields";
import FormSection from "../../../common/FormSection";
import FormSectionTitle from "../../../common/FormSectionTitle";
import Kasvilaji from "../../../common/Kasvilaji";
import { useSatotasoCalculator } from "../../../common/useSatotasoCalculator";
import { Fields } from "./perunanSatotiedot.schema";

const useStyles = makeStyles(() => ({
  acreField: {
    minWidth: "275px",
  },
  sectionHeader: {
    "&:first-letter": {
      textTransform: "capitalize",
    },
  },
}));

export default function Perunalaji({ field, index }: any) {
  const classes = useStyles();
  const methods = useFormContext();
  const { t } = useTranslation();

  const istutusala = methods.watch(
    `PerunanSatotiedot.${index}.${[Fields.Istutusala]}`
  );
  useEffect(() => {
    if (istutusala === 0) {
      methods.setValue(`PerunanSatotiedot.${index}.${Fields.Korjuuala}`, null);
      methods.setValue(`PerunanSatotiedot.${index}.${Fields.Satomäärä}`, null);
      methods.setValue(`PerunanSatotiedot.${index}.${Fields.Satotaso}`, null);
    }
  }, [istutusala]);

  const korjuuala = methods.watch(
    `PerunanSatotiedot.${index}.${Fields.Korjuuala}`
  );
  useEffect(() => {
    if (korjuuala === 0) {
      methods.setValue(`PerunanSatotiedot.${index}.${Fields.Satomäärä}`, null);
      methods.setValue(`PerunanSatotiedot.${index}.${Fields.Satotaso}`, null);
    }
  }, [korjuuala]);

  const { handleChangeAcres, handleChangeKilograms } = useSatotasoCalculator(
    `PerunanSatotiedot.${index}.${Fields.Satomäärä}`,
    `PerunanSatotiedot.${index}.${Fields.Korjuuala}`,
    `PerunanSatotiedot.${index}.${Fields.Satotaso}`
  );

  return (
    <FormSection key={field.Kasvilaji}>
      <FormSectionTitle>
        <Kasvilaji id={field.Kasvilaji} />
      </FormSectionTitle>
      <Grid spacing={2} container item xs={12}>
        <Grid item xs={12}>
          <Controller
            name={`PerunanSatotiedot.${index}.${Fields.Istutusala}`}
            control={methods.control}
            render={({ field: { ref, ...field } }) => (
              <HehtaariField
                tooltip={t("fields.ohjeteksti.Esitaytto")}
                inputRef={ref}
                {...field}
                className={classes.acreField}
                label={"Istutusala"}
              />
            )}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <Controller
            name={`PerunanSatotiedot.${index}.${Fields.Korjuuala}`}
            control={methods.control}
            render={({ field: { ref, ...field } }) => (
              <HehtaariField
                disabled={istutusala === 0}
                inputRef={ref}
                {...field}
                label={"Korjuuala"}
                fullWidth
                onChange={(e) => {
                  field.onChange(e);
                  handleChangeAcres(e);
                }}
              />
            )}
          />

          <Controller
            name={`PerunanSatotiedot.${index}.${Fields.Satomäärä}`}
            control={methods.control}
            render={({ field: { ref, ...field } }) => (
              <KgField
                disabled={istutusala === 0 || korjuuala === 0}
                inputRef={ref}
                {...field}
                label={"SadonKokonaismäärä"}
                fullWidth
                onChange={(e) => {
                  field.onChange(e);
                  handleChangeKilograms(e);
                }}
              />
            )}
          />

          <Controller
            name={`PerunanSatotiedot.${index}.${Fields.Satotaso}`}
            control={methods.control}
            render={({ field: { ref, ...field } }) => (
              <SatotasoField
                disabled={istutusala === 0 || korjuuala === 0}
                inputRef={ref}
                {...field}
                label={"satotaso"}
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
    </FormSection>
  );
}
