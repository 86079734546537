import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Prompt from "./Prompt";

type ConfirmationPromptProps = {
  open: boolean;
};

export default function ConfirmationPrompt({ open }: ConfirmationPromptProps) {
  const { t } = useTranslation();

  return (
    <Prompt when={open}>
      {({ isActive, onConfirm, onCancel }) => (
        <Dialog open={isActive}>
          <DialogTitle>{t("confirmdialog.title")}</DialogTitle>
          <DialogContent dividers>
            <Typography>{t("confirmdialog.content")}</Typography>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={onCancel}>
              {t("confirmdialog.back")}
            </Button>
            <Button onClick={onConfirm}>{t("confirmdialog.continue")}</Button>
          </DialogActions>
        </Dialog>
      )}
    </Prompt>
  );
}
