import * as mui from "@mui/material";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useTilatyyppi } from "../../../../store/meta/selectors";
import Checkbox from "../../../common/Checkbox";
import FormInstructions from "../../../common/FormInstructions";
import varastoTilannePvm from "./varastoTilannePvm.data";

const Ohje = () => {
  const { t } = useTranslation();

  const ttyyppi = useTilatyyppi();

  return (
    <FormInstructions>
      <mui.Typography variant="body1">
        <Trans
          i18nKey={
            ttyyppi === 2 ? "Varastotiedot.ohje.luomu" : "Varastotiedot.ohje"
          }
          components={{
            b: <b />,
          }}
          values={{
            heinakuussa: varastoTilannePvm.heinäkuu,
            tammikuussa: varastoTilannePvm.tammikuu,
          }}
        />
      </mui.Typography>
    </FormInstructions>
  );
};

/**
 * Komponentin nimestä huolimatta kenttiä on nyt vain yksi.
 *
 * Niitä kuitenkin voisi olla useampikin tässä loogisessa kokonaisuudessa.
 */
const Kentät = () => {
  const { t } = useTranslation();

  const otsake = t("fields.onkoVarastossaViljaa");

  return (
    <mui.Box pt={2} pb={2}>
      <mui.Grid container item xs={12}>
        <Checkbox label={otsake} name={"Varastotiedot.varastossaOnViljaa"} />
      </mui.Grid>
    </mui.Box>
  );
};

const OnkoVarastoaKentät = () => {
  return (
    <mui.Box pt={2}>
      <Ohje />
    </mui.Box>
  );
};

export default OnkoVarastoaKentät;
