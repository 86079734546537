import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  box: {
    [theme.breakpoints.up("md")]: {
      overflow: "initial",
      paddingRight: theme.spacing(8),
    },
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    height: "100%",
    overflow: "scroll",
    position: "relative",
    boxSizing: "border-box",
    backgroundColor: "#fff",
  },
}));

export default function FormContent({
  children,
}: React.PropsWithChildren<any>) {
  const classes = useStyles();

  return <Box className={classes.box}>{children}</Box>;
}
