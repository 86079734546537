import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import useBreakpoints from "../../../breakpoints";
import paths, { RouteDef } from "../../../paths";
import { useTilatyyppi } from "../../../store/meta/selectors";
import { formInquiryPath } from "../../../utils";
import { Footer, Header, StepperNav, useQuery } from "../../common";
import CenteredCircularProgress from "../../common/CenteredCircularProgress";
import KysymyssivuErrorBoundary from "../../common/error-boundaries/KysymyssivuErrorBoundary";
import KysymyssivuaEiLoytynyt from "../../common/error-views/KysymyssivuaEiLoytynyt";
import KyselyOtsake from "../../common/KyselyOtsake";
import useFormData from "../../common/useFormData";
import Vastauskoodit from "../../common/Vastauskoodit";
import Aloitus from "../Aloitus";
import Kiitos from "./Lisatiedot/Kiitos";
import Lisätiedot from "./Lisatiedot/Lisatiedot";
import MuutViljelyKasvit from "./MuutViljelykasvit/MuutViljelykasvit";
import PerunanSatotiedot from "./PerunanSatotiedot/PerunanSatotiedot";
import RehunurmenSatotiedot from "./RehunurmenSatotiedot/RehunurmenSatotiedot";
import Syyskylvot from "./Syyskylvot/Syyskylvot";
import ValkuaiskasvienSatotiedot from "./ValkuaiskasvienSatotiedot/ValkuaiskasvienSatotiedot";
import Varastotiedot from "./Varastotiedot/Varastotiedot";
import ViljanSatotiedot from "./ViljanSatotiedot/ViljanSatotiedot";
import Yhteenveto from "./Yhteenveto/Yhteenveto";

/**
 * Form routes along with their translation key are listed here.
 */
export const nav: RouteDef[] = [
  {
    href: paths.viljansatotiedot,
    text: "ViljanSatotiedot.title",
  },
  {
    href: paths.viljanvarastot,
    text: "Varastotiedot.title",
  },
  {
    href: paths.valkuaiskasviensatotiedot,
    text: "ValkuaiskasvienSatotiedot.title",
  },
  {
    href: paths.muidenviljelykasviensatotiedot,
    text: "muutViljelyKasvit.title",
  },
  {
    href: paths.perunansatotiedot,
    text: "perunanSato.title",
  },
  {
    href: paths.syyskylvot,
    text: "syyskylvot.title",
  },
  {
    href: paths.rehunurmensatotiedot,
    text: "RehunurmenSatotiedot.title",
  },
  {
    href: paths.lisatiedot,
    text: "Lisätiedot.title",
  },
  {
    href: paths.yhteenveto,
    text: "yhteenveto.title",
  },
];

export default function FormRouter() {
  const { t } = useTranslation();
  const breakpoints = useBreakpoints();
  const query = useQuery();
  const id = query.get("id") as string;
  const ttyyppi = useTilatyyppi();

  /**
   * Load form values from backend and populate store before continuing to form pages
   */
  const { loading } = useFormData(id);

  return (
    <>
      <Header isLoggedIn />
      <KyselyOtsake
        title={`${
          ttyyppi === 2
            ? t("common.satotilastokysely.luomu")
            : t("common.satotilastokysely")
        } ${new Date().getFullYear()}`}
      />

      {loading ? (
        <CenteredCircularProgress />
      ) : (
        <div className={breakpoints.layout}>
          <StepperNav routes={nav} formId={id} />
          <KysymyssivuErrorBoundary>
            <Box className={breakpoints.routes}>
              <Routes>
                <Route path="*" element={<KysymyssivuaEiLoytynyt />} />
                <Route
                  path={paths.vastauskoodit}
                  element={
                    <Vastauskoodit
                      nextPath={formInquiryPath(paths.viljansatotiedot)}
                    />
                  }
                />
                <Route
                  path={paths.viljansatotiedot}
                  element={<ViljanSatotiedot />}
                />
                <Route
                  path={paths.viljanvarastot}
                  element={<Varastotiedot />}
                />

                <Route
                  path={paths.rehunurmensatotiedot}
                  element={<RehunurmenSatotiedot />}
                />
                <Route
                  path={paths.perunansatotiedot}
                  element={<PerunanSatotiedot />}
                />
                <Route path={paths.aloitus} element={<Aloitus />} />
                <Route path={paths.kiitos} element={<Kiitos />} />
                <Route path={paths.yhteenveto} element={<Yhteenveto />} />

                <Route
                  path={paths.muidenviljelykasviensatotiedot}
                  element={<MuutViljelyKasvit />}
                />
                <Route path={paths.lisatiedot} element={<Lisätiedot />} />
                <Route path={paths.syyskylvot} element={<Syyskylvot />} />
                <Route
                  path={paths.valkuaiskasviensatotiedot}
                  element={<ValkuaiskasvienSatotiedot />}
                />
              </Routes>
            </Box>
          </KysymyssivuErrorBoundary>
        </div>
      )}

      <Footer />
    </>
  );
}
