import {
  Checkbox as MuiCheckbox,
  CheckboxProps,
  FormControlLabel,
} from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

export default function Checkbox(
  props: CheckboxProps & { label?: string | JSX.Element }
) {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      sx={{ color: props.disabled ? "rgba(0, 0, 0, 0.3)" : "inherit" }}
      label={props.label ?? ""}
      control={
        <Controller
          name={props.name as string}
          control={control}
          render={({ field }) => (
            <MuiCheckbox
              sx={{
                "&.Mui-disabled": {
                  color: "rgba(0, 0, 0, 0.3);",
                },
              }}
              disabled={props.disabled}
              color="primary"
              {...field}
              checked={!!field.value}
              onChange={(event) => {
                field.onChange({
                  target: {
                    name: name,
                    value: event.target.checked ? 1 : 0,
                  },
                });
              }}
            />
          )}
        />
      }
    />
  );
}
