import React from "react";
import { Navigate } from "react-router-dom";
import useTokenState from "./useTokenState";

const GuardedRoute = ({ children }: any) => {
  const auth = useTokenState();

  // Bypass guard in development env.
  if (!auth && process.env.NODE_ENV !== "development")
    return <Navigate to="/" />;

  return <>{children}</>;
};

export default GuardedRoute;
