import { Box, Grid, Paper } from "@mui/material";
import React from "react";

export default function FormSection({
  children,
  ...rest
}: React.PropsWithChildren<any>) {
  return (
    <Box sx={{}} component={Paper} p={2} pb={4} mb={2} {...rest}>
      <Grid container>{children}</Grid>
    </Box>
  );
}
