import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import RouterErrorBoundary from "./common/error-boundaries/RouterErrorBoundary";
import SivuaEiLoytynytNakyma from "./common/error-views/SivuaEiLoytynyt";
import GuardedRoute from "./common/GuardedRoute";
import ScrollReset from "./common/ScrollReset";
import TokenExpirationPrompt from "./common/TokenExpirationPrompt";
import useTokenCookieParser from "./common/useTokenCookieParser";
import Aloitus from "./Forms/Aloitus";
import PerunaRouter from "./Forms/Peruna/PerunaRouter";
import SatoRouter from "./Forms/Sato/SatoRouter";
import Accessibility from "./Public/Accessibility";
import Kirjaudu from "./Public/Kirjaudu";
import RedirectsEditors from "./Public/RedirectsEditors";
import TunnistautuminenEpaonnistui from "./Public/TunnistautuminenEpaonnistui";
import Uloskirjauduttu from "./Public/Uloskirjauduttu";

export default function Router() {
  useTokenCookieParser();

  return (
    <BrowserRouter>
      <ScrollReset>
        <RouterErrorBoundary>
          <Routes>
            <Route path="*" element={<SivuaEiLoytynytNakyma />} />
            <Route path="/" element={<Kirjaudu />} />
            <Route path="/aloitus" element={<Aloitus />} />
            <Route
              path="/tunnistautuminen-epaonnistui"
              element={<TunnistautuminenEpaonnistui />}
            ></Route>
            <Route
              path="sato/*"
              element={
                <GuardedRoute>
                  <SatoRouter />
                </GuardedRoute>
              }
            />
            <Route
              path="peruna/*"
              element={
                <GuardedRoute>
                  <PerunaRouter />
                </GuardedRoute>
              }
            />

            <Route path="uloskirjauduttu" element={<Uloskirjauduttu />} />
            <Route path="saavutettavuus" element={<Accessibility />} />
            <Route path="editor" element={<RedirectsEditors />} />
          </Routes>
        </RouterErrorBoundary>
      </ScrollReset>
      <TokenExpirationPrompt />
    </BrowserRouter>
  );
}
