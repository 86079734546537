import { yupResolver } from "@hookform/resolvers/yup";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setFormValues } from "../../../../store/form/actions";
import { useTilatyyppi } from "../../../../store/meta/selectors";
import { Kasvi } from "../../../../store/meta/types";
import RootState from "../../../../store/types";
import { FormActions } from "../../../common";
import DefaultActions from "../../../common/DefaultActions";
import FormInstructions from "../../../common/FormInstructions";
import FormPage from "../../../common/FormPage";
import FormPageTitle from "../../../common/FormPageTitle";
import FormSection from "../../../common/FormSection";
import { hasDirtyFields } from "../../../common/FormUtils";
import MobileActions from "../../../common/MobileActions";
import MobileFooter from "../../../common/MobileFooter";
import NoPresetsForPage from "../../../common/NoPresetsForPage";
import ConfirmationPrompt from "../../../common/Prompt/ConfirmationPrompt";
import useFocusFirstField from "../../../common/useFocus";
import useNavigateWithSearch from "../../../common/useNavigateWithSearch";
import MuuKasvi from "./MuuKasvi";
import schema, { MuutViljelykasvitForm } from "./muutViljelykasvit.schema";

const useStyles = makeStyles(() => ({
  sectionHeader: {
    display: "flex",
    alignItems: "flex-end",
  },
  title: {
    "&:first-letter": {
      textTransform: "capitalize",
    },
  },
}));

export default function MuutViljelyKasvit() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const muutkasvit = useSelector(({ meta }: RootState) => meta.muutkasvit);
  const MuutViljelykasvitSatotiedot = useSelector(
    ({ form }: RootState) => form.MuutViljelykasvitSatotiedot
  );
  const navigate = useNavigateWithSearch();
  const { t } = useTranslation();
  const ttyyppi = useTilatyyppi();

  const methods = useForm<MuutViljelykasvitForm>({
    shouldFocusError: true,
    defaultValues: {
      MuutViljelykasvitSatotiedot: MuutViljelykasvitSatotiedot.sort((a, b) => {
        const cropA = muutkasvit.find((row) => row.id === a.Kasvilaji) as Kasvi;
        const cropB = muutkasvit.find((row) => row.id === b.Kasvilaji) as Kasvi;

        if (!cropA || !cropB) {
          return -1;
        }

        return cropA.order - cropB.order;
      }),
    },
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });

  const { fields } = useFieldArray({
    control: methods.control,
    name: "MuutViljelykasvitSatotiedot",
  });

  const handleNextClick = (values: Partial<MuutViljelykasvitForm>) => {
    dispatch(setFormValues({ ...values, step: 4 }));
    navigate("/sato/perunan-satotiedot");
  };
  const handlePreviousClick = (values: Partial<MuutViljelykasvitForm>) => {
    dispatch(setFormValues(values));
    navigate("/sato/valkuaiskasvien-satotiedot");
  };

  useFocusFirstField();

  return (
    <FormProvider {...methods}>
      <FormPage>
        <FormPageTitle>4. {t("muutViljelyKasvit.title")}</FormPageTitle>
        {MuutViljelykasvitSatotiedot?.length === 0 ? (
          <NoPresetsForPage />
        ) : (
          <>
            <FormInstructions>
              {ttyyppi === 2
                ? t("muutViljelyKasvit.ohjeteksti.tayttoohje.luomu")
                : t("muutViljelyKasvit.ohjeteksti.tayttoohje")}
            </FormInstructions>

            {fields.map((field, index) => (
              <FormSection key={field.Kasvilaji}>
                <MuuKasvi key={index} field={field} index={index} />
              </FormSection>
            ))}
          </>
        )}

        <FormActions>
          <DefaultActions
            onNext={handleNextClick}
            onPrevious={handlePreviousClick}
          />
        </FormActions>
      </FormPage>
      <MobileFooter>
        <MobileActions
          step={"4/9"}
          onNext={handleNextClick}
          onPrevious={handlePreviousClick}
        />
      </MobileFooter>
      <ConfirmationPrompt
        open={
          hasDirtyFields(methods.formState) && !methods.formState.isSubmitting
        }
      />
    </FormProvider>
  );
}
