import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { arrayHasValue, kaikkiKorjuukertakentatNollia, jokainenKorjuukertaKenttaTyhja } from "./common";
import {
    KgField, ProsenttiField, SatotasoField, HehtaariField,
} from "../../../common/Fields";
import { useSatotasoCalculator } from "../../../common/useSatotasoCalculator";
import makeStyles from "@mui/styles/makeStyles";
import {
    Fields,
    RehunurmenSatotieto
} from "./rehunurmenSatotiedot.schema";

interface IEsikuivattuSailorehuOsio {
    methods: UseFormReturn<any, any>
}

interface IEsikuivattuSailorehuHaYhteensa {
    methods: UseFormReturn<any, any>,
    fields: RehunurmenSatotieto[]
}

const useStyles = makeStyles(() => ({
    field: {},
}));

const sailorehuEsikuivattuHaYhteensa = ({ methods, fields }: IEsikuivattuSailorehuHaYhteensa) => {
    const esikuivattusailorehuFieldNames = fields.map(
        (_, index) =>
            `RehunurmenSatotiedot.Korjuu.${index}.${Fields.SäilörehuEsikuivattu}`
    );
    const esikuivattusailorehuFieldValues: number[] = methods.watch(
        esikuivattusailorehuFieldNames as any
    );

    const summa = esikuivattusailorehuFieldValues.reduce((acc, val) => {
        return acc + (val || 0);
    }, 0);

    useEffect(() => {
        methods.setValue(
            `RehunurmenSatotiedot.${Fields.SailorehuEsikuivattuKorjuuAlaKaikkiKorjuukerratYhteensa}`,
            summa
        );
    }, [summa])

    return summa;
}

const useSummaryValues = (
    fields: RehunurmenSatotieto[],
    methods: UseFormReturn<any, any>,
): {
    esikuivattuaSailorehuaKorjattu: boolean;
    sailorehuEsikuivattuKaikkiKorjuukerratNollia: boolean;
    sailorehuEsikuivattuKaikkiKorjuukerratTyhjia: boolean;
    sailorehuEsikuivattuKorjuukerratHaYhteensa: number
} => {
    const esikuivattusailorehuFieldNames = fields.map(
        (_, index) =>
            `RehunurmenSatotiedot.Korjuu.${index}.${Fields.SäilörehuEsikuivattu}`
    );
    const esikuivattusailorehuFieldValues = methods.watch(
        esikuivattusailorehuFieldNames as any
    );
    const esikuivattuaSailorehuaKorjattu = arrayHasValue(esikuivattusailorehuFieldValues);

    const sailorehuEsikuivattuKaikkiKorjuukerratNollia = kaikkiKorjuukertakentatNollia(esikuivattusailorehuFieldValues);

    const sailorehuEsikuivattuKaikkiKorjuukerratTyhjia = jokainenKorjuukertaKenttaTyhja(esikuivattusailorehuFieldValues);

    const sailorehuEsikuivattuKorjuukerratHaYhteensa = sailorehuEsikuivattuHaYhteensa({ methods, fields });

    return {
        esikuivattuaSailorehuaKorjattu,
        sailorehuEsikuivattuKaikkiKorjuukerratNollia,
        sailorehuEsikuivattuKaikkiKorjuukerratTyhjia,
        sailorehuEsikuivattuKorjuukerratHaYhteensa
    };
};

const EsikuivattuSailorehuOsio = ({ methods }: IEsikuivattuSailorehuOsio) => {
    const { t } = useTranslation();
    const classes = useStyles();


    const {
        esikuivattuaSailorehuaKorjattu,
        sailorehuEsikuivattuKaikkiKorjuukerratNollia,
        sailorehuEsikuivattuKaikkiKorjuukerratTyhjia,
        sailorehuEsikuivattuKorjuukerratHaYhteensa
    } = useSummaryValues(
        methods.getValues("RehunurmenSatotiedot.Korjuu"),
        methods
    );

    const { handleChangeAcres, handleChangeKilograms } =
        useSatotasoCalculator(
            `RehunurmenSatotiedot.${Fields.SäilörehuEsikuivattuYhteensäKg}`,
            `RehunurmenSatotiedot.${Fields.SailorehuEsikuivattuKorjuuAlaKaikkiKorjuukerratYhteensa}`,
            `RehunurmenSatotiedot.${Fields.SatotasoSailorehuEsikuivattuYhteensa}`
        );

    useEffect(() => {
        if (sailorehuEsikuivattuKaikkiKorjuukerratNollia || sailorehuEsikuivattuKaikkiKorjuukerratTyhjia) {
            methods.clearErrors(`RehunurmenSatotiedot.${Fields.SäilörehuEsikuivattuYhteensäKg}`);
        }
        if (esikuivattuaSailorehuaKorjattu === false) {
            methods.setValue(
                `RehunurmenSatotiedot.${Fields.SäilörehuEsikuivattuYhteensäKg}`,
                null
            );
            methods.setValue(
                `RehunurmenSatotiedot.${Fields.SäilörehuEsikuivattuKuivaaineProsentti}`,
                null
            );
        }
    }, [esikuivattuaSailorehuaKorjattu]);

    return (
        <>
            <Typography sx={{ fontWeight: "bold" }} pt={4}>
                {t("RehunurmenSatotiedot.EsikuivattuSäilörehun")}
            </Typography>
            <Controller
                name={`RehunurmenSatotiedot.${Fields.SailorehuEsikuivattuKorjuuAlaKaikkiKorjuukerratYhteensa}`}
                control={methods.control}
                render={({ field: { ref, ...field } }) => (
                    <HehtaariField
                        inputRef={ref}
                        {...field}
                        disabled
                        onChange={(e) => {
                            field.onChange(e);
                            handleChangeAcres(e);
                        }}
                        fullWidth
                        className={classes.field}
                        label={"KorjattuAlaYhteensä"}
                    />
                )}
            />

            <Controller
                name={`RehunurmenSatotiedot.${Fields.SäilörehuEsikuivattuYhteensäKg}`}
                control={methods.control}
                render={({ field: { ref, ...field } }) => (
                    <KgField
                        inputRef={ref}
                        {...field}
                        label={"SäilörehuEsikuivattu"}
                        fullWidth
                        onChange={(e) => {
                            field.onChange(e);
                            handleChangeKilograms(e);
                        }}
                        disabled={!esikuivattuaSailorehuaKorjattu}
                    />
                )}
            />

            <Controller
                name={`RehunurmenSatotiedot.${Fields.SatotasoSailorehuEsikuivattuYhteensa}`}
                control={methods.control}
                render={({ field: { ref, ...field } }) => (
                    <SatotasoField
                        inputRef={ref}
                        {...field}
                        label={"satotaso"}
                        fullWidth
                        disabled={true}
                    />
                )}
            />

            <Controller
                name={`RehunurmenSatotiedot.${Fields.SäilörehuEsikuivattuKuivaaineProsentti}`}
                control={methods.control}
                render={({ field: { ref, ...field } }) => (
                    <ProsenttiField
                        inputRef={ref}
                        {...field}
                        label={"SäilörehuEsikuivattuKuivaaineProsentti"}
                        fullWidth
                        disabled={!esikuivattuaSailorehuaKorjattu}
                    />
                )}
            />
        </>
    )
}
export default EsikuivattuSailorehuOsio;
