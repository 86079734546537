import { Box, CircularProgress } from "@mui/material";
import React from "react";

const CenteredCircularProgress = () => (
  <Box
    sx={{
      display: "flex",
      height: "100%",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <CircularProgress />
  </Box>
);

export default CenteredCircularProgress;
