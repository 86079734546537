import axios from "axios";
import { useState } from "react";
import { useAsync } from "react-async-hook";

export interface Inquiry {
  key: string;
  name: string;
  open_from: Date;
  open_until: Date;
  period_id: string;
  status: number;
}

export interface InquiryData {
  inquiries: Inquiry[];
  ttyyppi: number;
}

export default function useInquiries() {
  const [inquiries, setInquiries] = useState<InquiryData>({ inquiries: [], ttyyppi: 0 });

  const endpoint = process.env.REACT_APP_DATA_FUNCTION_URL + "/GetInquiries";

  const { loading } = useAsync(async () => {
    const res = await axios.get(endpoint);
    setInquiries(res.data);
  }, []);

  return {
    inquiries,
    loading,
  };
}
