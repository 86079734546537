import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setFormValues } from "../../../../store/form/actions";
import { useTilatyyppi } from "../../../../store/meta/selectors";
import { FormActions } from "../../../common";
import DefaultActions from "../../../common/DefaultActions";
import { HehtaariField } from "../../../common/Fields";
import FormInstructions from "../../../common/FormInstructions";
import FormPage from "../../../common/FormPage";
import FormPageTitle from "../../../common/FormPageTitle";
import FormSection from "../../../common/FormSection";
import { hasDirtyFields } from "../../../common/FormUtils";
import Kasvilaji from "../../../common/Kasvilaji";
import MobileActions from "../../../common/MobileActions";
import MobileFooter from "../../../common/MobileFooter";
import ConfirmationPrompt from "../../../common/Prompt/ConfirmationPrompt";
import useFocusFirstField from "../../../common/useFocus";
import useNavigateWithSearch from "../../../common/useNavigateWithSearch";
import schema, {
  Fields,
  SyyskylvötForm,
  useSyyskylvoValues,
} from "./syyskylvot.schema";

const useStyles = makeStyles(() => ({
  acreField: {
    minWidth: "275px",
  },
  sectionHeader: {
    display: "flex",
    alignItems: "flex-end",
  },
}));

export default function Syyskylvöt() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const values = useSyyskylvoValues();
  const navigate = useNavigateWithSearch();
  const { t } = useTranslation();
  const ttyyppi = useTilatyyppi();

  const methods = useForm<SyyskylvötForm>({
    shouldFocusError: true,
    defaultValues: {
      Syyskylvot: values,
    },
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });

  const { fields } = useFieldArray({
    control: methods.control,
    name: "Syyskylvot",
  });

  const handlePreviousClick = (values: Partial<SyyskylvötForm>) => {
    navigate("/sato/perunan-satotiedot");
  };
  const handleNextClick = (values: Partial<SyyskylvötForm>) => {
    dispatch(setFormValues({ ...values, step: 6 }));
    navigate("/sato/rehunurmen-satotiedot");
  };

  useFocusFirstField();

  return (
    <FormProvider {...methods}>
      <FormPage>
        <FormPageTitle>6. {t("syyskylvot.title")}</FormPageTitle>
        <FormInstructions>
          <Typography variant="body1">
            {ttyyppi === 2
              ? t("Syyskylvot.ohjeteksti.tayttoohje.luomu")
              : t("Syyskylvot.ohjeteksti.tayttoohje")}
          </Typography>
        </FormInstructions>
        <FormSection>
          <Grid container>
            {fields.map((field, index) => (
              <Grid
                key={field.Kasvilaji}
                container
                item
                xs={12}
                columnSpacing={2}
              >
                <Grid item xs={12} md={4} className={classes.sectionHeader}>
                  <Typography
                    sx={{
                      "&:first-letter": {
                        textTransform: "capitalize",
                      },
                    }}
                  >
                    <Kasvilaji id={field.Kasvilaji} />
                    {index === 0 &&
                      " " + t("syyskylvot.syysvehna.spelttivehna")}
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Controller
                    name={`Syyskylvot.${index}.${Fields.Kylvöala}`}
                    control={methods.control}
                    render={({ field: { ref, ...field } }) => (
                      <HehtaariField
                        autoFocus
                        inputRef={ref}
                        {...field}
                        label={"Kylvoala"}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Controller
                    name={`Syyskylvot.${index}.${Fields.Suorakylvöala}`}
                    control={methods.control}
                    render={({ field: { ref, ...field } }) => (
                      <HehtaariField
                        inputRef={ref}
                        tooltip={t("syyskylvot.ohjeteksti.SuorakylvoalaOhje")}
                        {...field}
                        fullWidth
                        label={"Suorakylvoala"}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </FormSection>
        <FormActions>
          <DefaultActions
            onNext={handleNextClick}
            onPrevious={handlePreviousClick}
          />
        </FormActions>
      </FormPage>
      <MobileFooter>
        <MobileActions
          step={"6/9"}
          onNext={handleNextClick}
          onPrevious={handlePreviousClick}
        />
      </MobileFooter>
      <ConfirmationPrompt
        open={
          hasDirtyFields(methods.formState) && !methods.formState.isSubmitting
        }
      />
    </FormProvider>
  );
}
