import { Box, Grid, GridSpacing, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import potatoPic from "../../assets/PerunaSato.jpg";
import wheatPic from "../../assets/VehnaSato.jpg";
import { useTilatyyppi } from "../../store/meta/selectors";
import { Footer } from "../common";
import Action from "../common/Action";
import CenteredCircularProgress from "../common/CenteredCircularProgress";
import Header from "../common/Header";
import Title from "../common/Title";
import useInquiries from "../common/useInquiries";
import useTokenUserInfo from "../common/useTokenUserInfo";

const useStyles = makeStyles(() => ({
  box: {
    height: "100%",
  },
}));

export enum StatusCode {
  Aloittamaton = 0,
  Kesken = 1,
  Lähetetty = 2,
}

interface IActionStatusProps {
  status: number;
}

export function ActionStatus({ status }: IActionStatusProps): ReactElement {
  const { t } = useTranslation();

  let formStatus: string;
  let subtitleColor: string;

  switch (status) {
    case StatusCode.Aloittamaton:
      formStatus = t("status.aloittamaton");
      subtitleColor = "textSecondary";
      break;
    case StatusCode.Kesken:
      formStatus = t("status.kesken");
      subtitleColor = "#c7bd06";
      break;
    case StatusCode.Lähetetty:
      formStatus = t("status.lähetetty");
      subtitleColor = "#0f800b";
      break;
    default:
      formStatus = t("status.aloittamaton");
      subtitleColor = "#c70c22";
  }

  return (
    <Typography variant="body1" color={subtitleColor} component="p">
      {formStatus}
    </Typography>
  );
}

export default function Aloitus() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { inquiries, loading } = useInquiries();
  const ttyyppi = useTilatyyppi();

  const user = useTokenUserInfo();

  if (loading)
    return (
      <>
        <Header isLoggedIn />
        <Title></Title>
        <Box className={classes.box}>
          <CenteredCircularProgress />
        </Box>

        <Footer />
      </>
    );

  return (
    <>
      <Header isLoggedIn />
      <Title>
        <Typography variant="h1">
          {t("common.hello")}, {user?.gn}!
        </Typography>
        {inquiries && inquiries.ttyyppi === 1 ? (
          <Box mt={2}>
            <Typography variant="body1">
              {t("peruna.description1.1")}
            </Typography>
            <Typography variant="body1">
              {t("peruna.description1.2")}
            </Typography>
            <br />
            <Typography sx={{ fontWeight: "bold" }} variant="body1">
              {t("sato.luovutus")}
            </Typography>
            <br />
          </Box>
        ) : (
          <Box mt={2}>
            <Typography variant="body1">{t("sato.description1.1")}</Typography>
            <Typography variant="body1">
              {t("sato.description1.1.2")}
            </Typography>
            <br />
            <Typography variant="body1">{t("sato.description1.2")}</Typography>
            <Typography variant="body1">
              {t("sato.description1.2.1")}
            </Typography>
            <br />
            <Typography sx={{ fontWeight: "bold" }} variant="body1">
              {t("sato.luovutus")}
            </Typography>
          </Box>
        )}
      </Title>
      <Box className={classes.box}>
        <Actions>
          {loading && <CenteredCircularProgress />}
          {!loading &&
            inquiries.inquiries.map((row) => (
              <Grid key={row.key} item xs={12} sm={6} md={4} lg={3}>
                {row.key === "sato" && (
                  <Action
                    title={`${
                      ttyyppi === 2
                        ? t("common.satotilastokysely.luomu")
                        : t("common.satotilastokysely")
                    } ${new Date().getFullYear()}`}
                    subtitle={<ActionStatus status={row.status} />}
                    href={`/sato/viljan-satotiedot?id=${row.period_id}`}
                    image={wheatPic}
                  />
                )}
                {row.key === "perunan-sato-ja-varastot" && (
                  <Action
                    title={t("common.peruna")}
                    subtitle={<ActionStatus status={row.status} />}
                    href={`/peruna/sato-ja-varastot?id=${row.period_id}`}
                    image={potatoPic}
                  />
                )}
              </Grid>
            ))}
        </Actions>
      </Box>

      <Footer />
    </>
  );
}

type ActionsProps = {
  p?: number;
  spacing?: GridSpacing;
};

function Actions({
  children,
  p,
  spacing,
}: React.PropsWithChildren<ActionsProps>) {
  return (
    <Box
      p={p || 8}
      sx={{
        position: "relative",
        right: 0,
        left: 0,
        marginTop: "-95px",
      }}
    >
      <Grid container spacing={spacing || 4}>
        {children}
      </Grid>
    </Box>
  );
}
