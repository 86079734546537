export const SET_META = "SET_META";

export interface SetMetaAction {
  type: typeof SET_META;
  payload?: {};
}

export type Kasvi = {
  id: string;
  order: number;
  fi?: string;
  sv?: string;
};

export interface Viljakasvi extends Kasvi {
  eiTuoresadonKokonaismäärää?: boolean;
  eiVarastoarvoja?: boolean;
  eiSadonkorjuuvalintaa?: boolean;
  syyskylvo?: boolean;
}

export interface Valkuaiskasvi extends Kasvi {
  sailorehu?: boolean;
  syyskylvo?: boolean;
}

export interface MetaState {
  viljakasvit: Viljakasvi[];
  valkuaiskasvit: Valkuaiskasvi[];
  muutkasvit: Kasvi[];
  öljykasvit: Kasvi[];
  perunakasvit: Kasvi[];
  ttyyppi?: number;
}

export type MetaActions = SetMetaAction;
