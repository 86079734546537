import { Box, styled, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FieldMap } from "./Fields";
import Kasvilaji from "./Kasvilaji";

/**
 * TODO: Fix the `SummaryData` type, as it is horribly broken.
 *
 * Problems:
 *
 * * Many places just give `SummaryData` as an array, and cast it first to `any`.
 *   This shouldn't be possible per the type definition, but as the top level keys
 *   are only used as `key` props, they can be anything.
 * * `SummaryRow` takes rows as `any`, so their definition here has no meaning.
 * *
 */
export type SummaryData = {
  [key: string]: number | { [key: string]: number };
}[];

export const SummaryBox = styled(Box)`
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
`;

export const SummaryHeader = ({ children }: React.PropsWithChildren<any>) => {
  return <Typography variant="h3">{children}</Typography>;
};

export const SummaryRowHeader = ({
  children,
}: React.PropsWithChildren<any>) => {
  return (
    <Box mb={1}>
      <Typography
        sx={{ textTransform: "capitalize", textDecoration: "underline" }}
      >
        {children}
      </Typography>
    </Box>
  );
};

export const SummarySubheader = ({
  children,
}: React.PropsWithChildren<any>) => {
  return <Typography sx={{ fontWeight: "bold" }}>{children}</Typography>;
};

export const SummaryValueRow = styled(Box)`
  display: flex;
  justify-content: space-between;
  max-width: 332px;
`;

export const SummaryNestedValueRow = styled(Box)`
  display: flex;
  justify-content: space-between;
  max-width: 308px;
`;

export type SummaryProps = {
  title: string;
  idAttribute?: string;
  data: SummaryData;
  CustomSummaryRowHeader?: React.ComponentType<{ id: string }>;
};

export const Summary = ({
  title,
  idAttribute = "Kasvilaji",
  data,
  CustomSummaryRowHeader,
}: SummaryProps) => {
  if (!data || !data.length) return null;

  return (
    <SummaryBox p={2} mt={2} mb={2}>
      <SummaryHeader>{title}</SummaryHeader>
      {data.map((row, index) => (
        <SummaryRow
          Header={CustomSummaryRowHeader}
          key={index}
          data={row}
          idAttribute={idAttribute}
        />
      ))}
    </SummaryBox>
  );
};

export interface ISummaryRowProps {
  idAttribute: string;
  data: any;
  Header?: React.ComponentType<{ id: string }>;
}

export function SummaryRow({ data, idAttribute, Header }: ISummaryRowProps) {
  const { t, i18n } = useTranslation();
  const [summaryValueRows, setSummaryValueRows] = useState<any[]>([]);

  /**
   * This useEffect hook appears, based on very superficial reading, to do memoization of the
   * rendered jsx based on changes to `data`.
   *
   * As it is, it seems to work just fine for now. No need to change it at the moment.
   *
   * If it would ever need changing, consider either:
   *
   * 1. converting it to a new subcomponent that is memoized with `React.memo`. This would reduce its
   *    complexity drastically.
   * 2. Possibly dropping the memoization, and just rendering it plain inside a map.
   *
   * There is a slight change that it might be doing something more than what `React.memo` would do.
   * Because of this, if you think of changing it, read through it first, and make sure it isn't actually
   * doing anything else.
   */
  useEffect(() => {
    let els: any[] = [];

    Object.keys(data).forEach((value: string) => {
      if (value === idAttribute) return;

      if (data[value] === null) return;

      if (typeof data[value] === "object") {
        {
          els.push(
            <Box pl={4} mb={1} mt={1} key={value}>
              <SummarySubheader>{t(value)}</SummarySubheader>

              {Object.keys(data[value]).map((nestedKey: string) => (
                <Typography key={nestedKey}>
                  <SummaryNestedValueRow pl={3}>
                    <span>
                      {FieldMap[nestedKey]
                        ? t(FieldMap[nestedKey].label)
                        : nestedKey}
                    </span>
                    <span>
                      {data[value][nestedKey]}
                      {"  "}
                      {FieldMap[nestedKey] ? t(FieldMap[nestedKey].unit) : ""}
                    </span>
                  </SummaryNestedValueRow>
                </Typography>
              ))}
            </Box>
          );
        }
      } else
        els.push(
          <Typography key={value}>
            <SummaryValueRow pl={4}>
              <span>
                {FieldMap[value] ? t(FieldMap[value].label) : value}
                {"  "}
              </span>
              <span>
                {data[value]} {FieldMap[value] ? t(FieldMap[value].unit) : ""}
              </span>
            </SummaryValueRow>
          </Typography>
        );
    });

    setSummaryValueRows(els);
  }, [data, i18n.language]);

  return (
    <Typography>
      <Box pl={2} pt={1} pb={1}>
        <SummaryRowHeader>
          {Header ? (
            <Header id={data[idAttribute]} />
          ) : (
            <Kasvilaji id={data[idAttribute]} />
          )}
        </SummaryRowHeader>
      </Box>
      {summaryValueRows}
    </Typography>
  );
}
