import { Grid } from "@mui/material";
import _ from "lodash";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormSection from "../../../common/FormSection";
import FormSectionTitle from "../../../common/FormSectionTitle";
import Kuivaheina from "./Kuivaheina";
import Sailorehu from "./Sailorehu";
import EsikuivattuSailorehu from "./EsikuivattuSailorehu";
import Niittorehu from "./Niittorehu";

interface IKokonaissatoOsio {
  methods: UseFormReturn<any, any>
}


const KokonaissatoOsio = ({ methods }: IKokonaissatoOsio) => {
  const { t } = useTranslation();

  return (

    <FormSection>
      <FormSectionTitle>
        {t("RehunurmenSatotiedot.otsikko.Kokonaissato")}
      </FormSectionTitle>
      <Grid container spacing={0}>
        <Grid item md={6} xs={12}>
          <Kuivaheina methods={methods} />
          <Sailorehu methods={methods} />
          <EsikuivattuSailorehu methods={methods} />
          <Niittorehu methods={methods} />
        </Grid>
      </Grid>
    </FormSection>
  );
};

export default KokonaissatoOsio;
