import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormActions } from "../../../common";
import { FinishButton, PrevButton } from "../../../common/Buttons";
import FormInstructions from "../../../common/FormInstructions";
import FormPage from "../../../common/FormPage";
import FormPageTitle from "../../../common/FormPageTitle";
import MobileFooter from "../../../common/MobileFooter";
import useLogout from "../../../common/useLogout";
import useNavigateWithSearch from "../../../common/useNavigateWithSearch";
import { PrintButton, SatoSummaries } from "../Yhteenveto/Yhteenveto";

const useStyles = makeStyles((theme) => ({
  lukeLink: {},
}));

export default function Kiitos() {
  const classes = useStyles();
  const navigate = useNavigateWithSearch();
  const logout = useLogout();

  const { t } = useTranslation();

  const handleDownloadClick = () => {
    window.print();
  };

  return (
    <>
      <FormPage>
        <FormPageTitle
          sx={{
            ["@media print"]: {
              display: "none",
            },
          }}
        >
          {t("common.kiitos")}
        </FormPageTitle>
        <FormInstructions>
          <p>{t("common.kiitosTekstiSato1.1")}</p>
          <p>{t("common.kiitosTekstiSato1.2")}</p>
          <p>
            {t("common.kiitosTilastoteksti")}{" "}
            <a
              className={classes.lukeLink}
              target="_blank"
              rel="noreferrer noopener"
              href={t("common.kiitosTilastolinkki")}
            >
              {t("common.kiitos.linkiteksti")}
            </a>
          </p>
        </FormInstructions>
        <Box
          sx={{
            display: "none",
            ["@media print"]: {
              display: "block",
            },
          }}
        >
          <SatoSummaries />
        </Box>
        <FormActions>
          <PrevButton
            text={t("common.uusilomake")}
            onClick={() => navigate("/aloitus")}
          />
          <div>
            <PrintButton onClick={handleDownloadClick} />

            <FinishButton text={t("common.logout")} onClick={() => logout()} />
          </div>
        </FormActions>
      </FormPage>
      <MobileFooter>
        <PrevButton
          text={t("common.uusilomake")}
          onClick={() => navigate("/aloitus")}
        />
        <FinishButton text={t("common.logout")} onClick={() => logout()} />
      </MobileFooter>
    </>
  );
}
