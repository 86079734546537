const DEFAULT_DECIMAL_PRECISION_DIGITS = 5;
const DEFAULT_DECIMAL_PRECISION =
  1 / Math.pow(10, DEFAULT_DECIMAL_PRECISION_DIGITS); // 0.00001

interface IDecimalOptions {
  digits?: number;
  precision?: number;
}

/**
 * Equality comparison between two decimal numbers, with specific precision.
 *
 * Mimics calling `===` on two decimal numbers, with specific precision.
 *
 * Default precision is defined in `DEFAULT_DECIMAL_PRECISION_DIGITS`.
 *
 * ```
 * eq(0.09, 0.11, { precion: 0.1 })  // true
 * eq(0.009, 0.09, { precion: 0.1 }) // false
 * ```
 */
const eq = (
  a: number,
  b: number,
  {
    digits = DEFAULT_DECIMAL_PRECISION_DIGITS,
    precision = 1 / Math.pow(10, digits),
  }: IDecimalOptions = {}
) => {
  if (Number.isNaN(a) || Number.isNaN(b)) return false;
  return Math.abs(a - b) < precision;
};

/**
 * Greater than comparison between two decimal numbers, with specific precision.
 *
 * Mimics calling `>` on two decimal numbers, with specific precision.
 *
 * Default precision is defined in `DEFAULT_DECIMAL_PRECISION_DIGITS`.
 *
 * ```
 * gt(0.09, 0.01, { precion: 0.1 })  // true
 * gt(0.009, 0.09, { precion: 0.1 }) // false
 * ```
 */
const gt = (
  a: number,
  b: number,
  {
    digits = DEFAULT_DECIMAL_PRECISION_DIGITS,
    precision = 1 / Math.pow(10, digits),
  }: IDecimalOptions = {}
) => {
  if (Number.isNaN(a) || Number.isNaN(b)) return false;
  return a - b - precision > 0;
};

/**
 * Greater than or equal comparison between two decimal numbers, with specific precision.
 *
 * Mimics calling >=` on two decimal numbers, with specific precision.
 *
 * Default precision is defined in `DEFAULT_DECIMAL_PRECISION_DIGITS`.
 *
 * ```
 * gte(0.09, 0.11, { precion: 0.1 })  // true
 * gte(0.009, 0.09, { precion: 0.1 }) // false
 * ```
 */
const gte = (
  a: number,
  b: number,
  {
    digits = DEFAULT_DECIMAL_PRECISION_DIGITS,
    precision = 1 / Math.pow(10, digits),
  }: IDecimalOptions = {}
) => {
  if (Number.isNaN(a) || Number.isNaN(b)) return false;

  if (eq(a, b, { digits })) return true;

  return gt(a, b, { digits });
};

/**
 * Lesser than comparison between two decimal numbers, with specific precision.
 *
 * Mimics calling `<` on two decimal numbers, with specific precision.
 *
 * Default precision is defined in `DEFAULT_DECIMAL_PRECISION_DIGITS`.
 *
 * ```
 * lt(0.009, 0.09, { precion: 0.1 }) // true
 * lt(0.09, 0.11, { precion: 0.1 })  // false
 * ```
 */
const lt = (
  a: number,
  b: number,
  {
    digits = DEFAULT_DECIMAL_PRECISION_DIGITS,
    precision = 1 / Math.pow(10, digits),
  }: IDecimalOptions = {}
) => {
  if (Number.isNaN(a) || Number.isNaN(b)) return false;
  return a - b + precision < 0;
};

/**
 * Lesser than or equal comparison between two decimal numbers, with specific precision.
 *
 * Mimics calling `<=` on two decimal numbers, with specific precision.
 *
 * Default precision is defined in `DEFAULT_DECIMAL_PRECISION_DIGITS`.
 *
 * ```
 * lte(0.09, 0.11, { precion: 0.1 })  // true
 * lte(0.11, 0.09, { precion: 0.1 })  // true
 * lte(0.09, 0.009, { precion: 0.1 }) // false
 * ```
 */
const lte = (
  a: number,
  b: number,
  {
    digits = DEFAULT_DECIMAL_PRECISION_DIGITS,
    precision = 1 / Math.pow(10, digits),
  }: IDecimalOptions = {}
) => {
  if (Number.isNaN(a) || Number.isNaN(b)) return false;

  if (eq(a, b, { digits })) return true;

  return lt(a, b, { digits });
};

/**
 * Calculate difference between two values as absolute value, rounded to two decimals.
 *
 * Returns difference as a string. Return `undefined` if value is `NaN`.
 */
const difference = (
  a: number,
  b: number,
  precision = DEFAULT_DECIMAL_PRECISION
): string | undefined => {
  if (Number.isNaN(a) || Number.isNaN(b)) return undefined;
  return Math.abs(a - b) < precision ? "0" : Math.abs(a - b).toFixed(2);
};

export {
  DEFAULT_DECIMAL_PRECISION,
  DEFAULT_DECIMAL_PRECISION_DIGITS,
  eq,
  gt,
  gte,
  lt,
  lte,
  difference,
};
