import { ErrorActionType, IDeleteErrorAction, ISetErrorAction } from "./types";

let idCounter = 0;

const getId = () => {
  return idCounter++;
};

export function setError(
  message: string,
  additionalInformation?: string
): ISetErrorAction {
  return {
    type: ErrorActionType.SET_ERROR,
    payload: { message, additionalInformation, id: getId() },
  };
}

export function deleteError(id: number): IDeleteErrorAction {
  return {
    type: ErrorActionType.DELETE_ERROR,
    payload: id,
  };
}
