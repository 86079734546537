import { Box, Typography } from "@mui/material";
import React from "react";
import { Navigate } from "react-router-dom";
import useQuery from "../common/useQuery";

export default function RedirectsEditors() {
  const query = useQuery();

  const id = query.get("id");
  const path = query.get("path");

  if (id && path) {
    return <Navigate to={`${path}?id=${id}`} />;
  } else
    return (
      <Box p={4}>
        <Typography>
          Lomakkeelle ohjaus epäonnistui - ohjaustiedot puuttuvat.
        </Typography>
      </Box>
    );
}
