import { yupResolver } from "@hookform/resolvers/yup";
import { FormHelperText, Grid, TextField } from "@mui/material";
import React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setFormValues } from "../../../../store/form/actions";
import { FormState } from "../../../../store/form/types";
import { FormActions } from "../../../common";
import DefaultActions from "../../../common/DefaultActions";
import FormInstructions from "../../../common/FormInstructions";
import FormPage from "../../../common/FormPage";
import FormPageTitle from "../../../common/FormPageTitle";
import { hasDirtyFields } from "../../../common/FormUtils";
import {
  Fields,
  lisatiedotSchemaValidator,
} from "../../../common/lisatieto/lisatiedot.schema";
import MobileActions from "../../../common/MobileActions";
import MobileFooter from "../../../common/MobileFooter";
import ConfirmationPrompt from "../../../common/Prompt/ConfirmationPrompt";
import useFocusFirstField from "../../../common/useFocus";
import { useLisatiedotValue } from "../../../common/useLisatiedotValue";
import useNavigateWithSearch from "../../../common/useNavigateWithSearch";

type LisätiedotFields = Pick<FormState, "Lisatiedot">;

export default function Lisätiedot() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigateWithSearch();
  const lisatiedot = useLisatiedotValue();

  const methods = useForm<LisätiedotFields>({
    shouldFocusError: true,
    defaultValues: {
      Lisatiedot: lisatiedot,
    },
    reValidateMode: "onBlur",
    resolver: yupResolver(lisatiedotSchemaValidator()),
  });

  const handlePreviousClick = () => {
    navigate("/sato/rehunurmen-satotiedot");
  };

  const handleNextClick = (values: Partial<LisätiedotFields>) => {
    dispatch(setFormValues({ ...values, step: 8 }));
    navigate("/sato/yhteenveto");
  };

  useFocusFirstField();

  const lisatietoError =
    methods.formState.errors.Lisatiedot &&
    methods.formState.errors.Lisatiedot[Fields.Lisatiedot]?.message;

  return (
    <FormProvider {...methods}>
      <FormPage>
        <FormPageTitle>8. {t("Lisätiedot.title")}</FormPageTitle>
        <FormInstructions>{t("lisätiedot.ohjeteksti")}</FormInstructions>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Controller
              name={`Lisatiedot.${Fields.Lisatiedot}`}
              control={methods.control}
              render={({ field: { ref, ...field } }) => (
                <>
                  <TextField
                    variant="standard"
                    inputRef={ref}
                    {...field}
                    error={!!lisatietoError}
                    multiline
                    fullWidth
                    label={t("fields.Lisätiedot")}
                  />
                  {!!lisatietoError && (
                    <FormHelperText error={true}>
                      {t(lisatietoError)}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </Grid>
        </Grid>
        <FormActions>
          <DefaultActions
            onPrevious={handlePreviousClick}
            onNext={handleNextClick}
          />
        </FormActions>
      </FormPage>
      <MobileFooter>
        <MobileActions
          step={"8/9"}
          onPrevious={handlePreviousClick}
          onNext={handleNextClick}
        />
      </MobileFooter>
      <ConfirmationPrompt
        open={
          hasDirtyFields(methods.formState) && !methods.formState.isSubmitting
        }
      />
    </FormProvider>
  );
}
