import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { arrayHasValue, kaikkiKorjuukertakentatNollia, jokainenKorjuukertaKenttaTyhja } from "./common";
import {
    KgField, ProsenttiField, SatotasoField, HehtaariField,
} from "../../../common/Fields";
import { useSatotasoCalculator } from "../../../common/useSatotasoCalculator";
import makeStyles from "@mui/styles/makeStyles";
import {
    Fields,
    RehunurmenSatotieto
} from "./rehunurmenSatotiedot.schema";

interface ISailorehuOsio {
    methods: UseFormReturn<any, any>
}

interface ISailorehuHaYhteensa {
    methods: UseFormReturn<any, any>,
    fields: RehunurmenSatotieto[]
}

const useStyles = makeStyles(() => ({
    field: {},
}));

const sailorehuTuoreHaYhteensa = ({ methods, fields }: ISailorehuHaYhteensa) => {
    const tuoresailorehuFieldNames = fields.map(
        (_, index) => `RehunurmenSatotiedot.Korjuu.${index}.${Fields.Säilörehu}`
    );
    const tuoresailorehuFieldValues: number[] = methods.watch(
        tuoresailorehuFieldNames as any
    );

    const summa = tuoresailorehuFieldValues.reduce((acc, val) => {
        return acc + (val || 0);
    }, 0)
    useEffect(() => {
        methods.setValue(
            `RehunurmenSatotiedot.${Fields.SailorehuKorjuuAlaKaikkiKorjuukerratYhteensa}`,
            summa
        );
    }, [summa])
    return summa;
}

const useSummaryValues = (
    fields: RehunurmenSatotieto[],
    methods: UseFormReturn<any, any>,
): {
    tuoresailorehuaKorjattu: boolean;
    tuoresailorehuKaikkiKorjuukerratNollia: boolean;
    tuoresailorehuKaikkiKorjuukerratTyhjia: boolean;
    tuoreSailorehuHaKaikkiKorjuuKerratyhteensa: number
} => {
    const tuoresailorehuFieldNames = fields.map(
        (_, index) => `RehunurmenSatotiedot.Korjuu.${index}.${Fields.Säilörehu}`
    );
    const tuoresailorehuFieldValues = methods.watch(
        tuoresailorehuFieldNames as any
    );
    const tuoresailorehuaKorjattu = arrayHasValue(tuoresailorehuFieldValues);

    const tuoresailorehuKaikkiKorjuukerratNollia = kaikkiKorjuukertakentatNollia(tuoresailorehuFieldValues);

    const tuoresailorehuKaikkiKorjuukerratTyhjia = jokainenKorjuukertaKenttaTyhja(tuoresailorehuFieldValues);

    const tuoreSailorehuHaKaikkiKorjuuKerratyhteensa = sailorehuTuoreHaYhteensa({ methods, fields });

    return {
        tuoresailorehuaKorjattu,
        tuoresailorehuKaikkiKorjuukerratNollia,
        tuoresailorehuKaikkiKorjuukerratTyhjia,
        tuoreSailorehuHaKaikkiKorjuuKerratyhteensa
    };
};

const SailorehuOsio = ({ methods }: ISailorehuOsio) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const {
        tuoresailorehuaKorjattu,
        tuoresailorehuKaikkiKorjuukerratNollia,
        tuoresailorehuKaikkiKorjuukerratTyhjia,
        tuoreSailorehuHaKaikkiKorjuuKerratyhteensa
    } = useSummaryValues(
        methods.getValues("RehunurmenSatotiedot.Korjuu"),
        methods
    );

    const { handleChangeAcres, handleChangeKilograms } =
        useSatotasoCalculator(
            `RehunurmenSatotiedot.${Fields.SäilörehuYhteensäKg}`,
            `RehunurmenSatotiedot.${Fields.SailorehuKorjuuAlaKaikkiKorjuukerratYhteensa}`,
            `RehunurmenSatotiedot.${Fields.SatotasoSailorehuYhteensa}`
        );

    useEffect(() => {
        if (tuoresailorehuKaikkiKorjuukerratNollia || tuoresailorehuKaikkiKorjuukerratTyhjia) {
            methods.clearErrors(`RehunurmenSatotiedot.${Fields.SäilörehuYhteensäKg}`);
        }
        if (tuoresailorehuaKorjattu === false) {
            methods.setValue(
                `RehunurmenSatotiedot.${Fields.SäilörehuYhteensäKg}`,
                null
            );
            methods.setValue(
                `RehunurmenSatotiedot.${Fields.SäilörehuKuivaaineProsentti}`,
                null
            );
        }
    }, [tuoresailorehuaKorjattu]);

    return (
        <>
            <Typography sx={{ fontWeight: "bold" }} pt={4}>
                {t("RehunurmenSatotiedot.TuoresäilöttySäilörehun")}
            </Typography>
            <Controller
                name={`RehunurmenSatotiedot.${Fields.SailorehuKorjuuAlaKaikkiKorjuukerratYhteensa}`}
                control={methods.control}
                render={({ field: { ref, ...field } }) => (
                    <HehtaariField
                        inputRef={ref}
                        {...field}
                        disabled
                        fullWidth
                        onChange={(e) => {
                            field.onChange(e);
                            handleChangeAcres(e);
                        }}
                        className={classes.field}
                        label={"KorjattuAlaYhteensä"}
                    />
                )}
            />
            <Controller
                name={`RehunurmenSatotiedot.${Fields.SäilörehuYhteensäKg}`}
                control={methods.control}
                render={({ field: { ref, ...field } }) => (
                    <KgField
                        inputRef={ref}
                        {...field}
                        label={"Säilörehu"}
                        fullWidth
                        onChange={(e) => {
                            field.onChange(e);
                            handleChangeKilograms(e);
                        }}
                        disabled={!tuoresailorehuaKorjattu}
                    />
                )}
            />
            <Controller
                name={`RehunurmenSatotiedot.${Fields.SatotasoSailorehuYhteensa}`}
                control={methods.control}
                render={({ field: { ref, ...field } }) => (
                    <SatotasoField
                        inputRef={ref}
                        {...field}
                        label={"satotaso"}
                        fullWidth
                        disabled={true}
                    />
                )}
            />
            <Controller
                name={`RehunurmenSatotiedot.${Fields.SäilörehuKuivaaineProsentti}`}
                control={methods.control}
                render={({ field: { ref, ...field } }) => (
                    <ProsenttiField
                        inputRef={ref}
                        {...field}
                        label={"SäilörehuKuivaaineProsentti"}
                        fullWidth
                        disabled={!tuoresailorehuaKorjattu}
                    />
                )}
            />
        </>
    )
}
export default SailorehuOsio;
