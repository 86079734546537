import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  sisalto: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: theme.typography.body1.fontFamily,
  },
  virhe: {
    fontWeight: "700",
    marginBottom: "1rem",
  },
  ohje: {},
}));

interface IProps {
  virhe?: string;
  ohje?: string;
  Virhe?: React.ElementType;
  Ohje?: React.ElementType;
}

/**
 * Component for rendering an error message.
 *
 * Takes props `virhe` (error) and `ohje` (instructions) that define strings
 * to show.
 *
 * Additionally takes props `Virhe` and `Ohje`, with capital first letters,
 * that render components given in them.
 *
 * The component props (with capital letters) take precedence over the
 * string props.
 *
 * If no virhe prop is given (string or component), nothing is rendered.
 */
export default function Virhe(props: IProps) {
  const classes = useStyles();

  const { virhe, ohje, Virhe: VirheRP, Ohje: OhjeRP } = props;

  if (!VirheRP && !virhe) return null;

  return (
    <div className={classes.sisalto}>
      <div className={classes.virhe}>{VirheRP ? <VirheRP /> : virhe}</div>

      {(OhjeRP || ohje) && (
        <div className={classes.ohje}>{OhjeRP ? <OhjeRP /> : ohje}</div>
      )}
    </div>
  );
}
