import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import RootState from "../../../../store/types";
import { FieldMap } from "../../../common/Fields";
import {
  SummaryBox,
  SummaryHeader,
  SummaryRowHeader,
  SummaryValueRow,
} from "../../../common/Summary";
import {
  Fields as RehunurmenSatotietoFields,
  RehunurmenSatotieto as IRehunurmenSatotieto,
} from "./rehunurmenSatotiedot.schema";

interface YhteenvetoNimikeProps {
  fieldId: string;
  paddingLeft?: number;
}
const YhteenvetoNimike = ({
  fieldId,
  paddingLeft,
}: YhteenvetoNimikeProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Typography pl={paddingLeft}>
      {FieldMap[fieldId] ? t(FieldMap[fieldId].label) : fieldId}
    </Typography>
  );
};

interface YhteenvetoArvoJaYksikkoProps {
  fieldId: string;
  korjuukerta?: IRehunurmenSatotieto;
}
const YhteenvetoArvoJaYksikko = ({
  fieldId,
  korjuukerta,
}: YhteenvetoArvoJaYksikkoProps): JSX.Element => {
  return korjuukerta ? (
    <RehunurmenSatotiedotKorjuuValue
      fieldId={fieldId}
      korjuukerta={korjuukerta}
    />
  ) : (
    <RehunurmenSatotiedotValue fieldId={fieldId} />
  );
};

interface KorjuuValueProps {
  fieldId: string;
  korjuukerta: IRehunurmenSatotieto;
}
const RehunurmenSatotiedotKorjuuValue = ({
  fieldId,
  korjuukerta,
}: KorjuuValueProps): JSX.Element => {
  const { t } = useTranslation();

  const korjuu = korjuukerta as unknown as Record<
    string,
    string | number | null
  >;
  const value = korjuu[fieldId] ? korjuu[fieldId] : 0;

  const unit = FieldMap[fieldId] ? t(FieldMap[fieldId].unit) : "";

  return (
    <SummaryValueRow>
      <Typography>{value}</Typography>
      <span>&nbsp;</span>
      <Typography>{unit}</Typography>
    </SummaryValueRow>
  );
};

interface RehunurmenSatotiedotValueProps {
  fieldId: string;
}
const RehunurmenSatotiedotValue = ({
  fieldId,
}: RehunurmenSatotiedotValueProps): JSX.Element => {
  const { t } = useTranslation();
  // Seuraava tyyppi olisi parempi määritellä valmiiksi jo skeemassa.
  type TRehunurmenSatotiedotValue = number | null | IRehunurmenSatotieto[];
  const rehunurmenSatotiedot = useSelector(
    ({ form }: RootState) =>
      form.RehunurmenSatotiedot as Record<string, TRehunurmenSatotiedotValue>
  );

  const value = rehunurmenSatotiedot[fieldId]
    ? rehunurmenSatotiedot[fieldId]
    : 0;

  const unit = FieldMap[fieldId] ? t(FieldMap[fieldId].unit) : "";

  return (
    <SummaryValueRow>
      <Typography>{value}</Typography>
      <span>&nbsp;</span>
      <Typography>{unit}</Typography>
    </SummaryValueRow>
  );
};

interface YhteenvetoRiviProps {
  fieldId: string;
  paddingLeft?: number;
  korjuukerta?: IRehunurmenSatotieto;
}
const YhteenvetoRivi = ({
  fieldId,
  paddingLeft,
  korjuukerta,
}: YhteenvetoRiviProps): JSX.Element => {
  const pl = 4;

  return (
    <SummaryValueRow pl={pl}>
      <YhteenvetoNimike fieldId={fieldId} paddingLeft={paddingLeft} />
      <YhteenvetoArvoJaYksikko fieldId={fieldId} korjuukerta={korjuukerta} />
    </SummaryValueRow>
  );
};

const KuivaheinaYhteensaJaSatotaso = (): JSX.Element => {
  const KuivaheinaHaKaikkiKorjuukerratYhteensa = RehunurmenSatotietoFields.KuivaheinaKorjuuAlaKaikkiKorjuukerratYhteensa;
  const kuivaheinaYhteensaKG = RehunurmenSatotietoFields.KuivaheinäYhteensäKg;
  const satotasoKuivaheina = RehunurmenSatotietoFields.SatotasoKuivaheinaYhteensa;

  const KuivaheinaYhteensaJaSatotasoTaulukko = [
    KuivaheinaHaKaikkiKorjuukerratYhteensa,
    kuivaheinaYhteensaKG,
    satotasoKuivaheina,
  ];
  const KuivaheinaYhteensaJaSatotasoRivit = KuivaheinaYhteensaJaSatotasoTaulukko.map((fieldId: string, index) => {
    return (
      <YhteenvetoRivi
        key={index}
        fieldId={fieldId}
        paddingLeft={0}
      />
    );
  });
  return (
    <>{KuivaheinaYhteensaJaSatotasoRivit}</>
  )
}

const SailorehuYhteensaJaSatotaso = (): JSX.Element => {
  const SailorehuHaKaikkiKorjuukerratYhteensa = RehunurmenSatotietoFields.SailorehuKorjuuAlaKaikkiKorjuukerratYhteensa;
  const SailorehuYhteensaKG = RehunurmenSatotietoFields.SäilörehuYhteensäKg;
  const satotasoSailorehu = RehunurmenSatotietoFields.SatotasoSailorehuYhteensa;
  const sailorehuKuivaaineProsentti =
    RehunurmenSatotietoFields.SäilörehuKuivaaineProsentti;

  const SailorehuYhteensaJaSatotasoTaulukko = [
    SailorehuHaKaikkiKorjuukerratYhteensa,
    SailorehuYhteensaKG,
    satotasoSailorehu,
    sailorehuKuivaaineProsentti
  ];
  const SailorehuYhteensaSatotasoRivit = SailorehuYhteensaJaSatotasoTaulukko.map((fieldId: string, index) => {
    return (
      <YhteenvetoRivi
        key={index}
        fieldId={fieldId}
        paddingLeft={0}
      />
    );
  });
  return (
    <>{SailorehuYhteensaSatotasoRivit}</>
  )
}

const SailorehuEsikuivattuYhteensaJaSatotaso = (): JSX.Element => {
  const SailorehuEsikuivattuHaKaikkiKorjuukerratYhteensa = RehunurmenSatotietoFields.SailorehuEsikuivattuKorjuuAlaKaikkiKorjuukerratYhteensa;
  const sailorehuEsikuivattuYhteensaKg =
    RehunurmenSatotietoFields.SäilörehuEsikuivattuYhteensäKg;
  const satotasoSailorehuEsikuivattu = RehunurmenSatotietoFields.SatotasoSailorehuEsikuivattuYhteensa;
  const sailorehuEsikuivattuKuivaaineProsentti =
    RehunurmenSatotietoFields.SäilörehuEsikuivattuKuivaaineProsentti;

  const SailorehuEsikuivattuYhteensaJaSatotasoTaulukko = [
    SailorehuEsikuivattuHaKaikkiKorjuukerratYhteensa,
    sailorehuEsikuivattuYhteensaKg,
    satotasoSailorehuEsikuivattu,
    sailorehuEsikuivattuKuivaaineProsentti
  ];
  const SailorehuEsikuivattuYhteensaSatotasoRivit = SailorehuEsikuivattuYhteensaJaSatotasoTaulukko.map((fieldId: string, index) => {
    return (
      <YhteenvetoRivi
        key={index}
        fieldId={fieldId}
        paddingLeft={0}
      />
    );
  });
  return (
    <>{SailorehuEsikuivattuYhteensaSatotasoRivit}</>
  )
}

const NiittorehuYhteensaJaSatotaso = (): JSX.Element => {
  const NiittorehuHaKaikkiKorjuukerratYhteensa = RehunurmenSatotietoFields.NiittorehuKorjuuAlaKaikkiKorjuukerratYhteensa;
  const niittorehuYhteensaKg = RehunurmenSatotietoFields.NiittorehuYhteensäKg;
  const satotasoNiittorehu = RehunurmenSatotietoFields.SatotasoNiittorehuYhteensa;
  const niittorehuKuivaaineProsentti =
    RehunurmenSatotietoFields.NiittorehuKuivaaineProsentti;

  const NiittorehuYhteensaJaSatotasoTaulukko = [
    NiittorehuHaKaikkiKorjuukerratYhteensa,
    niittorehuYhteensaKg,
    satotasoNiittorehu,
    niittorehuKuivaaineProsentti
  ];
  const NiittorehuYhteensaSatotasoRivit = NiittorehuYhteensaJaSatotasoTaulukko.map((fieldId: string, index) => {
    return (
      <YhteenvetoRivi
        key={index}
        fieldId={fieldId}
        paddingLeft={0}
      />
    );
  });
  return (
    <>{NiittorehuYhteensaSatotasoRivit}</>
  )
}

const YleisetArvotOsio = (): JSX.Element => {
  const tukihakemuksessaImoitettuAlaKey = RehunurmenSatotietoFields.Korjuuala;
  const alaJoltaKorjattuSatoaKey = RehunurmenSatotietoFields.Korjattuala;
  const pystyynOstettuKey = RehunurmenSatotietoFields.Pystyynosto;
  const pystyynMyytyKey = RehunurmenSatotietoFields.Pystyynmyynti;
  const katoAlaKey = RehunurmenSatotietoFields.Katoala;

  return (
    <>
      <YhteenvetoRivi fieldId={tukihakemuksessaImoitettuAlaKey} />
      <YhteenvetoRivi fieldId={alaJoltaKorjattuSatoaKey} />
      <YhteenvetoRivi fieldId={pystyynOstettuKey} />
      <YhteenvetoRivi fieldId={pystyynMyytyKey} />
      <YhteenvetoRivi fieldId={katoAlaKey} />
    </>
  );
};

const SadonKokonaisMaaraOsio = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Box pt={1}>
        <Typography sx={{ fontWeight: "bold" }}>
          {t("fields.SadonKokonaismäärä")}
        </Typography>
      </Box>
      <Typography sx={{ fontWeight: "bold" }} pt={4}>
        {t("RehunurmenSatotiedot.Kuivaheinän")}
      </Typography>
      <KuivaheinaYhteensaJaSatotaso />
      <Typography sx={{ fontWeight: "bold" }} pt={4}>
        {t("RehunurmenSatotiedot.TuoresäilöttySäilörehun")}
      </Typography>
      <SailorehuYhteensaJaSatotaso />
      <Typography sx={{ fontWeight: "bold" }} pt={4}>
        {t("RehunurmenSatotiedot.EsikuivattuSäilörehun")}
      </Typography>
      <SailorehuEsikuivattuYhteensaJaSatotaso />
      <Typography sx={{ fontWeight: "bold" }} pt={4}>
        {t("RehunurmenSatotiedot.Niittorehun")}
      </Typography>
      <NiittorehuYhteensaJaSatotaso />
    </>
  );
};

interface KorjuukertaProps {
  number: number;
  korjuukerta: IRehunurmenSatotieto;
}
const Korjuukerta = ({
  number,
  korjuukerta,
}: KorjuukertaProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Box pt={1} pb={1}>
      <SummaryRowHeader>
        {number}. {t("fields.Korjuukerta")}
      </SummaryRowHeader>
      {Object.keys(korjuukerta)
        .filter((fieldId) => fieldId !== RehunurmenSatotietoFields.Korjuu)
        .map((fieldId, index) => {
          return (
            <YhteenvetoRivi
              key={index}
              fieldId={fieldId}
              korjuukerta={korjuukerta}
            />
          );
        })}
    </Box>
  );
};

const KorjuukerratOsio = (): JSX.Element => {
  const korjuukerrat = useSelector(
    ({ form }: RootState) => form.RehunurmenSatotiedot.Korjuu
  );
  const { t } = useTranslation();

  return (
    <>
      <Box pt={1} pb={1}>
        <Typography sx={{ fontWeight: "bold" }}>
          {t("RehunurmenSatotiedot.otsikko.Korjuukerrat")}
        </Typography>
      </Box>
      {korjuukerrat.map((korjuukerta, index: number) => (
        <Korjuukerta
          key={index + 1}
          number={index + 1}
          korjuukerta={korjuukerta}
        />
      ))}
    </>
  );
};

export default function YhteenvetoRehunurmenSatotiedot() {
  const { t } = useTranslation();
  const rehunurmenSatotiedot = useSelector(
    ({ form }: RootState) => form.RehunurmenSatotiedot
  );

  if (!rehunurmenSatotiedot) return null;

  return (
    <SummaryBox p={2} mt={2} mb={2}>
      <Box pl={1} pb={1}>
        <SummaryHeader>{t("RehunurmenSatotiedot.title")}</SummaryHeader>
      </Box>
      <YleisetArvotOsio />
      <KorjuukerratOsio />
      <SadonKokonaisMaaraOsio />
    </SummaryBox>
  );
}
