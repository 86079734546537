import * as yup from "yup";
import { nullableNumber } from "../../../common/FormUtils";

export type VarastotiedotForm = {
  Varastotiedot: Varastotiedot;
};

/*
old
export enum VarastoViljat {
  vehnä = "_61209",
  ruis = "_6119",
  ohra = "_61197",
  kaura = "_61189",
}
 */
export enum VarastoViljat {
  vehnä = "_61189",
  ruis = "_61197",
  ohra = "_61199",
  kaura = "_61209",
}

export enum Fields {
  Kasvilaji = "Kasvilaji",
  VarastoarvoHeinäkuu = "_5660386923",
  VarastoarvioTammikuu = "_5660386927",
}

export interface IVarastotiedotVilja {
  /**
   * Apparently we are actually using `kasvilaji` key.
   * This seems too fragile to fix for now.
   */
  kasvilaji?: VarastoViljat;
  [Fields.Kasvilaji]: VarastoViljat;
  [Fields.VarastoarvoHeinäkuu]?: number;
  [Fields.VarastoarvioTammikuu]?: number;
}

export class Varastotiedot {
  constructor(varastossaOnViljaa?: boolean) {
    this.varastossaOnViljaa = varastossaOnViljaa;
    Object.values(VarastoViljat).forEach((label) => {
      this.viljat.push({ Kasvilaji: label });
    });
  }

  varastossaOnViljaa?: boolean = false;
  viljat: IVarastotiedotVilja[] = [];
}

const schema = yup.object().shape({
  Varastotiedot: yup.array().of(
    yup.object().shape({
      [Fields.Kasvilaji]: yup.string(),
      [Fields.VarastoarvoHeinäkuu]: nullableNumber,
      [Fields.VarastoarvioTammikuu]: nullableNumber,
    })
  ),
});

export default schema;
