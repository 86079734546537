import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  footer: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    ["@media print"]: {
      display: "none !important",
    },
    height: "48px",
    position: "fixed",
    backgroundColor: "#B35C00",
    right: 0,
    left: 0,
    width: "100%",
    bottom: 0,
    display: "flex",
    justifyContent: "space-between",
    "& .MuiButton-root": {
      flex: 1,
    },
  },
}));

export default function MobileFooter({
  children,
}: React.PropsWithChildren<any>) {
  const classes = useStyles();

  return <div className={classes.footer}>{children}</div>;
}
