import { ThemeProvider } from "@mui/material/styles";
import { ThemeProvider as LegacyThemeProvider } from "@mui/styles";
// There's something wrong with the MUI packages, yarn.lock has two versions.
// Using the legacy provider is the only working fix atm
import axios, { AxiosRequestConfig } from "axios";
import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Router from "./components/Router";
import { persistor, store } from "./store";
import theme from "./theme";

axios.interceptors.request.use((config: AxiosRequestConfig) => {
  if (config.headers) {
    config.headers.Authorization = store.getState().auth.token || "no token";
  }

  return config;
});

export default function App() {
  return (
    <Suspense fallback={<></>}>
      <ThemeProvider theme={theme}>
        <LegacyThemeProvider theme={theme}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <Router />
            </PersistGate>
          </Provider>
        </LegacyThemeProvider>
      </ThemeProvider>
    </Suspense>
  );
}
