import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import * as mui from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() =>
  createStyles({
    button: {},
  })
);

export function Button(props: mui.ButtonProps) {
  const { size, variant, color, className = "", children, ...rest } = props;
  const classes = useStyles();

  return (
    <mui.Button
      {...rest}
      size={size || "medium"}
      variant={variant || "contained"}
      color={color || "primary"}
      className={`${classes.button} ${className}`}
    >
      {children}
    </mui.Button>
  );
}

type TextProps = {
  text?: string;
};

type TextButtonProps = mui.ButtonProps & TextProps;

export function NextButton({ text, ...rest }: TextButtonProps) {
  const { t } = useTranslation();

  return (
    <Button endIcon={<ArrowRight />} {...rest}>
      {text || t("common.seuraava")}
    </Button>
  );
}

export function PrevButton({ text, ...rest }: TextButtonProps) {
  const { t } = useTranslation();

  return (
    <Button startIcon={<ArrowLeft />} {...rest}>
      {text || t("common.edellinen")}
    </Button>
  );
}

export function FinishButton({ text, ...rest }: TextButtonProps) {
  const { t } = useTranslation();

  return <Button {...rest}>{text || t("common.lähetä")}</Button>;
}
