import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { getNumberValue } from "./FormUtils";

interface ICalculatorProps {
  kilograms?: number | string | null;
  acres?: number | string | null;
}

const round = (value: number) =>
  Math.round((value + Number.EPSILON) * 100) / 100;

/**
 * Calculate satotaso, using kilograms and acres.
 *
 * formula:
 *
 * ```
 * satotaso = kilograms / acres
 * ```
 *
 * @returns satotaso - if either kilograms or acres is missing, returns `undefined`.
 */
export const calculateSatotaso = ({
  kilograms,
  acres,
}: ICalculatorProps): number | null => {
  const acresHasValue = acres !== undefined && acres !== null;
  const kilogramsHasValue = kilograms !== undefined && kilograms !== null;

  if (!acresHasValue || !kilogramsHasValue) return null;

  const kg = getNumberValue(kilograms);
  const ha = getNumberValue(acres);

  // No strings or other non-numeric values (for its hard to do division with them).
  if (isNaN(kg) || isNaN(ha)) return null;

  // No division by 0.
  if (ha === 0) return null;

  return round(kg / ha);
};

interface ITotalCalculatorProps {
  acres?: number | string | null;
  satotaso?: number | string | null;
}

/**
 * Calculate total crops based on acres and satotaso.
 *
 * Formula:
 *
 * ```
 * Acres ha * satotaso kg/ha = total kg
 * ````
 *
 * @returns total - if either acres or satotaso are missing, returns undefined
 */
const calculateTotalKg = ({ acres, satotaso }: ITotalCalculatorProps) => {
  const acresHasValue = acres !== undefined && acres !== null;
  const satotasoHasValue = satotaso !== undefined && satotaso !== null;
  if (!acresHasValue || !satotasoHasValue) return null;

  const ha = getNumberValue(acres);
  const kgha = getNumberValue(satotaso);

  if (isNaN(ha) || isNaN(kgha)) return null;

  return round(ha * kgha);
};

/**
 * Calculate satotaso using react-hook-form's watch.
 *
 * The function is deprecated due to performance issues.
 * Watch appears to invoke all hooks using it, on all field changes.
 * Due to this, and the number of fields (and field arrays) in the form,
 * it cannot currently be used.
 *
 * @deprecated
 * @param kilogramFieldName - kilogram field name (source)
 * @param acreFieldName - acre field name (source)
 * @param satotasoFieldName - satotaso field name (target)
 * @returns void
 */
export function useSatotasoCalculatorWithWatch(
  kilogramFieldName: string,
  acreFieldName: string,
  satotasoFieldName: string
) {
  const methods = useFormContext();

  const [kilograms, acres] = methods.watch([kilogramFieldName, acreFieldName]);

  useEffect(() => {
    const satotaso = calculateSatotaso({ kilograms, acres });
    methods.setValue(satotasoFieldName, satotaso);
  }, [kilograms, acres]);
}

/**
 * Hook for calculating satotaso.
 *
 * Provides event handlers for kg and ha, for setting satotaso on change.
 *
 * @param kilogramFieldName - kilogram field name (source).
 * @param acreFieldName - acre field name (source).
 * @param satotasoFieldName - satotaso field name (target).
 * @returns event handlers (input change) for kilogram and acre fields.
 */
export function useSatotasoCalculator(
  kilogramFieldName: string,
  acreFieldName: string,
  satotasoFieldName: string
) {
  const methods = useFormContext();

  interface IHandleChangeParams {
    acres?: number | string | null;
    kilograms?: number | string | null;
    acreFieldName?: string;
    kilogramFieldName?: string;
  }

  const handleChange = ({
    acres: ha,
    kilograms: kg,
    acreFieldName,
    kilogramFieldName,
  }: IHandleChangeParams): void => {
    const acres = acreFieldName ? methods.getValues(acreFieldName) : ha;
    const kilograms = kilogramFieldName
      ? methods.getValues(kilogramFieldName)
      : kg;

    const satotasoNykyinen = methods.getValues(satotasoFieldName);

    const satotaso = calculateSatotaso({
      acres,
      kilograms,
    });

    if (satotaso !== satotasoNykyinen) {
      methods.setValue(satotasoFieldName, satotaso);
    }
  };

  const handleChangeKilograms = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => handleChange({ acreFieldName, kilograms: e.target.value });

  const handleChangeAcres = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => handleChange({ kilogramFieldName, acres: e.target.value });

  return {
    handleChangeAcres,
    handleChangeKilograms,
  };
}

/**
 * Old implementation.
 *
 * @returns `calculateSatotaso` function.
 */
export default function () {
  return calculateSatotaso;
}
