import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { arrayHasValue, kaikkiKorjuukertakentatNollia, jokainenKorjuukertaKenttaTyhja } from "./common";
import {
    KgField, ProsenttiField, SatotasoField, HehtaariField,
} from "../../../common/Fields";
import { useSatotasoCalculator } from "../../../common/useSatotasoCalculator";
import makeStyles from "@mui/styles/makeStyles";
import {
    Fields,
    RehunurmenSatotieto
} from "./rehunurmenSatotiedot.schema";

interface INiittorehuOsio {
    methods: UseFormReturn<any, any>
}

interface INiittorehuHaYhteensa {
    methods: UseFormReturn<any, any>,
    fields: RehunurmenSatotieto[]
}

const useStyles = makeStyles(() => ({
    field: {},
}));

const niittorehuHaYhteensa = ({ methods, fields }: INiittorehuHaYhteensa) => {
    const niittorehuFieldNames = fields.map(
        (_, index) => `RehunurmenSatotiedot.Korjuu.${index}.${Fields.Niittorehu}`
    );
    const niittorehuFieldValues: number[] = methods.watch(
        niittorehuFieldNames as any
    );
    const summa = niittorehuFieldValues.reduce((acc, val) => {
        return acc + (val || 0);
    }, 0);

    useEffect(() => {
        methods.setValue(
            `RehunurmenSatotiedot.${Fields.NiittorehuKorjuuAlaKaikkiKorjuukerratYhteensa}`,
            summa
        );
    }, [summa])

    return summa;
}

const useSummaryValues = (
    fields: RehunurmenSatotieto[],
    methods: UseFormReturn<any, any>,
): {
    niittorehuaKorjattu: boolean;
    niittorehuKaikkiKorjuukerratNollia: boolean;
    niittorehuKaikkiKorjuukerratTyhjia: boolean;
    niittorehuKorjuukerratHaYhteensa: number
} => {
    const niittorehuFieldNames = fields.map(
        (_, index) => `RehunurmenSatotiedot.Korjuu.${index}.${Fields.Niittorehu}`
    );

    const niittorehuFieldValues = methods.watch(niittorehuFieldNames as any);

    const niittorehuaKorjattu = arrayHasValue(niittorehuFieldValues);


    const niittorehuKaikkiKorjuukerratNollia = kaikkiKorjuukertakentatNollia(niittorehuFieldValues);

    const niittorehuKaikkiKorjuukerratTyhjia = jokainenKorjuukertaKenttaTyhja(niittorehuFieldValues);

    const niittorehuKorjuukerratHaYhteensa = niittorehuHaYhteensa({ methods, fields });

    return {
        niittorehuaKorjattu,
        niittorehuKaikkiKorjuukerratNollia,
        niittorehuKaikkiKorjuukerratTyhjia,
        niittorehuKorjuukerratHaYhteensa
    };
};

const NiittorehuOsio = ({ methods }: INiittorehuOsio) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const {
        niittorehuaKorjattu,
        niittorehuKaikkiKorjuukerratNollia,
        niittorehuKaikkiKorjuukerratTyhjia,
        niittorehuKorjuukerratHaYhteensa
    } = useSummaryValues(
        methods.getValues("RehunurmenSatotiedot.Korjuu"),
        methods
    );

    const { handleChangeAcres, handleChangeKilograms } =
        useSatotasoCalculator(
            `RehunurmenSatotiedot.${Fields.NiittorehuYhteensäKg}`,
            `RehunurmenSatotiedot.${Fields.NiittorehuKorjuuAlaKaikkiKorjuukerratYhteensa}`,
            `RehunurmenSatotiedot.${Fields.SatotasoNiittorehuYhteensa}`
        );

    useEffect(() => {

        if (niittorehuKaikkiKorjuukerratNollia || niittorehuKaikkiKorjuukerratTyhjia) {
            methods.clearErrors(`RehunurmenSatotiedot.${Fields.NiittorehuYhteensäKg}`);
            methods.clearErrors(`RehunurmenSatotiedot.${Fields.NiittorehuKuivaaineProsentti}`);
        }

        if (niittorehuaKorjattu === false) {
            methods.setValue(
                `RehunurmenSatotiedot.${Fields.NiittorehuYhteensäKg}`,
                null
            );
            methods.setValue(
                `RehunurmenSatotiedot.${Fields.NiittorehuKuivaaineProsentti}`,
                null
            );
        }
    }, [niittorehuaKorjattu]);

    return (
        <>
            <Typography sx={{ fontWeight: "bold" }} pt={4}>
                {t("RehunurmenSatotiedot.Niittorehun")}
            </Typography>
            <Controller
                name={`RehunurmenSatotiedot.${Fields.NiittorehuKorjuuAlaKaikkiKorjuukerratYhteensa}`}
                control={methods.control}
                render={({ field: { ref, ...field } }) => (
                    <HehtaariField
                        inputRef={ref}
                        {...field}
                        disabled
                        fullWidth
                        onChange={(e) => {
                            field.onChange(e);
                            handleChangeAcres(e);
                        }}
                        className={classes.field}
                        label={"KorjattuAlaYhteensä"}
                    />
                )}
            />
            <Controller
                name={`RehunurmenSatotiedot.${Fields.NiittorehuYhteensäKg}`}
                control={methods.control}
                render={({ field: { ref, ...field } }) => (
                    <KgField
                        inputRef={ref}
                        {...field}
                        label={"Niittorehu"}
                        fullWidth
                        onChange={(e) => {
                            field.onChange(e);
                            handleChangeKilograms(e);
                        }}
                        disabled={!niittorehuaKorjattu}
                    />
                )}
            />
            <Controller
                name={`RehunurmenSatotiedot.${Fields.SatotasoNiittorehuYhteensa}`}
                control={methods.control}
                render={({ field: { ref, ...field } }) => (
                    <SatotasoField
                        inputRef={ref}
                        {...field}
                        label={"satotaso"}
                        fullWidth
                        disabled={true}
                    />
                )}
            />
            <Controller
                name={`RehunurmenSatotiedot.${Fields.NiittorehuKuivaaineProsentti}`}
                control={methods.control}
                render={({ field: { ref, ...field } }) => (
                    <ProsenttiField
                        inputRef={ref}
                        {...field}
                        label={"NiittorehuKuivaaineProsentti"}
                        fullWidth
                        disabled={!niittorehuaKorjattu}
                    />
                )}
            />
        </>
    )
}
export default NiittorehuOsio;
