import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "../Footer";
import Header from "../Header";
import KyselyOtsake from "../KyselyOtsake";
import Virhe from "../Virhe";
import ErrorBoundary from "./ErrorBoundary";

const useStyles = makeStyles((theme) => ({
  sivu: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    minHeight: "100vh",
  },
  sisalto: {
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  virhe: {
    fontWeight: "700",
    marginBottom: "1rem",
  },
  ohje: {},
}));

const Virhesivu = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Header isLoggedIn />
      <KyselyOtsake title={t("common.app")} />
      <div className={classes.sisalto}>
        <Virhe
          virhe={t("virhe.errorboundary.router.virhe")}
          ohje={t("virhe.errorboundary.router.ohje")}
        />
      </div>
      <Footer />
    </>
  );
};

interface IProps {
  children?: React.ReactNode;
}

const RouterErrorBoundary = (props: IProps) => {
  const { children } = props;
  return <ErrorBoundary Virhenakyma={Virhesivu}>{children}</ErrorBoundary>;
};

export default RouterErrorBoundary;

export { Virhesivu };
