import { Box, Typography } from "@mui/material";
import React from "react";
import { Footer, Header } from "../common";
import Title from "../common/Title";
import { Trans, useTranslation } from "react-i18next";

export default function Accessibility() {
  const { t } = useTranslation();

  return (
    <>
      <Header isLoggedIn={false} />
      <Title>
        <Typography variant="h1">{t("Accessibility.title")}</Typography>
      </Title>
      <Box p={8} pt={4}>
        <Typography>
          <article>
            <header>
              <h1>{t("Accessibility.tervetuloa")}</h1>
            </header>
            <section>
              <p>
                <Trans
                  i18nKey={"Accessibility.lakiperuste"}
                  components={{
                    a: <a href="https://sato.luke.fi" />,
                  }}
                />
              </p>
              <p>{t("Accessibility.arviointi")}</p>
            </section>

            <section>
              <h2>{t("Accessibility.saavutettavuustila")}</h2>
              <p>{t("Accessibility.tayttaaVaatimukset")}</p>
            </section>

            <h2>{t("Accessibility.saavuttamatonsisalto")}</h2>

            <section>
              <section>
                <h3>3. {t("Accessibility.eiVaatimustenMukainen")} </h3>

                <ul>
                  <li>
                    <h4>{t("Accessibility.mobiilikaytto")}</h4>
                    <p>{t("Accessibility.mobiilikaytto.teksti")}</p>
                  </li>
                  <li>
                    <h4>{t("Accessibility.pdfLuettavuus")}</h4>
                    <p>
                      <Trans
                        i18nKey={"Accessibility.pdfLuettavuus.teksti"}
                        components={{ br: <br /> }}
                      />
                    </p>
                  </li>
                </ul>
              </section>
            </section>

            <section>
              <h2>{t("Accessibility.saavutettavuuspuute")}</h2>

              <h3>{t("Accessibility.sahkopostilla")} </h3>
              <p>{t("Accessibility.kirjaamo")}</p>

              <h3>{t("Accessibility.muillatavoin")}</h3>
              <p>
                <Trans
                  i18nKey={"Accessibility.muillatavoin.teksti"}
                  components={{ br: <br /> }}
                />
              </p>
            </section>

            <section>
              <h2>{t("Accessibility.valvontaviranomainen")}</h2>
              <p>
                <Trans
                  i18nKey={"Accessibility.valvontaviranomainen.teksti"}
                  components={{ br: <br />, a: <a />, span: <span /> }}
                />
              </p>

              <h2>{t("Accessibility.valvontaYhteystiedot")}</h2>
              <p>
                <Trans
                  i18nKey={"Accessibility.valvontaYhteystiedot.teksti"}
                  components={{ br: <br /> }}
                />
              </p>
            </section>

            <section>
              <h2>{t("Accessibility.saavutettavuudenParantaminen")} </h2>
              <h3>{t("Accessibility.tarjoammeTukea")}</h3>
              <p>{t("Accessibility.tarjoammetukea.teksti")}</p>
              <h3>{t("Accessibility.julkaisu")}</h3>
              <p>{t("Accessibility.julkaisu.pvm")} </p>
            </section>
          </article>
        </Typography>
      </Box>
      <Footer />
    </>
  );
}
