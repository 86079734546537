import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import RootState from "../../store/types";

export default function Kasvilaji({ id }: { id: string }) {
  const { t } = useTranslation();
  const laji = useSelector(({ meta }: RootState) =>
    [
      ...meta.viljakasvit,
      ...meta.muutkasvit,
      ...meta.perunakasvit,
      ...meta.valkuaiskasvit,
    ].find((row) => row.id === id)
  ) as { [key: string]: string | number };

  if (!laji) return <>-</>;

  return <>{t(laji.name as string)}</>;
}
