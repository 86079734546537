import {
  ErrorActions,
  ErrorActionType,
  IDeleteErrorAction,
  IErrorState,
  ISetErrorAction,
} from "./types";

const initialState: IErrorState = {
  errors: [],
};

const setErrorReducer = (
  state = initialState,
  action: ISetErrorAction
): IErrorState => {
  const errors = [...state.errors];
  const newError = action.payload;
  errors.push(newError);
  return { ...state, errors };
};

const deleteErrorReducer = (
  state = initialState,
  action: IDeleteErrorAction
): IErrorState => {
  const id = action.payload;
  const errors = [...state.errors].filter((e) => e.id !== id);
  return { ...state, errors };
};

export default function errorReducer(
  state = initialState,
  action: ErrorActions
): IErrorState {
  switch (action.type) {
    case ErrorActionType.SET_ERROR:
      return setErrorReducer(state, action);

    case ErrorActionType.DELETE_ERROR:
      return deleteErrorReducer(state, action);

    default:
      return state;
  }
}
