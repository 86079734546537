import { Box, Link, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { Footer, Header, useQuery } from "../common";
import FormContent from "../common/FormContent";
import KyselyOtsake from "../common/KyselyOtsake";

export default function Uloskirjauduttu() {
  const { t } = useTranslation();
  const query = useQuery();

  const [reason, setReason] = useState<string | null>(null);

  useEffect(() => {
    setReason(query.get("reason"));
  }, [query]);

  return (
    <>
      <Header />
      <KyselyOtsake title={t("common.app")} />
      <FormContent>
        <Box pl={6} pt={2}>
          {!reason && (
            <Box mb={2}>
              <Typography>{t("common.loggedOut")}</Typography>
            </Box>
          )}

          {reason === "timeout" && (
            <Box mb={2}>
              <Typography>{t("common.timedOut")}</Typography>
            </Box>
          )}

          <Link component={RouterLink} to="/">
            <Typography> {t("common.toFrontpage")}</Typography>
          </Link>
        </Box>
      </FormContent>
      <Footer />
    </>
  );
}
