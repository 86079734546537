import _ from "lodash";

export type TDecimalFromFormField =
  | number
  | null
  | undefined
  | string
  | boolean;

export const convertFieldToNumber = (value: TDecimalFromFormField) => {
  if (!value) return 0;
  if (typeof value !== "number") return 0;

  return value;
};

export const arrayHasValue = (fields: any[]) => {
  return !!_.sum(_.compact(fields));
};

export const kaikkiKorjuukertakentatNollia = (fields: any[]) => {
  const suurempiTaiPienempiKuinNolla = fields.find(korjuuKerta => (korjuuKerta < 0 || korjuuKerta > 0)
    && korjuuKerta !== null && isNaN(korjuuKerta) === false && korjuuKerta !== undefined);

  return !suurempiTaiPienempiKuinNolla;
};

export const jokainenKorjuukertaKenttaTyhja = (fields: any[]) => {
  const notEmpty = fields.find(korjuuKerta => korjuuKerta !== null && korjuuKerta !== undefined && isNaN(korjuuKerta) === false)

  return !notEmpty;
}
