import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { HehtaariField, KgField, SatotasoField } from "../../../common/Fields";
import SectionSubtitle from "../../../common/SectionSubtitle";
import { useSatotasoCalculator } from "../../../common/useSatotasoCalculator";
import { Fields } from "./valkuaiskasvienSatotiedot.schema";

export default function Oljykasvi({ field, index }: any) {
  const { t } = useTranslation();

  const methods = useFormContext();

  const viljelyAla = methods.watch(
    `ValkuaiskasvienSatotiedot.${index}.${Fields.Viljelyala}`
  );

  const korjuuala = methods.watch(
    `ValkuaiskasvienSatotiedot.${index}.${Fields.KorjuualaÖljykasvi}`
  );
  useEffect(() => {
    if (korjuuala === 0) {
      methods.setValue(
        `ValkuaiskasvienSatotiedot.${index}.${[Fields.SatomääräÖljykasvi]}`,
        null
      );
      methods.setValue(
        `ValkuaiskasvienSatotiedot.${index}.${[Fields.SatotasoÖljykasvi]}`,
        null
      );
    }
  }, [korjuuala]);

  useEffect(() => {
    if (viljelyAla === 0) {
      methods.setValue(
        `ValkuaiskasvienSatotiedot.${index}.${Fields.KorjuualaÖljykasvi}`,
        null
      );
      methods.setValue(
        `ValkuaiskasvienSatotiedot.${index}.${Fields.SatomääräÖljykasvi}`,
        null
      );
      methods.setValue(
        `ValkuaiskasvienSatotiedot.${index}.${Fields.SatotasoKuiva}`,
        null
      );

      methods.trigger([
        `ValkuaiskasvienSatotiedot.${index}.${Fields.KorjuualaÖljykasvi}`,
        `ValkuaiskasvienSatotiedot.${index}.${Fields.SatomääräÖljykasvi}`,
        `ValkuaiskasvienSatotiedot.${index}.${Fields.SatotasoKuiva}`,
      ]);
    }
  }, [viljelyAla]);

  useEffect(() => {
    if (korjuuala) {
      methods.clearErrors(
        `ValkuaiskasvienSatotiedot.${index}.${Fields.KorjuualaÖljykasvi}`
      );
    } else
      methods.clearErrors(
        `ValkuaiskasvienSatotiedot.${index}.${Fields.SatomääräÖljykasvi}`
      );
  }, [korjuuala]);

  const { handleChangeAcres, handleChangeKilograms } = useSatotasoCalculator(
    `ValkuaiskasvienSatotiedot.${index}.${Fields.SatomääräÖljykasvi}`,
    `ValkuaiskasvienSatotiedot.${index}.${Fields.KorjuualaÖljykasvi}`,
    `ValkuaiskasvienSatotiedot.${index}.${Fields.SatotasoÖljykasvi}`
  );

  if (viljelyAla === 0) return null;

  switch (field.Kasvilaji) {
    case "_387023":
      break;
    case "_387019":
      break;
    case "_387027":
      break;
    case "_387243":
      break;
    case "_386999":
      break;
    case "_387003":
      break;
    default:
      return null;
  }

  return (
    <Grid item md={6} xs={12}>
      <SectionSubtitle>
        {t("ValkuaiskasvienSatotiedot.Korjuutiedot")}
      </SectionSubtitle>
      <Controller
        name={`ValkuaiskasvienSatotiedot.${index}.${Fields.KorjuualaÖljykasvi}`}
        control={methods.control}
        render={({ field: { ref, ...field } }) => (
          <HehtaariField
            disabled={viljelyAla === 0}
            inputRef={ref}
            {...field}
            label={"Korjuuala"}
            fullWidth
            onChange={(e) => {
              field.onChange(e);
              handleChangeAcres(e);
            }}
          />
        )}
      />

      <Controller
        name={`ValkuaiskasvienSatotiedot.${index}.${Fields.SatomääräÖljykasvi}`}
        control={methods.control}
        render={({ field: { ref, ...field } }) => (
          <KgField
            inputRef={ref}
            {...field}
            label={"SadonKokonaismäärä"}
            fullWidth
            onChange={(e) => {
              field.onChange(e);
              handleChangeKilograms(e);
            }}
            disabled={!korjuuala}
          />
        )}
      />

      <Controller
        name={`ValkuaiskasvienSatotiedot.${index}.${Fields.SatotasoÖljykasvi}`}
        control={methods.control}
        render={({ field: { ref, ...field } }) => (
          <SatotasoField
            inputRef={ref}
            {...field}
            label={"satotaso"}
            fullWidth
            disabled={!korjuuala}
          />
        )}
      />
    </Grid>
  );
}
