import { Grid, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  HehtaariField,
  KgField,
  ProsenttiField,
  SatotasoField,
} from "../../../common/Fields";
import { useSatotasoCalculator } from "../../../common/useSatotasoCalculator";
import { CropFieldContext } from "./CropContext";
import { Fields } from "./viljanSatotiedot.schema";

export default function TuoreenaKorjattavaVilja() {
  const { t } = useTranslation();
  const { field, index } = useContext(CropFieldContext);

  const methods = useFormContext();
  const selected = methods.watch(
    `ViljanSatotiedot.${index}.${Fields.KorjattuTuoresäilörehu}`
  );

  const korjuuala = methods.watch(
    `ViljanSatotiedot.${index}.${[Fields.KorjuualaTuoresäilövilja]}`
  );

  useEffect(() => {
    if (korjuuala === 0) {
      methods.setValue(
        `ViljanSatotiedot.${index}.${[Fields.SatomääräTuoresäilövilja]}`,
        null
      );
      methods.setValue(
        `ViljanSatotiedot.${index}.${[Fields.KosteusTuoresäilövilja]}`,
        null
      );
    }
  }, [korjuuala]);

  const { handleChangeAcres, handleChangeKilograms } = useSatotasoCalculator(
    `ViljanSatotiedot.${index}.${Fields.SatomääräTuoresäilövilja}`,
    `ViljanSatotiedot.${index}.${Fields.KorjuualaTuoresäilövilja}`,
    `ViljanSatotiedot.${index}.${Fields.SatotasoTuoresäilövilja}`
  );

  useEffect(() => {
    if (!selected) {
      methods.setValue(
        `ViljanSatotiedot.${index}.${Fields.SatomääräTuoresäilövilja}`,
        null
      );
      methods.setValue(
        `ViljanSatotiedot.${index}.${Fields.KorjuualaTuoresäilövilja}`,
        null
      );
      methods.setValue(
        `ViljanSatotiedot.${index}.${Fields.SatotasoTuoresäilövilja}`,
        null
      );
      methods.setValue(
        `ViljanSatotiedot.${index}.${Fields.KosteusTuoresäilövilja}`,
        null
      );
    }
  }, [selected]);

  return (
    <>
      {!!selected && (
        <Grid item md={6} xs={12}>
          <Typography sx={{ fontWeight: "bold" }}>
            {t("ViljanSatotiedot.TuoreenaKorjattavaVilja")}
          </Typography>

          <Controller
            name={`ViljanSatotiedot.${index}.${Fields.KorjuualaTuoresäilövilja}`}
            control={methods.control}
            render={({ field: { ref, ...field } }) => (
              <HehtaariField
                tooltip={t(
                  "ViljanSatotiedot.ohjeteksti.TuoreenaKorjattavaViljaOhje"
                )}
                inputRef={ref}
                {...field}
                fullWidth
                label={"TuoreviljanKorjuuala"}
                onChange={(e) => {
                  field.onChange(e);
                  handleChangeAcres(e);
                }}
              />
            )}
          />

          <Controller
            name={`ViljanSatotiedot.${index}.${Fields.SatomääräTuoresäilövilja}`}
            control={methods.control}
            render={({ field: { ref, ...field } }) => (
              <KgField
                inputRef={ref}
                {...field}
                label={"TuoresadonKokonaismäärä"}
                fullWidth
                onChange={(e) => {
                  field.onChange(e);
                  handleChangeKilograms(e);
                }}
                disabled={korjuuala === 0}
              />
            )}
          />

          <Controller
            name={`ViljanSatotiedot.${index}.${Fields.SatotasoTuoresäilövilja}`}
            control={methods.control}
            render={({ field: { ref, ...field } }) => (
              <SatotasoField
                inputRef={ref}
                {...field}
                label={"satotaso"}
                fullWidth
                disabled={korjuuala === 0}
              />
            )}
          />

          <Controller
            name={`ViljanSatotiedot.${index}.${Fields.KosteusTuoresäilövilja}`}
            control={methods.control}
            render={({ field: { ref, ...field } }) => (
              <ProsenttiField
                inputRef={ref}
                {...field}
                label={"Kosteusprosentti"}
                fullWidth
                disabled={korjuuala === 0}
              />
            )}
          />
        </Grid>
      )}
    </>
  );
}
