import { createTheme, Theme } from "@mui/material/styles";

declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme { }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#B35C00",
      contrastText: "#fff",
    },
    secondary: { main: "#fff", contrastText: "#E07400" },
    action: {
      disabledBackground: "#B35C00",
      disabled: "#fff",
    },
  },
  typography: {
    fontFamily: "Titillium Web",
    h1: {
      fontSize: "2.25rem",
      lineHeight: 1.334,
      fontWeight: 400,
    },
    h2: {
      fontSize: "1.8rem",
      lineHeight: 1.6,
      fontWeight: 400,
    },
    h3: {
      fontSize: "1.5rem",
      lineHeight: 1.334,
      fontWeight: 400,
    },
    subtitle2: {
      display: "block",
    },
    body1: {
      fontSize: "1.02rem",
    },
  },
});

const extendedTheme = createTheme(theme, {
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: "black",
          textDecoration: "underline",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "rgba(0, 0, 0, 0.87)",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          color: "rgba(0, 0, 0, 0.87)",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          color: "#0033a0",
        },
      },
    },
    MuiCardActionArea: {
      styleOverrides: {
        root: {
          height: "100%",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          height: "100%",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: "bold",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: theme.spacing(1),
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {},
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: "0",
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: "rgba(0, 0, 0, 0.87)",
          "& .MuiTypography-root": {
            color: "rgba(0, 0, 0, 0.87)",
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "rgba(0, 0, 0, 0.87)",
        },
        colorSecondary: {
          color: "#fff",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        colorSecondary: {
          color: "#fff",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          marginTop: "24px",
          marginRight: "-1px",
          marginBottom: "24px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "0",
          textTransform: "none",
        },
        containedSecondary: {
          borderRadius: "3px",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {},
        tooltipPlacementTop: {
          marging: "-1rem",
        },
      },
    },
  },
});

export default extendedTheme;
