/**
 * Application's pathnames without inquiry prefix
 */
const paths = {
  aloitus: "aloitus",
  kiitos: "kiitos",
  lisatiedot: "lisatiedot",
  muidenviljelykasviensatotiedot: "muiden-viljelykasvien-satotiedot",
  perunansatotiedot: "perunan-satotiedot",
  rehunurmensatotiedot: "rehunurmen-satotiedot",
  satojavarastot: "sato-ja-varastot",
  syyskylvot: "syyskylvot",
  valkuaiskasviensatotiedot: "valkuaiskasvien-satotiedot",
  vastauskoodit: "vastauskoodit",
  viljansatotiedot: "viljan-satotiedot",
  viljanvarastot: "viljan-varastot",
  yhteenveto: "yhteenveto",
} as const;

export type Inquiry = "sato" | "peruna";
type PathKey = keyof typeof paths;
export type AppPath = (typeof paths)[PathKey];
export type AppPathWithInquiry = `/${Inquiry}/${AppPath}`;

export default paths as Record<PathKey, AppPath>;

/**
 * Application's route definition
 */
export interface RouteDef {
  /**
   * Route pathname
   */
  href: AppPath;
  /**
   * Route label in Stepper
   */
  text: string;
  nested?: boolean;
}
