import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import useBreakpoints from "../../../breakpoints";
import paths, { RouteDef } from "../../../paths";
import { formInquiryPath } from "../../../utils";
import { Footer, Header, useQuery } from "../../common";
import CenteredCircularProgress from "../../common/CenteredCircularProgress";
import KysymyssivuErrorBoundary from "../../common/error-boundaries/KysymyssivuErrorBoundary";
import KysymyssivuaEiLoytynytNakyma from "../../common/error-views/KysymyssivuaEiLoytynyt";
import KyselyOtsake from "../../common/KyselyOtsake";
import StepperNav from "../../common/StepperNav";
import useFormData from "../../common/useFormData";
import Vastauskoodit from "../../common/Vastauskoodit";
import Kiitos from "./Lisatiedot/Kiitos";
import Lisätiedot from "./Lisatiedot/Lisatiedot";
import PerunanSatotiedot from "./PerunanSatotiedot/PerunanSatotiedot";
import Yhteenveto from "./Yhteenveto/Yhteenveto";

function usePerunaRoutes(): RouteDef[] {
  const { t } = useTranslation();

  return [
    {
      href: paths.satojavarastot,
      text: "PerunanSatojaVarastot.title",
    },
    {
      href: paths.lisatiedot,
      text: "PerunanLisätiedot.title",
    },
    {
      href: paths.yhteenveto,
      text: "yhteenveto.title",
    },
  ];
}

export default function PerunaRouter() {
  const nav = usePerunaRoutes();
  const { t } = useTranslation();
  const breakpoints = useBreakpoints();
  const query = useQuery();
  const id = query.get("id") as string;

  /**
   * Load form values from backend and populate store before continuing to form pages
   */
  const { loading } = useFormData(id);

  return (
    <>
      <Header isLoggedIn />
      <KyselyOtsake
        title={`${t("common.peruna")} ${new Date().getFullYear()}`}
      />

      {loading ? (
        <CenteredCircularProgress />
      ) : (
        <div className={breakpoints.layout}>
          <StepperNav routes={nav} formId={id} />
          <KysymyssivuErrorBoundary>
            <Box className={breakpoints.routes}>
              <Routes>
                <Route path="*" element={<KysymyssivuaEiLoytynytNakyma />} />
                <Route
                  path={paths.vastauskoodit}
                  element={
                    <Vastauskoodit
                      nextPath={formInquiryPath(paths.satojavarastot)}
                    />
                  }
                />
                <Route
                  path={paths.satojavarastot}
                  element={<PerunanSatotiedot />}
                />
                <Route path={paths.kiitos} element={<Kiitos />} />
                <Route path={paths.yhteenveto} element={<Yhteenveto />} />
                <Route path={paths.lisatiedot} element={<Lisätiedot />} />
              </Routes>
            </Box>
          </KysymyssivuErrorBoundary>{" "}
        </div>
      )}

      <Footer />
    </>
  );
}
