import { LisatiedotForm } from "../../components/common/lisatieto/lisatiedot.schema";
import { VastauskoodiKommenttiForm } from "../../components/common/vastauskoodit.schema";
import { MuutViljelykasvitForm } from "../../components/Forms/Sato/MuutViljelykasvit/muutViljelykasvit.schema";
import { PerunanSatotiedotForm } from "../../components/Forms/Sato/PerunanSatotiedot/perunanSatotiedot.schema";
import { RehunurmenSatotiedotForm } from "../../components/Forms/Sato/RehunurmenSatotiedot/rehunurmenSatotiedot.schema";
import { SyyskylvötForm } from "../../components/Forms/Sato/Syyskylvot/syyskylvot.schema";
import { ValkuaiskasvienSatotiedotForm } from "../../components/Forms/Sato/ValkuaiskasvienSatotiedot/valkuaiskasvienSatotiedot.schema";
import { VarastotiedotForm } from "../../components/Forms/Sato/Varastotiedot/varastotiedot.schema";
import { ViljanSatotiedotForm } from "../../components/Forms/Sato/ViljanSatotiedot/viljanSatotiedot.schema";

export const SET_VALUES = "SET_VALUES";
export const INIT_VALUES = "INIT_VALUES";
export const RESET_VALUES = "RESET_VALUES";

export interface InitValuesAction {
  type: typeof INIT_VALUES;
  payload?: Partial<FormState>;
}

export interface SetValuesAction {
  type: typeof SET_VALUES;
  payload?: Partial<FormState>;
}

export interface ResetValuesAction {
  type: typeof RESET_VALUES;
}

export type Switch = 0 | 1 | undefined;

export type FormState = {
  step: number | null;
  periodId: string | null;
  /**
   * Time when the form was finished and sent.
   * Timestamp in ms from ECMAScript epoch; undefined if not sent yet.
   */
  finished_at?: string;
  // fixme: Backwards compatilibity workaround, to be removed after SATO tiedonkeruu is over and data is cleaned
  Lisätiedot?: string;
} & ViljanSatotiedotForm &
  VarastotiedotForm &
  ValkuaiskasvienSatotiedotForm &
  SyyskylvötForm &
  MuutViljelykasvitForm &
  PerunanSatotiedotForm &
  RehunurmenSatotiedotForm &
  LisatiedotForm &
  VastauskoodiKommenttiForm;

export type FormActions =
  | SetValuesAction
  | InitValuesAction
  | ResetValuesAction;
