import { Box, Typography } from "@mui/material";
import React from "react";

const FormPageTitle = ({ children, sx }: React.PropsWithChildren<any>) => (
  <Box sx={sx || {}} mb={2}>
    <Typography variant="h2">{children}</Typography>
  </Box>
);

export default FormPageTitle;
