import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-locize-backend";
import { initReactI18next } from "react-i18next";
import fi from "../locales/fi.json";
import sv from "../locales/sv.json";

const locizeOptions = {
  projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID as string,
  apiKey: process.env.REACT_APP_LOCIZE_API_KEY as string,
  referenceLng: "fi",
};

if (process.env.REACT_APP_LOCIZE_PROJECT_ID) {
  i18n
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      fallbackLng: "fi",
      debug: false,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      backend: locizeOptions,
    });
} else {
  // Fallback to local translations if Locize project is not specified
  i18n
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      fallbackLng: "fi",
      resources: {
        fi: {
          translation: fi,
        },
        sv: {
          translation: sv,
        },
      },
      debug: false,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    });
}

export default i18n;
