import {
  AppBar,
  Box,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { t } from "i18next";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { default as logoWhite } from "../../assets/Luke_FI_valk_RGB.png";
import { default as logoRgb } from "../../assets/Luke_FI_virall_RGB.png";
import { default as logoSE } from "../../assets/Luke_SE_virall_RGB.png";
import svtLogo from "../../assets/svt_logo.jpg";
import useBreakpoints from "../../breakpoints";
import i18n from "../../i18n";
import BannerShower from "./BannerShower/BannerShower";
import UserMenu from "./UserMenu";
import useTokenMetadata from "./useTokenMetadata";
import useTokenUser from "./useTokenUserInfo";
const useStyles = makeStyles(() => ({
  appBarCenter: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
  },
  appBar: {
    ["@media print"]: {
      display: "none !important",
    },
  },
  logout: {
    textAlign: "right",
  },
  logoMobile: { height: "48px" },
  svtLogo: {
    marginLeft: "32px",
    maxWidth: "250px",
    height: "60px",
    marginTop: "20px",
  },
  link: {
    color: "#0033a0",
    textDecoration: "underline",
    cursor: "pointer",
  },
  items: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

type HeaderProps = {
  isLoggedIn?: boolean;
};

const Brand = () => (
  <RouterLink to="/">
    <div>
      {i18n.language == "fi" ? (
        <img src={logoRgb} height={90} alt="Logo" />
      ) : (
        <img src={logoSE} height={90} alt="Logo" />
      )}
    </div>
  </RouterLink>
);

export default function Header({ isLoggedIn }: HeaderProps) {
  const breakpoints = useBreakpoints();
  const user = useTokenUser();
  const classes = useStyles();
  const meta = useTokenMetadata();

  const tiltu = meta.find((row) => row.key === "TILTU");

  const handleLanguageChange = (lng: string) => {
    setTimeout(() => {
      i18n.changeLanguage(lng);
    }, 100);
  };

  return (
    <>
      <Box pl={8} pr={8} pt={4} pb={4} className={breakpoints.desktopHeader}>
        <Grid container justifyContent="space-between">
          <Grid item sm={6} xs={12} sx={{ display: "flex" }}>
            <Brand />
            <img
              tabIndex={0}
              alt={t("common.svt_logo")}
              className={classes.svtLogo}
              src={svtLogo}
            />
          </Grid>

          <Grid className={classes.logout} item sm={6} xs={12}>
            {isLoggedIn && (
              <div className={classes.items}>
                <Typography variant="subtitle2">
                  <div>{user?.n}</div>
                  <div>
                    {t("common.tila")} {tiltu?.value}
                  </div>
                </Typography>
                <UserMenu />
              </div>
            )}
            {!isLoggedIn && (
              <div className={classes.items}>
                <IconButton
                  size="small"
                  onClick={() => handleLanguageChange("fi")}
                >
                  {t("common.fi")}
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleLanguageChange("sv")}
                >
                  {t("common.se")}
                </IconButton>
              </div>
            )}
          </Grid>
        </Grid>
      </Box>
      <AppBar
        position="static"
        className={`${breakpoints.appBar} ${classes.appBar}`}
      >
        <Toolbar>
          <div className={classes.appBarCenter}>
            <RouterLink to="/">
              {" "}
              <img
                className={classes.logoMobile}
                src={logoWhite}
                alt={"Luonnonvarakeskuksen logo"}
              />
            </RouterLink>
          </div>
          {isLoggedIn && <UserMenu />}
        </Toolbar>
      </AppBar>
      <BannerShower />
    </>
  );
}
