import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useTranslation } from "react-i18next";
import Virhe from "../Virhe";

const useStyles = makeStyles((theme) => ({
  teksti: {
    width: "100%",
    maxHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function KysymyssivuaEiLoytynyt() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.teksti}>
      <Virhe
        virhe={t("virhe.404.kysymyssarjaaEiLoytynyt.virhe")}
        ohje={t("virhe.404.kysymyssarjaaEiLoytynyt.ohje")}
      />
    </div>
  );
}
