import { Box, Grid, Link, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import useBreakpoints from "../../breakpoints";

export default function Footer({ sticky }: { sticky?: boolean }) {
  const breakpoints = useBreakpoints();

  return (
    <div className={breakpoints.footer}>
      <TikalFooter
        sticky={sticky}
        firstColumnLinks={[
          {
            text: "footer.saavutettavuus",
            href: "/saavutettavuus",
          },
          {
            text: "footer.tietosuoja",
            href: "https://www.luke.fi/fi/tietosuoja",
          },
        ]}
        secondColumnLinks={[
          {
            text: "footer.sato",
          },
          {
            text: "footer.peruna",
          },
        ]}
      ></TikalFooter>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  box: {
    position: "relative",
    right: 0,
    left: 0,
    bottom: 0,
    backgroundColor: "#F1F4F9",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
    },
  },
  sticky: {
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
    },
    [theme.breakpoints.down("xs")]: {
      position: "relative", // In mobile size the footer is positioned relative, even if classed sticky.
    },
  },
  link: {
    color: "black",
    textDecoration: "underline",
  },
  links: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  linksRight: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "right",
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  copyright: {
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      paddingTop: theme.spacing(2),
    },
  },
}));

type FooterProps = {
  sticky?: boolean;
  firstColumnLinks?: { text: string; href: string }[];
  secondColumnLinks?: { text: string; href?: string }[];
};

export function TikalFooter({
  sticky,
  firstColumnLinks,
  secondColumnLinks,
}: FooterProps) {
  const { t } = useTranslation();
  const classes = useStyles({ sticky });

  return (
    <Box
      pt={4}
      pb={4}
      pl={8}
      pr={8}
      className={`${classes.box} ${sticky ? classes.sticky : "-"}`}
    >
      <Grid container>
        <Grid className={`${classes.links}`} item md={3} sm={6} xs={12}>
          {(firstColumnLinks || []).map((row, i) => (
            <Link
              key={i}
              className={classes.link}
              href={row.href}
              target="_blank"
            >
              <Typography variant="body1">{t(row.text)}</Typography>
            </Link>
          ))}
        </Grid>
        <Grid
          className={`${classes.links} ${classes.linksRight}`}
          item
          md={3}
          sm={6}
          xs={12}
        >
          {(secondColumnLinks || []).map((row, i) => (
            <div className={classes.link} key={i}>
              <Typography sx={{ "& a": { color: "black" } }}>
                <Trans
                  i18nKey={row.text}
                  components={{
                    a: <a />,
                  }}
                />
              </Typography>
            </div>
          ))}
        </Grid>
        <Grid item md={6} xs={12}>
          <Box className={classes.copyright}>
            <Typography variant="body1">
              &copy; {t("common.copyright")}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
