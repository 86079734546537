import { MetaActions, MetaState, SET_META } from "./types";

const initialState: MetaState = {
  valkuaiskasvit: [],
  viljakasvit: [],
  öljykasvit: [],
  perunakasvit: [],
  muutkasvit: [],
};

export default function metaReducer(
  state = initialState,
  action: MetaActions
): MetaState {
  switch (action.type) {
    case SET_META:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
