import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { HehtaariField, KgField, SatotasoField } from "../../../common/Fields";
import SectionSubtitle from "../../../common/SectionSubtitle";
import { useSatotasoCalculator } from "../../../common/useSatotasoCalculator";
import { Fields } from "./valkuaiskasvienSatotiedot.schema";

export default function PuituSiemen({ field, index }: any) {
  const { t } = useTranslation();

  const methods = useFormContext();

  const viljelyAla = methods.watch(
    `ValkuaiskasvienSatotiedot.${index}.${Fields.Viljelyala}`
  );

  const korjuuala = methods.watch(
    `ValkuaiskasvienSatotiedot.${index}.${Fields.Korjuuala}`
  );
  useEffect(() => {
    if (korjuuala === 0) {
      methods.setValue(
        `ValkuaiskasvienSatotiedot.${index}.${[Fields.Sato]}`,
        null
      );
      methods.setValue(
        `ValkuaiskasvienSatotiedot.${index}.${[Fields.SatotasoKuiva]}`,
        null
      );
    }
  }, [korjuuala]);

  useEffect(() => {
    if (viljelyAla === 0) {
      methods.setValue(
        `ValkuaiskasvienSatotiedot.${index}.${Fields.Korjuuala}`,
        null
      );
      methods.setValue(
        `ValkuaiskasvienSatotiedot.${index}.${Fields.Sato}`,
        null
      );
      methods.setValue(
        `ValkuaiskasvienSatotiedot.${index}.${Fields.SatotasoKuiva}`,
        null
      );
      methods.setValue(
        `ValkuaiskasvienSatotiedot.${index}.${Fields.KorjuualaSäilörehu}`,
        null
      );
    }
  }, [viljelyAla]);

  useEffect(() => {
    if (korjuuala) {
      methods.clearErrors(
        `ValkuaiskasvienSatotiedot.${index}.${Fields.KorjuualaSäilörehu}`
      );
    } else
      methods.clearErrors(`ValkuaiskasvienSatotiedot.${index}.${Fields.Sato}`);
  }, [korjuuala]);

  const { handleChangeAcres, handleChangeKilograms } = useSatotasoCalculator(
    `ValkuaiskasvienSatotiedot.${index}.${Fields.Sato}`,
    `ValkuaiskasvienSatotiedot.${index}.${Fields.Korjuuala}`,
    `ValkuaiskasvienSatotiedot.${index}.Satotaso_Kuiva`
  );

  if (viljelyAla === 0) return null;

  switch (field.Kasvilaji) {
    case "_387139":
      break;
    case "_386579":
      break;
    case "_61223":
      break;
    default:
      return null;
  }

  return (
    <Grid item md={6} xs={12}>
      <SectionSubtitle>
        {t("ValkuaiskasvienSatotiedot.KuivattavaKasvi")}
      </SectionSubtitle>
      <Controller
        name={`ValkuaiskasvienSatotiedot.${index}.${Fields.Korjuuala}`}
        control={methods.control}
        render={({ field: { ref, ...field } }) => (
          <HehtaariField
            inputRef={ref}
            disabled={viljelyAla === 0}
            {...field}
            label={"Kuivakorjuuala"}
            fullWidth
            onChange={(e) => {
              field.onChange(e);
              handleChangeAcres(e);
            }}
          />
        )}
      />

      <Controller
        name={`ValkuaiskasvienSatotiedot.${index}.${Fields.Sato}`}
        control={methods.control}
        render={({ field: { ref, ...field } }) => (
          <KgField
            inputRef={ref}
            {...field}
            label={"KuivattuKokonaismäärä"}
            fullWidth
            onChange={(e) => {
              field.onChange(e);
              handleChangeKilograms(e);
            }}
            disabled={korjuuala === 0 || viljelyAla === 0}
          />
        )}
      />

      <Controller
        name={`ValkuaiskasvienSatotiedot.${index}.${Fields.SatotasoKuiva}`}
        control={methods.control}
        render={({ field: { ref, ...field } }) => (
          <SatotasoField
            inputRef={ref}
            {...field}
            label={"KuivasadonSatotaso"}
            fullWidth
            disabled={korjuuala === 0 || viljelyAla === 0}
          />
        )}
      />
    </Grid>
  );
}
